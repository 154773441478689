import React from 'react';
import {Achievement, RecordItem} from "../../../../../features/types/history.d";
import {HistoryContentsMobile} from "../../../../../features/styledComponents/introduce/history/historyContentsMobile";
import Curve from "../../../../../../assets/img/history/line.svg";
import useIntersectionObserver from "../../../../../features/util/commonFacility/sectionsObserver";
import {
  MobileSelectedHistoryActive,
} from "../../../../../features/styledComponents/introduce/history/historyFrames";

const HistoryContentsDivine = ({RecordItem, color, activeYearId,}: {
  RecordItem: RecordItem,
  color: string,
  activeYearId: number,
}) => {
  const {ref: innerRef,} = useIntersectionObserver({threshold: 0.3});
  const colors: {[key: string] : string} = {
    '#3199FF': 'text-[#3199FF]',
    '#3E4FBD': 'text-[#3E4FBD]',
    '#6736B8': 'text-[#6736B8]',
    '#A220B5': 'text-[#A220B5]',
  }
  return (
    <HistoryContentsMobile ref={innerRef}
                           color={color}
                           className={`history_view_data ${RecordItem[0].id ? 'goToLink' : ''}`}
                           id={String(RecordItem[0].year) ?? ''}>
      {(activeYearId === RecordItem[0].year) &&
        <MobileSelectedHistoryActive color={color} targetHistoryYear={RecordItem[0].year === activeYearId}/>}
      {RecordItem.slice(1).map((history, historyIndexKey) => {
        if (('type' in history)) {
          const year = RecordItem[0].year
          return (
            history.achievement &&
            <React.Fragment key={`historyKey-${historyIndexKey}`}>
              <span className={`absolute w-32 bottom-0 top-[1.1rem] left-[1.4rem]`}>
                <img src={Curve} style={{
                  transform: 'scale(0.6, 0.9)',
                }} alt={''}/>
              </span>
              <h2 className={`px-8 py-0 text-5xl font-semibold ${colors[color]}`}>{history.type === 'japan' ? null : year}</h2>
              <h4 className={`${history.type === 'japan' ? 'text-[#A220B5]':'text-[#3199FF]'} py-0 px-8 font-normal text-sm mt-4`}>
                {history.type === 'japan' ? '- NAMU TECH JAPAN' : '- NAMU TECH 韓国本社'}</h4>
              <ol style={{minWidth: '16.75rem', paddingLeft: '2.75rem'}}>
                {
                  history.achievement?.map((ach: Achievement) => {
                    return (
                      <li className={`py-2 flex justify-left`} key={`achievementKey-${ach.result}`}>
                        <p className={`text-base`}>{ach.result}</p>
                      </li>
                    )
                  })
                }
              </ol>
            </React.Fragment>
          )
        } else {
          return null
        }
      })}
    </HistoryContentsMobile>
  )
};

export default React.memo(HistoryContentsDivine);
