import styled from "@emotion/styled";
import {Constants} from "../../constants/appConstants";
import {IsVisibleBoxArticleStyled} from "../common/isVisibleBox";

interface ServicesContentsProps {
  serviceNumber?: number
}

export const ServicesContentsDivineStyled = styled(IsVisibleBoxArticleStyled)<ServicesContentsProps>`
  height: 45.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
  }
  @media (max-width: 768px) {
    height: ${props => props.serviceNumber === Constants.SUB_NAV_SERVICES_IT_INFRASTRUCTURE ? '45rem' : 'auto'};
  }
`
