import {keyframes} from "@emotion/react";
import {colorAndTargetYear} from "./historyContents";
import styled from "@emotion/styled";
import {css} from "@emotion/react/macro";

export const blink = (color: string,) => keyframes`
  from {
    width: 1rem;
    height: 1rem;
    border: 0.5rem double ${color};
    background-color: white;
    border-radius: 50%;
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    width: 3rem;
    height: 3rem;
    border: 0.5rem double ${color};
    background-color: white;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;
export const mobileBlink = (color: string) => keyframes`
  from {
    width: 1.5rem;
    height: 1.5rem;
    border: 0.5rem double ${color};
    background-color: white;
    border-radius: 50%;
    opacity: 1;
    top: 0.1rem;
    left: 4.3rem;
    transform: rotate(0deg);
  }
  to {
    width: 3rem;
    height: 3rem;
    border: 0.5rem double ${color};
    background-color: white;
    border-radius: 50%;
    opacity: 0;
    left: 3.6rem;
    top: 0.1rem;
    transform: rotate(360deg);
  }
`;

export const SelectedHistoryActive = styled.div<colorAndTargetYear>`
  content: "";
  display: block;
  position: absolute;
  width: 1rem; /* 점의 크기 */
  height: 1rem; /* 점의 크기 */
  background: ${props => props.color};
  top: -0.75rem;
  z-index: 1;
  left: 50%; /* 왼쪽 상단에 점을 위치 */
  transform: translate(-50%, -50%);
  animation: ${(props) => props.targetHistoryYear ? css`${blink(props.color)} 2.5s infinite ease-in-out` : ''};
`

export const MobileSelectedHistoryActive = styled(SelectedHistoryActive)`
  left: 3.045rem; /* 왼쪽 상단에 점을 위치 */
  top: 0.95rem;
`
