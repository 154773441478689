import React from 'react';
import {FileUploader} from "react-drag-drop-files";
import InputFileMold from "./inputFileMoldProps";
import {InputFileProps} from "../../../features/types/globals.d";
import {HoverInputFileTitle} from "../../../features/styledComponents/common/inputFile";

const InputFile = ({
                     inputFile, contents, file, handleRemove, onBlur, label
                   }: InputFileProps) => {
  const fileTypes = ["PDF", "CSV", "JPG", "PNG", "PPTX", "DOC", "XLSX", "XLS"];
  let content = contents
  if (contents === '') content = 'ポジションを選択して下さい'
  return (
    <FileUploader
      types={fileTypes}
      onBlur={onBlur}
      label={label}
      handleChange={inputFile}
      name={label}
      disabled={contents === ''}
      classes={`input ${contents === '' ? 'input-disabled' : ''}`}
      maxSize={30}// MAX 30MB
      multiple={false}
      onBler={file}
      hoverTitle={
      contents !== "" &&
      <div className={`py-0 px-4 bg-black rounded-lg border h-[2.3rem] w-full min-w-56 flex justify-center items-center`}>
        <HoverInputFileTitle>ファイルを載せて下さい</HoverInputFileTitle>
      </div>
      }
      onSizeError={(err: any) => {
        if (err === 'File size is too big') {
          alert(`ファイルのサイズが大きいです。\n30MB以下のファイルでお願いします。`)
        }
      }}
      onDrop={(err: any) => {
      }}
      onTypeError={(err: any) => {
        if (err === 'File type is not supported') {
          alert(`ファイルの形式に合わないです。\n以下の形式のファイルでアップロードしてください。\n(JPG , PNG , excel , pdf)`)
        }
      }}
      children={<InputFileMold contents={content} file={file} handleRemove={handleRemove}/>}
    />
  )
};

export default InputFile;
