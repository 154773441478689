import React, {createContext, useReducer, useContext, useEffect, ReactNode} from 'react';
import {Constants} from "../constants/appConstants";
import {NavMenuData, SubNavMenuData} from "../types/globals.d";

type UseDataReturnType = {
  state: {
    sharedData: {
      subNavMenu?: SubNavMenuData | NavMenuData | null
    },
    isNavAllPageOpen: boolean,
    isMobileHistoryHeaderOpen: boolean,
    clickTouchScreenDownHeaderBar: boolean,
  };
  dispatch?: React.Dispatch<any>; // dispatch 함수의 타입을 추가,
}
// 초기값 설정
const initialState: any = {
  // 로컬 스토리지에서 상태를 로드하거나, 없을 경우 기본값 사용 ( banner 이미지, 현재 가르키는 페이지의 인덱스 정보를 저장(공개 될 데이터) )
  sharedData: JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_SHARED_DATA) || '{}') || {},
  isNavAllPageOpen: false,
  isMobileHistoryHeaderOpen: false,
  clickTouchScreenDownHeaderBar: false,
};

const dataReducer = (state: any, action: any) => {
  switch (action.type) {
    // 로컬 스토리지 작업
    case Constants.SET_DATA:
      return {...state, sharedData: action.payload};
    // 페이지 이동 시 작업
    case Constants.SET_PAGE:
      return {...state, page: action.page};
    // 스크롤 작업
    case Constants.SET_SCROLL:
      return {...state, isNavAllPageOpen: action.scroll};
    // 연혁 페이지에서 모바일 일때 일정 수준 스크롤이 진행 될 때, 헤더바 가 내려오게끔 하는 작업
    case Constants.SET_MOBILE_HISTORY:
      return {...state, isMobileHistoryHeaderOpen: action.historyHeaderForMobile};
    // 연혁 쪽에서 터치를 할 때 헤더바가 내려오면 연혁 쪽 네브바도 같이 내려가게끔 작업
    case Constants.SET_HEADER_BAR_TOUCH_TO_HISTORY:
      return {...state, clickTouchScreenDownHeaderBar: action.clickTouchScreenDownHeaderBar};
    default:
      return state;
  }
};

// 디폴트 값
const defaultValue: UseDataReturnType = {
  state: {
    sharedData: {
      subNavMenu: null
    },
    isNavAllPageOpen: false,
    isMobileHistoryHeaderOpen: false,
    clickTouchScreenDownHeaderBar: false,
  },
  dispatch: () => {} // dispatch 함수에 대한 빈 함수 할당
};

export const DataContext = createContext(defaultValue);

export const DataProvider = ({children}: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);
  useEffect(() => {
    // 상태가 변경 될 때 마다 로컬 스토리지 에 저장
    localStorage.setItem(Constants.LOCAL_STORAGE_SHARED_DATA, JSON.stringify({subNavMenu: state.sharedData}));
    // 상태가 변경 될 때 마다 타이틀 변경
    (document.getElementsByTagName(Constants.TITLE)[0] as HTMLElement).innerText = state.sharedData.tagTitle;// 페이지 타이틀 작업
    if (state.page) {
      // top 0으로 해서 제일 위로 올린다.
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      state.page = false;
    }
  }, [state]);
  return (
    <DataContext.Provider value={{state, dispatch} as UseDataReturnType}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = (): UseDataReturnType => useContext(DataContext) as UseDataReturnType;
