import React, {forwardRef} from 'react';
import {navMenuData, subNavMenuData} from "../../../../assets/data/menuData";
import {Constants} from "../../../features/constants/appConstants";
import {NavLink, useNavigate} from "react-router-dom";
import {useMediaQuery} from "@mui/material";

export type NavAllPageProps = {
  isOpenAllNavPage: boolean;
  openClosePage: (type?: boolean) => void;
}
const NavAllPage = forwardRef<HTMLDivElement, NavAllPageProps>(({isOpenAllNavPage, openClosePage}, ref) => {
  let navigate = useNavigate();
  const isSmall: boolean = useMediaQuery('(max-height:700px)');
  const sortedNavMenuData = navMenuData
    .filter(navData => navData.navMenuId !== Constants.NAV_MENU_HOME && navData.navMenuId !== Constants.NAV_MENU_COMMUNICATION) // 홈 커뮤니티 제외 ID를 제외
    .sort((a, b) => {
      // 특정 navMenuId를 우선하여 정렬
      if (a.navMenuId === Constants.NAV_MENU_PRODUCTION && b.navMenuId === Constants.NAV_MENU_SERVICES) {
        return -1; // a가 b보다 앞에 오도록 - 일 경우는 a 가 b보다 앞서야한다
      } else if (a.navMenuId === Constants.NAV_MENU_SERVICES && b.navMenuId === Constants.NAV_MENU_PRODUCTION) {
        return 1; // b가 a보다 앞에 오도록 + 일 경우는 b 가 a 보다 앞서야한다
      }
      return 0; // 기타 경우는 순서 변경 없음
    });

  function goToLink(link: string) {
    if (link.includes('skpack')) {
      window.open(link, '_blank');
    } else {
      navigate(link);
    }
  }

  return (
    <menu
      className={`bg-white bg-[url('./assets/img/nav/navigation_full_background.svg')] bg-cover bg-center bg-no-repeat fixed overflow-x-hidden overflow-y-auto flex flex-col justify-between flex-nowrap shadow-md transition-all duration-150 ease-in delay-0 ${isOpenAllNavPage ? 'pointer-events-auto z-40 opacity-100' : 'pointer-events-none opacity-0'} ${!isSmall ? 'h-[75vh]':'h-[90vh]'} inset-0`}
      ref={ref}>
      <div className={`z-10`}/>
      <div className={`mt-10`}>
        <div className={`w-full flex justify-center items-center`}>
          <nav className={`grid grid-cols-2 gap-x-4 ${!isSmall ? 'gap-y-12':'gap-y-4'} items-start overflow-y-auto py-0 px-4`}>
            {sortedNavMenuData.map((navData, _) =>
              (<div
                className={`w-full ${isOpenAllNavPage ? 'block' : 'hidden'}`}
                key={navData.title}>
                <div className={`w-full grid grid-cols-2`}>
                  <div
                    className={`text-base text-[#637492] font-medium ml-[0.4rem] min-w-[13.1875rem] max-w-60 justify-self-end`}>
                    {navData.title}
                  </div>
                </div>
                <div className={`grid grid-cols-2 gap-4 mt-[0.7rem]`}>
                  {
                    subNavMenuData[navData.navMenuId].map((navSubData, index) => {
                      return (
                        <div
                          className={`${!isSmall ? 'py-4':'py-3'} px-0 border border-[#637492] rounded-[0.8rem] bg-white flex transition-all ease-in duration-200 min-w-[14.1875rem] max-w-60 font-medium ${index % 2 === 1 ? 'justify-self-start' : 'justify-self-end'} hover:shadow-header-nav-hover hover:translate-x-[-0.2rem] hover:translate-y-[-0.2rem] active:translate-x-0 active:translate-y-0 cursor-pointer`}
                          title={`遷移-${navSubData.title}`}
                          onClick={() => {
                            goToLink(navSubData.link)
                            openClosePage()
                          }}
                          key={navSubData.title}>
                          <img className={`w-4 ml-[1.3rem]`} src={navSubData.iconSrc1} alt={''}/>
                          <h6 className={`text-sm ml-4 text-[#243142]`}>{navSubData.title}</h6>
                        </div>
                      )
                    })
                  }
                </div>
              </div>))}
          </nav>
        </div>
      </div>
      <div
        className={`flex justify-center items-center text-[#243242] h-12 bg-white border-t border-b border-black`}
        id={'testfd'}>
        <NavLink
          onClick={() => openClosePage()}
          title={'move-communication-contact'}
          to={Constants.URL_PATH_COMMUNICATION_CONTACT}
          className={`h-10 flex items-center`}>
          お問い合わせ
        </NavLink>
      </div>
    </menu>
  );
});

export default NavAllPage;
