import React from 'react';
import Curve from "../../../../../../assets/img/history/line.svg"
import {Achievement} from "../../../../../features/types/history.d";
import {historyContentProps} from "./historyBlock";

const HistoryBlockContent = React.memo(({historyData, color, year, yearName}: historyContentProps) => {
  const colors: {[key: string] : string} = {
    '#3199FF': 'text-[#3199FF]',
    '#3E4FBD': 'text-[#3E4FBD]',
    '#6736B8': 'text-[#6736B8]',
    '#A220B5': 'text-[#A220B5]',
  }
  return (
    <div className={`flex-1 ${historyData.type === 'korea'? 'text-right':'text-left'} relative py-[2.3rem] px-32`}>
      {
        historyData.achievement &&
        <>
          <span className={`absolute w-44 bottom-0 top-[-5%] ${historyData.type === 'korea' ? 'right-0 scale-x-[-1]':'left-0'} `}>
            <img src={Curve} className={`scale-x-[1] scale-y-[0.7]`} alt={''}/>
          </span>
          <h2 className={`text-5xl font-semibold ${colors[color]} pt-3`}>{year}</h2>
          <h4 className={`font-normal text-[#6E6E6E] text-xs mb-4`}>{yearName}</h4>
          <ol>
            {
              historyData.achievement?.map((ach: Achievement) => {
                return (
                  <li key={`achievementKey-${ach.result}`} className={`px-0 py-2 flex ${historyData.type === 'korea' ? 'justify-end' : 'justify-start'}`}>
                    <p className={`font-medium text-sm text-[#202020] max-w-2xl`}>{ach.result}</p>
                  </li>
                )
              })
            }
          </ol>
        </>
      }
    </div>
  );
}, () => true);

export default HistoryBlockContent;
