import React from 'react';
import {serviceType} from "../../../../features/types/service.d";

type servicePartner = serviceType & {
  isOpenPTag: boolean;
  partnerImageStyle: string[];
}
const ServicePartner = ({serviceContent, isOpenPTag, partnerImageStyle}: servicePartner) => {
  return (
    <section className={`flex flex-col items-center py-12 bg-[#F1F3F7] text-[#243142]
      lg:py-32 `}>
      <div className={`flex flex-col flex-wrap
        lg:flex-nowrap lg:flex-row`}>
        {
          serviceContent.sub.map((images, index) => (
              <div className={`w-80 h-32 flex flex-row items-center justify-evenly bg-white rounded-[0.7rem] mx-[1.3rem] my-[0.5rem]
                lg:rounded-[1.2rem] lg:flex-col lg:justify-evenly lg:w-56 lg:h-56 lg:my-[0rem]`}
                   key={`imgKey-${images.subTitle}`}>
                <img src={images.src}
                     className={`p-4 object-contain ${partnerImageStyle[index]}`} // 서비스 내용의 이미지가 크기가 제각각이라 각자 사이즈 조정을 한다.
                     alt={images.subTitle}/>
                {(isOpenPTag) &&
                  (<p className={`text-center text-sm font-bold text-[#21355F] w-1/2 -ml-10 mr-2
                   lg:w-full lg:-mt-12 lg:-ml-0 lg:mr-0`}
                      dangerouslySetInnerHTML={{__html: images.subTitle}}/>)
                }
              </div>
            ))
        }
      </div>
    </section>
  );
};

export default ServicePartner;
