import React from 'react';
import RepresentativeMessage from "./representativeMessage";
import VisionMessage from "./visionMessage";
import useIntersectionObserver from "../../../../features/util/commonFacility/sectionsObserver";
import {
  IsVisibleBoxDivStyled
} from "../../../../features/styledComponents/common/isVisibleBox";

const Messages = () => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0});

  return (
    // isVisible && // 생략
    <IsVisibleBoxDivStyled isVisible={isVisible} ref={innerRef} className={`overflow-hidden`}>
      <RepresentativeMessage/>
      <VisionMessage/>
    </IsVisibleBoxDivStyled>
  );
};

export default Messages;
