import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import Nav from "./nav/nav";
import SubNavItems from "./nav/subNavItems/subNavItems";
import {NavLink} from "react-router-dom";
import {Constants} from "../../features/constants/appConstants";
import gridBar from "../../../assets/img/nav/grid.svg"
import {useWindowResizeToTablet} from "../../features/util/responsive/tablet";
import NavMobileAllPage from "./nav/navMobileAllPage";
import {HeaderStyled} from "../../features/styledComponents/header/headerStyled";
import {throttle} from "lodash";
import NavAllPage from "./nav/navAllPage";
import {DataContext, useData} from "../../features/reducer/navReducer";
import CloseIcon from '@mui/icons-material/Close';
import {useWindowResizeToMobile} from "../../features/util/responsive/mobile";
import Banner from "./banner/banner";

const Header = ({touchScreen, cancelTouchEnd}: { touchScreen: boolean, cancelTouchEnd: () => void }) => {
  const [showHeaderSubNavBar, setShowHeaderSubNavBar] = useState<boolean>(false); // 해당 버튼에 맞춰서 showHeaderSubNavBar 초기 높이 설정
  const [showHeaderNavBarToScroll, setShowHeaderNavBarToScroll] = useState<boolean>(true); // showHeaderSubNavBar 를 스크롤 위로 했을땐 보이고 아래로 했을 시에는 안보인다.
  const [navMenuId, setNavMenuId] = useState<number>(0); // nav 메뉴 아이디 값
  const [lastScrollY, setLastScrollY] = useState<number>(0); // 마지막 스크롤 위치// 마지막 스크롤 위치
  const [isOpenAllNavPage, setIsOpenAllNavPage] = useState<boolean>(false); // 전체 페이지 창
  const navAllPageRef = useRef<HTMLDivElement | null>(null);
  const isTablet = useWindowResizeToTablet(); // tablet 사이즈인지 아닌지 확인
  const isMobile = useWindowResizeToMobile(); // responsive 사이즈인지 아닌지 확인
  const {dispatch} = useContext(DataContext);
  const {state} = useData();
  const [touchY, setTouchY] = useState(0);

  function onTouchStart(e: any) {
    // setTouchY(e.changedTouches[0].pageY);
  }

  function onTouchEnd(e: any) {
    // if (touchY === 0) return
    // const distanceY = touchY - e.changedTouches[0].pageY;
    // if ((distanceY * (-1)) > 7) {
    //   openClosePage()
    // }
  }

  // nav 항목에 마우스를 올렸을 때 호출될 함수
  const hoverItemIndex = useCallback((navMenuIds: number) => {
    if (!isOpenAllNavPage) {
      setShowHeaderSubNavBar(true); // 참(아래로)
      setNavMenuId(navMenuIds);
    }
    // 홈이랑 컨택은 열지 않는다.
    if (navMenuIds === Constants.NAV_MENU_HOME || navMenuIds === Constants.NAV_MENU_COMMUNICATION) {
      setShowHeaderSubNavBar(false); // 참(아래로)
    }
  }, [isOpenAllNavPage]);
  // hover 를 풀었을 때는 닫는다.
  const NoHoverItemIndex = useCallback(() => {
    setShowHeaderSubNavBar(false); // 거짓(위로)
  }, [])

  // 버튼을 눌렀을 때, 전체 페이지를 닫는다.
  function openClosePage() {
    NoHoverItemIndex();
    dispatch && dispatch({type: Constants.SET_SCROLL, scroll: !isOpenAllNavPage})
    setIsOpenAllNavPage(!isOpenAllNavPage);
  }

  function clickItemIndex(navMenuIds: number) {
    if (!(isOpenAllNavPage && showHeaderSubNavBar)) {
      setShowHeaderSubNavBar(!showHeaderSubNavBar); // 참(아래로)
      setNavMenuId(navMenuIds);
    }
    // 홈이랑 컨택은 열지 않는다.
    if (navMenuIds === Constants.NAV_MENU_HOME || navMenuIds === Constants.NAV_MENU_COMMUNICATION) {
      setShowHeaderSubNavBar(false); // 참(아래로)
    }
  }

  // 스크롤・호버・버튼 클릭 시 마운토
  useEffect(() => {
    const handleScroll = throttle(() => {
      let currentScrollY = window.scrollY; // 현재 스크롤 위치
      if (currentScrollY <= 0) {
        currentScrollY = 0
      }
      if (currentScrollY > lastScrollY) {
        setShowHeaderNavBarToScroll(false); // showHeaderSubNavBar 숨기기
        cancelTouchEnd()
      } else {
        setShowHeaderNavBarToScroll(true);// showHeaderSubNavBar 표시하기
      }
      setLastScrollY(currentScrollY);
    }, 200); // 200ms 마다 throttle 적용

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);
    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, cancelTouchEnd]); // lastScrollY가 변경될 때마다 useEffect 실행

  useEffect(() => {
    // 외부 클릭을 감지하는 함수
    function handleClickOutside(event: any) {
      if (isOpenAllNavPage && navAllPageRef.current && !navAllPageRef.current.contains(event.target)) {
        setIsOpenAllNavPage(false)
        dispatch && dispatch({type: Constants.SET_SCROLL, scroll: false})
      }
    }

    // 클릭 이벤트 리스너 등록
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // 컴포넌트 언마운트 시 클릭 이벤트 리스너 제거
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navAllPageRef, setIsOpenAllNavPage, isOpenAllNavPage]);

  return (
    <header>
      <HeaderStyled
        id='Header'
        showHeaderNavBarToScroll={showHeaderNavBarToScroll}
        touchScreenToShowHeaderNavBar={touchScreen}
        isMobileHistoryHeaderShow={state.isMobileHistoryHeaderOpen}
        isZeroLastScrollY={lastScrollY === 0}
        showHeaderSubNavBar={showHeaderSubNavBar}
        isOpenAllNavPage={isOpenAllNavPage}
        onMouseLeave={NoHoverItemIndex}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}>
        <menu className={`flex items-center sticky top-0 justify-between h-14 my-0 mx-8 
        z-[101]
        lg:justify-around lg:m-0 lg:max-w-screen-xl lg:my-0 lg:mx-auto`}>
          <NavLink to={Constants.URL_PATH_HOME} title={'move-namu-home'} className={`w-[5.625rem] h-6 flex items-center relative`}>
            <div className="absolute left-0">
              <img className="object-contain -mt-2 w-full h-full" src={Constants.MAIN_LOGO_GRADIENT} alt={'NamuTechJapanLogo'}/>
            </div>
            <div className="absolute right-0">
              <img className="object-contain animate-bounce -mt-1.5 w-full h-full" src={Constants.MAIN_LOGO_CIRCLE} alt={'NamuTechJapanLogo'}/>
            </div>
          </NavLink>
          {/*<NavLink to={Constants.URL_PATH_HOME} title={'move-namu-home'}*/}
          {/*         className={`w-[5.625rem] h-6 flex items-center`}>*/}
          {/*  <img className="object-contain -mt-2 w-full h-full" src={Constants.MAIN_LOGO} alt={'NamuTechJapanLogo'}/>*/}
          {/*       alt={'NamuTechJapanLogo'}/>*/}
          {/*</NavLink>*/}
          {
              (!isTablet && !isMobile) &&
              <Nav onMouseHover={hoverItemIndex}
                   onMouseLeave={NoHoverItemIndex}
                 clickItemIndex={clickItemIndex}
                 isOpenAllNavPage={isOpenAllNavPage}/>
          }
          <div className={`cursor-pointer`}>
            <span className={`p-4 ${isOpenAllNavPage ? 'hidden' : 'block'}`} onClick={() => openClosePage()}>
              <img src={gridBar} className={`w-4 h-4`} alt={'menuGrid'}/>
            </span>
            <span className={`p-4 ${isOpenAllNavPage ? 'block' : 'hidden'}`} onClick={() => openClosePage()}>
              <CloseIcon fontSize="medium"/>
            </span>
          </div>
        </menu>
        {
          (!isTablet && !isMobile) ?
            <>
              <SubNavItems navMenuId={navMenuId} showHeaderSubNavBar={showHeaderSubNavBar}
                           onMouseLeave={NoHoverItemIndex}/>
              <NavAllPage isOpenAllNavPage={isOpenAllNavPage} openClosePage={openClosePage}
                          ref={navAllPageRef}/>
            </> :
            <NavMobileAllPage isOpenAllNavPage={isOpenAllNavPage} openClosePage={openClosePage}
                              ref={navAllPageRef}/>
        }
      </HeaderStyled>
      <Banner/>
    </header>
  );
};

export default Header
