import React, {Suspense} from 'react';
import Loading from "../../../../common/loading";
import {Constants} from "../../../../../features/constants/appConstants";
import useIntersectionObserver from "../../../../../features/util/commonFacility/sectionsObserver";
import aboutUsCss from './aboutUs.module.scss';
import {IsVisibleBoxDivStyled} from "../../../../../features/styledComponents/common/isVisibleBox";

const LazyImage = React.lazy(() => import('../../../../common/lazyImg'));
const AboutUs = () => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.2});
  return (
    // innerRef, isVisible 나중에 추가
    <IsVisibleBoxDivStyled className={`bg-white px-6 py-8
    lg:py-32`} ref={innerRef} isVisible={isVisible}>
        <div className="flex flex-col gap-y-8
      xl:gap-y-12
      ">
          <div>
            <p className="text-sm text-[#333333] text-center
          md:text-xl
          ">
              お客様の成長と共に2012年9月の設立以来成長を続けております。
            </p>
          </div>
          <div className="flex flex-col items-center
        md:flex-row md:justify-center md:gap-x-3
        ">
            <div className="border-[3px] border-[#00C06E] rounded-xl w-[17rem] h-[17rem] overflow-hidden
          md:w-[20rem] md:h-[20rem]
          xl:w-[28rem] xl:h-[28rem]
          ">
              <Suspense fallback={<Loading/>}>
                <picture>
                  <source media="(max-width: 768px)" srcSet={`/assets/about/mobile_collage_image.png`}/>
                  <LazyImage src="/assets/about/collage_image.png" alt='collageImage'/>
                </picture>
              </Suspense>
            </div>
            <div className="w-fullh h-6 flex flex-col items-center relative
          md:flex-row md:w-3 md:h-0
          ">
            <span className=" bg-[#00C06E] w-4 h-4 border-[3px] rounded-full border-black absolute -top-3 z-10
            md:-top-2 md:-left-5
            "/>
              <hr className="transform rotate-90 border-[0.13rem] border-[#00C06E] border-dashed w-8 z-0 top-[0.3rem] absolute
            md:rotate-0 md:w-10 md:border-[0.3rem] md:-left-2 md:-top-1
            "/>
              <span className="bg-[#00C06E] w-4 h-4 border-[3px] rounded-full border-black absolute -bottom-3 z-10
            md:-bottom-2 md:-right-5
            "/>
            </div>
            <div className={aboutUsCss.grid_box}>
              <div><img src='/assets/about/about_earth.svg' alt=''/></div>
              <div><p>15年以上<br/>大手企業への<br/>サービス<br/>提供経験</p></div>
              <div><img src='/assets/about/about_trade.svg' alt=''/></div>
              <div><p>40名以上の<br/>専門エンジニア<br/>保有</p></div>
              <div><img className={aboutUsCss.namu_logo} alt='namuLogo' src={Constants.MAIN_LOGO}/></div>
              <div><p>高い顧客の<br/>満足度</p></div>
              <div><img src='/assets/about/about_chart.svg' alt=''/></div>
              <div><p>高いシニア<br/>エンジニアの<br/>比率</p></div>
              <div><img src='/assets/about/about_head.svg' alt=''/></div>
            </div>
          </div>
          <div>
            <p className="text-sm text-[#333333] text-center
          md:text-xl
          ">
              私たちは、お客様から信頼される企業として、<br/>経験に基づいた様々なご提案や資格を持つスペシャリストによる確かな技術を提供します。
            </p>
          </div>
        </div>
    </IsVisibleBoxDivStyled>
  );
};

export default AboutUs;
