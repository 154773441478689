import React from 'react';
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";
import {ServicesContentsDivineStyled} from "../../../features/styledComponents/services/servicesMainStyled";
import {useWindowResizeToTablet} from "../../../features/util/responsive/tablet";
import {useWindowResizeToMobile} from "../../../features/util/responsive/mobile";
import CommonServiceTypeA from "./serviceContent/commonServiceTypeA";
import ServicePartner from "./serviceContent/servicePartner";
import {serviceType} from "../../../features/types/service.d";

const ItSystemMaintenance = ({serviceContent}: serviceType) => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.1});
  const isTablet = useWindowResizeToTablet();
  const isMobile = useWindowResizeToMobile();
  const mainImageClassName = `
      md:w-[80%] md:h-[80%] w-[90%] h-[90%]`;
  const pClassName = `
      font-normal mt-0 h-[3.3rem] text-[#243242]
      md:text-center md:text-white md:h-[3.3rem] md:w-[25rem] md:mt-[3.4rem] md:mb-[2.4rem]`
  const partnerImageClassName = [
    'w-1/2 h-5/6 lg:w-1/2 lg:h-1/2',
    'w-1/2 h-5/6 lg:w-1/2 lg:h-1/2',
    'w-1/2 h-5/6 lg:w-1/2 lg:h-1/2',
  ]

  return (
    <>
      <section className={`service-main-mold md:-mt-4 lg:mt-16 md:min-h-[650px] bg-[#FFEEAF] md:bg-[#05467E]`}>
        <ServicesContentsDivineStyled className="-my-2 lg:mb-16" ref={innerRef} isVisible={isVisible}>
          {(!isTablet && !isMobile) &&
            <>
              <div
                className={"absolute w-[66rem] h-[66rem] border-[3px] border-[#FABE04] z-0 rounded-full bottom-[-56%]"}/>
              <div className={"absolute w-[61rem] h-[61rem] z-0 bg-[#68C3F8] bottom-[-50%] border-0 rounded-full"}/>
              <div className={"w-full absolute bg-[#05467E] h-[15rem] bottom-[-6rem] z-10"}/>
            </>
          }
          {isVisible && <CommonServiceTypeA serviceContent={serviceContent}
                                            mainImageClassName={mainImageClassName}
                                            pClassName={pClassName}/>}
        </ServicesContentsDivineStyled>
      </section>
      <ServicePartner serviceContent={serviceContent} isOpenPTag={true} partnerImageStyle={partnerImageClassName}/>
    </>
  );
};

export default ItSystemMaintenance;
