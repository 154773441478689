import React from 'react';
import aboutIcon1 from "../../../../assets/img/homeAbout/mainpage_icon1.svg"
import aboutIcon2 from "../../../../assets/img/homeAbout/mainpage_icon2.svg"
import aboutIcon3 from "../../../../assets/img/homeAbout/mainpage_icon3.svg"
import aboutIcon4 from "../../../../assets/img/homeAbout/mainpage_icon4.svg"
import {DataButton} from "../../../features/styledComponents/common/Button";
import {useGoToLink} from "../../../features/util/commonFacility/goToLink";
import {Constants} from "../../../features/constants/appConstants";
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";

const AboutHome = () => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0});
  const goToLink = useGoToLink();
  const icons = [
    {src: aboutIcon1, title: '15年以上<br>大手企業への<br>サービス提供経験'},
    {src: aboutIcon2, title: '40名以上の<br>専門エンジニア保有'},
    {src: aboutIcon3, title: '高い顧客の満足度'},
    {src: aboutIcon4, title: '高いシニア<br>エンジニアの比率'},
  ];

  return (
    <section
      className={`w-full bg-transparent bg-[url('./assets/img/homeAbout/mainpage_grid.svg')] text-[#051B4F] pt-8 px-4 pb-12 md:pt-20 md:px-0 md:pb-24 flex overflow-hidden flex-col items-center transition-all duration-1000 delay-0 ease-in ${isVisible ? `opacity-100 transition-x-0 translate-y-0` : `opacity-0`}`}
      ref={innerRef}>
      <h1 className="font-semibold text-xl mb-4 md:mb-8 lg:text-3xl">私たちについて</h1>
      <p className="px-4 lg:text-center">お客様の成功と共に成長を続けております。<br/>2012年9月日本法人設立以来、継続的な成長を続けています。
      </p>
      <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 md:gap-8 my-11 text-center">
        {
          icons.map((img, index) => (
            <div key={index} className={`bg-white shadow pt-4 pb-6 px-3 rounded-xl border md:w-60 `}>
              {isVisible &&
                <>
                  <div className={`flex justify-center items-center h-32 pt-16 pb-12`}>
                    <img src={img.src} alt=""/>
                  </div>
                  <div className="flex items-center justify-center h-20">
                    <p dangerouslySetInnerHTML={{__html: img.title}} className="text-sm md:text-base font-semibold"></p>
                  </div>
                </>}
            </div>
          ))
        }
      </div>
      <p className="px-4 mt-4 mb-4
          md:mt-2
          sm:px-16
          lg:text-center"
         dangerouslySetInnerHTML={{
           __html: "私たちは、お客様から信頼される企業として、<br/>経験に基づいた様々なご提案や資格を持つスペシャリストによる確かな技術を提供します。"
         }}>
      </p>
      <DataButton
        onClick={() => goToLink(Constants.URL_PATH_INTRODUCE_ABOUT)}>詳細はこちら</DataButton>
    </section>
  );
};

export default AboutHome;
