import React, {Suspense, useCallback, useEffect, useState} from 'react';
import TopicBtn from "../topic/topicBtn";
import Loading from "../loading";
import {useLocation, useNavigate} from "react-router-dom";
import {BodyGroupProps} from "../../../features/types/globals.d";

const BodyGroup = ({data, tabs, type}: BodyGroupProps) => {
  const [index, setIndex] = useState(0);
  const [btnData, setBtnData] = useState([]);
  // 탭의 키를 인덱스에 맞게끔 관리를한다.
  const selectedTab = tabs?.find(tab => tab.key === index);
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    // 버튼을 셋팅한다.(버튼 타이틀 및 버튼 갯수)
    setBtnData(data);
    for (let i = 0; i < data.length; i++) {
      // 현재 패스명에 따라서 버튼 선택(index 변경)
      if (location.pathname === data[i].link) {
        setIndex(data[i].subNavMenuId)
        break
      }
    }
  }, [data, location.pathname]);

  // path 클릭
  const goToLink = useCallback((path: string, i: number) => {
    navigate(path); // /information/path 경로로 이동
    setIndex(i); // 해당 인덱스 설정
  }, [setIndex, navigate]);
  const handleSelect = useCallback((i: number) => {
    setIndex(i);
  }, [setIndex])

  return (
    <Suspense fallback={<Loading/>}>
      <TopicBtn btnData={btnData} handleSelect={handleSelect} goToLink={goToLink} index={index} type={type} tabsAmount={tabs?.length}>
        {
          btnData.length > 0 ?
            selectedTab?.content
            :
            <Loading/>
        }
      </TopicBtn>
    </Suspense>
  );
};

export default BodyGroup;
