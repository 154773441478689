import React from 'react';
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";
import {ServicesContentsDivineStyled} from "../../../features/styledComponents/services/servicesMainStyled";
import CommonServiceTypeA from "./serviceContent/commonServiceTypeA";
import ServicePartner from "./serviceContent/servicePartner";
import {serviceType} from "../../../features/types/service.d";

const ItSystemSales = ({serviceContent}: serviceType) => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.1});
  const mainImageClassName = `
       md:w-[80%] md:h-[80%] w-[90%] h-[90%]`;
  const pClassName = `
      font-normal mt-0 h-[3.3rem] text-[#243242]
      md:text-center md:h-[5.7rem] md:w-[29rem] md:mt-[2.8rem] md:mb-[1rem]`
  const partnerImageClassName = [
    'w-4/12 h-11/12 lg:w-1/2 lg:h-1/2',
    'w-9/12 h-3/5 lg:w-3/5 lg:h-3/5',
    'w-10/12 h-2/3 lg:w-9/12 lg:h-9/12',
    'w-6/12 h-9/12 lg:w-[70%] lg:h-[70%]',
  ]

  return (
    <>
      <section className={`service-main-mold md:-mt-4 lg:mt-16 md:min-h-[650px] bg-[#F7DAC5]`}>
        <ServicesContentsDivineStyled className="-my-3 -mb-5 lg:mb-5" ref={innerRef} isVisible={isVisible}>
          {isVisible && <CommonServiceTypeA serviceContent={serviceContent}
                                            mainImageClassName={mainImageClassName}
                                            pClassName={pClassName}/>}
        </ServicesContentsDivineStyled>
      </section>
      <ServicePartner serviceContent={serviceContent} isOpenPTag={false} partnerImageStyle={partnerImageClassName}/>
    </>
  );
};

export default ItSystemSales;
