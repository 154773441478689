import styled from "@emotion/styled";
import {blink} from "./historyFrames";
import {css} from "@emotion/react/macro";
import {IsVisibleBoxDivStyled} from "../../common/isVisibleBox";

export interface colorAndTargetYear {
  color: string,
  targetHistoryYear?: boolean,
}

interface dataViewItemsJapanOrKorea extends colorAndTargetYear {
  isKoreaItem?: boolean,
  isVisible?: boolean
}

export const HistoryDataView = styled(IsVisibleBoxDivStyled)<colorAndTargetYear>`
  display: flex;
  position: relative;
  width: 100%;

  &::before {
    background-color: rgba(230, 230, 230, 1); /* 선의 색상 설정 */
    content: " ";
    display: block;
    position: absolute;
    width: 0.15rem; /* 선의 너비 */
    height: 100%; /* 선의 높이 */
    top: 0;
    left: 50%;
    bottom: 0;
  }

  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 1.5rem; /* 점의 크기 */
    height: 1.5rem; /* 점의 크기 */
    border-radius: 50%; /* 원형으로 만들기 */
    background: white;
    border: 0.2rem solid ${props => props.color};
    top: -0.75rem;
    z-index: 2;
    left: 50%; /* 왼쪽 상단에 점을 위치 */
    transform: translate(-50%, -50%) scale(1, 1);
  }
`
