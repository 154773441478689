import blackQuote from "../../../assets/img/messages/blackQuotes.svg";
import bigTriangle from "../../../assets/img/messages/bigTriangle.svg";
import trianglePattern from "../../../assets/img/messages/trianglePattern.svg";
import whiteQuote from "../../../assets/img/messages/whiteQuotes.svg";
import smallTriangle from "../../../assets/img/messages/smallTriangle.svg";
import contactUs1 from "../../../assets/img/communication/contact_us_icon_pattern_fly1.svg";
import contactUs2 from "../../../assets/img/communication/contact_us_icon_pattern_fly2.svg";
import contactUs3 from "../../../assets/img/communication/contact_us_icon_rectenggle1.svg";
import contactUs4 from "../../../assets/img/communication/contact_us_icon_rectenggle2.svg";
import contactUs5 from "../../../assets/img/communication/contact_us_icon_rectenggle3.svg";
import contactUs6 from "../../../assets/img/communication/contact_us_icon_rectenggle4.svg";
import contactUs7 from "../../../assets/img/communication/contact_us_icon_rectenggle5.svg";
import contactUs8 from "../../../assets/img/communication/contact_us_icon_rectenggle6.svg";
import homeMainImage from "../../../assets/img/nav/intro/navIconIntro2.svg";
import hexagon from "../../../assets/img/peopleLoveUs/hexagon.svg"
import twoCircle from "../../../assets/img/peopleLoveUs/twoCircle.svg"
import countCircle from "../../../assets/img/peopleLoveUs/countCircle.svg"
import jobInfo1 from "../../../assets/img/recruitInformation/job_application_icon1.svg"
import jobInfo2 from "../../../assets/img/recruitInformation/job_application_icon2.svg"
import jobInfo3 from "../../../assets/img/recruitInformation/job_application_icon3.svg"
import jobInfo4 from "../../../assets/img/recruitInformation/job_application_icon4.svg"
import jobInfo5 from "../../../assets/img/recruitInformation/job_application_icon5.svg"
import jobInfo6 from "../../../assets/img/recruitInformation/job_application_icon6.svg"
import recruitment1 from "../../../assets/img/recruitment/recruitment1.svg";
import recruitment2 from "../../../assets/img/recruitment/recruitment2.svg";
import recruitment3 from "../../../assets/img/recruitment/recruitment3.svg";
import recruitment4 from "../../../assets/img/recruitment/recruitment4.svg";
import recruitment5 from "../../../assets/img/recruitment/recruitment5.svg";
import recruitment6 from "../../../assets/img/recruitment/recruitment6.svg";
import recruitment7 from "../../../assets/img/recruitment/recruitment7.svg";
import recruitment8 from "../../../assets/img/recruitment/recruitment8.svg";
import recruitment9 from "../../../assets/img/recruitment/recruitment9.svg";
import recruitment11 from "../../../assets/img/recruitment/recruitment11.svg";
import partnerImage1 from "../../../assets/img/partner/mainpage_partner_icon1.svg";
import partnerImage2 from "../../../assets/img/partner/mainpage_partner_icon2.svg";
import namutechJapan from "../../../assets/img/history/historypage_namutechjapan_.png";
import namutechKorea from "../../../assets/img/history/historypage_namutech_.png";
import info1 from "../../../assets/img/info/information_battery.svg";
import info2 from "../../../assets/img/info/information_cube1.svg";
import info3 from "../../../assets/img/info/information_cube2.svg";
import info4 from "../../../assets/img/info/information_cube3.svg";
import info5 from "../../../assets/img/info/information_cube4.svg";
import info6 from "../../../assets/img/info/information_cube5.svg";
import info7 from "../../../assets/img/info/information_cube6.svg";
import info8 from "../../../assets/img/info/information_grid1.svg";
import info9 from "../../../assets/img/info/information_grid2.svg";
import entry1 from "../../../assets/img/entry/recruit_entry_icon1.svg";
import entry2 from "../../../assets/img/entry/recruit_entry_icon2.svg";
import entry3 from "../../../assets/img/entry/recruit_entry_icon3.svg";
import entry4 from "../../../assets/img/entry/recruit_entry_icon4.svg";
import entry5 from "../../../assets/img/entry/recruit_entry_icon5.svg";
import entry6 from "../../../assets/img/entry/recruit_entry_icon6.svg";
import entry7 from "../../../assets/img/entry/recruit_entry_icon7.svg";
import entry8 from "../../../assets/img/entry/recruit_entry_icon8.svg";
import entry9 from "../../../assets/img/entry/recruit_entry_icon9.svg";


export class DecoImages {
  static jobInfo1 = {
    backgroundImage: `url(${jobInfo1})`,
    bottom: `3%`,
    right: '5%',
    width: '7rem',
    height: '7rem'
  }
  static jobInfo2 = {
    backgroundImage: `url(${jobInfo2})`,
    bottom: `50%`,
    right: '-5%',
    width: '13rem',
    height: '13rem'
  }
  static jobInfo3 = {
    backgroundImage: `url(${jobInfo3})`,
    bottom: `20%`,
    left: '-1%',
    width: '13rem',
    height: '13rem'
  }
  static jobInfo4 = {
    backgroundImage: `url(${jobInfo4})`,
    top: `3%`,
    right: '5%',
    width: '3rem',
    height: '3rem'
  }
  static jobInfo5 = {
    backgroundImage: `url(${jobInfo5})`,
    top: `37%`,
    left: '11%',
    width: '3rem',
    height: '3rem'
  }
  static jobInfo6 = {
    backgroundImage: `url(${jobInfo6})`,
    top: `1%`,
    left: '11%',
    width: '14rem',
    height: '20rem'
  }
  static recruitment1 = {
    backgroundImage: `url(${recruitment1})`,
    bottom: `-10%`,
    right: '-5%',
    width: '20%',
    height: '20%'
  }
  static recruitment2 = {
    backgroundImage: `url(${recruitment2})`,
    top: `5%`,
    right: '7%',
    width: '3.5rem',
    height: '3.5rem'
  }
  static recruitment3 = {
    backgroundImage: `url(${recruitment3})`,
    top: `50%`,
    left: '7%',
    width: '2.25rem',
    height: '2.25rem'
  }
  static recruitment4 = {
    backgroundImage: `url(${recruitment4})`,
    bottom: `7%`,
    right: '3%',
    width: '4rem',
    height: '4rem'
  }
  static recruitment5 = {
    backgroundImage: `url(${recruitment5})`,
    top: `7%`,
    left: '3%',
    width: '3rem',
    height: '3rem'
  }
  static recruitment6 = {
    backgroundImage: `url(${recruitment6})`,
    top: `30%`,
    right: '10%',
    width: '2rem',
    height: '2rem'
  }
  static recruitment7 = {
    backgroundImage: `url(${recruitment7})`,
    bottom: `10%`,
    left: '6%',
    width: '6rem',
    height: '6rem'
  }
  static recruitment8 = {
    backgroundImage: `url(${recruitment8})`,
    bottom: `30%`,
    left: '14%',
    width: '2.5rem',
    height: '2.5rem'
  }
  static recruitment9 = {
    backgroundImage: `url(${recruitment9})`,
    bottom: `2%`,
    right: '40%',
    width: '3rem',
    height: '3rem'
  }

  static recruitment11 = {
    backgroundImage: `url(${recruitment11})`,
    bottom: `50%`,
    right: '4%',
    width: '1rem',
    height: '1rem'
  }

  static twoCircle = {
    backgroundImage: `url(${twoCircle})`,
    left: '-2%',
    bottom: '43%',
    width: '11%'
  }
  static countCircle = {
    backgroundImage: `url(${countCircle})`,
    width: '15%',
    right: '-6%',
    bottom: '4%',
  }
  static hexagon = {
    backgroundImage: `url(${hexagon})`,
    bottom: '-5.5%',
    right: '45%',
    width: '30%',
    // height: '15%'
  }
  static wQuote = {
    backgroundImage: `url(${whiteQuote})`,
    top: '2.5rem',
    right: '3rem',
    width: '3rem',
    height: '3rem'
  }
  static sTriangle = {
    backgroundImage: `url(${smallTriangle})`,
    bottom: '-1.3rem',
    left: '-3.8rem',
    width: '20%',
    height: '20%',
    opacity: '0.2',
  }
  static bQuote = {
    backgroundImage: `url(${blackQuote})`,
    top: '2.5rem',
    left: '3rem',
    width: '3rem',
    height: '3rem'
  }
  static bTriangle = {
    backgroundImage: `url(${bigTriangle})`,
    bottom: '0rem',
    left: '0rem',
    width: '100%',
    height: '100%',
    opacity: '0.09',
  }
  static tPattern = {
    backgroundImage: `url(${trianglePattern})`,
    bottom: '-1.6rem',
    right: '-1.8rem',
    width: '10rem',
    height: '10rem',
  }
  static paperAirplane = {
    backgroundImage: `url(${contactUs1})`,
    top: '35%',
    width: '13rem',
    height: '10rem',
    left: '-3%'
  }
  static flyPaperAirPlane = {
    backgroundImage: `url(${contactUs2})`,
    top: '60%',
    left: '82%',
    width: '22rem',
    height: '20rem'
  }
  static boxPattern1 = {
    backgroundImage: `url(${contactUs3})`,
    top: '5%',
    left: '16%',
    width: '9rem',
    height: '7rem'
  }
  static boxPattern2 = {
    backgroundImage: `url(${contactUs4})`,
    top: '90%',
    left: '73%',
    width: '8rem',
    height: '11rem'
  }
  static boxPattern3 = {
    backgroundImage: `url(${contactUs5})`,
    top: '78%',
    left: '8%',
    width: '5rem',
    height: '5rem'
  }
  static boxPattern4 = {
    backgroundImage: `url(${contactUs6})`,
    top: '35%',
    left: '88%',
    width: '5rem',
    height: '5rem'
  }
  static boxPatternPink = {
    backgroundImage: `url(${contactUs7})`,
    top: '6%',
    left: '75%',
    width: '1.3rem',
    height: '1.3rem'
  }
  static boxPatternBlue = {
    backgroundImage: `url(${contactUs8})`,
    top: '95%',
    left: '40%',
    width: '1.3rem',
    height: '1.3rem'
  }
  static backgroundDots = {
    backgroundImage: `url(/assets/about/about_us_icon_dots.svg)`,
    // transform: 'translate(90%, 10%)',
    top: '1%',
    right: '1rem',
    width: '7rem',
    height: '7rem'
  }
  static homeMainImage = {
    backgroundImage: `url(${homeMainImage})`,
    left: '58%',
    top: '0%',
    width: '10rem',
    height: '10rem',
  }
  static partnerImage1 = {
    backgroundImage: `url(${partnerImage1})`,
    width: '7rem',
    height: '6rem',
    top: '-2%',
  }
  static partnerImage2 = {
    backgroundImage: `url(${partnerImage2})`,
    height: '10rem',
    width: '11rem',
    left: '90%',
  }
  static namutechJapan = {
    backgroundImage: `url(${namutechJapan})`,
    height: '55rem',
    width: '25rem',
    left: '60%',
    top: '12%',
  }
  static namutechKorea = {
    backgroundImage: `url(${namutechKorea})`,
    height: '40rem',
    width: '25rem',
    top: '60%',
    left: '15%'
  }

  static infoBattery = {
    backgroundImage: `url(${info1})`,
    height: '4rem',
    width: '4rem',
    bottom: '15%',
    right: '3%'
  }

  static infoCube1 = {
    backgroundImage: `url(${info2})`,
    height: '5rem',
    width: '5rem',
    top: '87%',
    left: '7%',
    zIndex: 1,
  }

  static infoCube2 = {
    backgroundImage: `url(${info3})`,
    height: '5rem',
    width: '5rem',
    top: '45%',
    right: '12%',
  }

  static infoCube3 = {
    backgroundImage: `url(${info4})`,
    height: '5rem',
    width: '5rem',
    top: '20%',
    left: '5%',
  }

  static infoCube4 = {
    backgroundImage: `url(${info5})`,
    height: '2rem',
    width: '2rem',
    top: '82%',
    left: '40%',
    zIndex: 1,
  }

  static infoCube5 = {
    backgroundImage: `url(${info6})`,
    height: '4rem',
    width: '4rem',
    top: '5%',
    left: '50%',
  }

  static infoCube6 = {
    backgroundImage: `url(${info7})`,
    height: '4rem',
    width: '4rem',
    right: '7%',
    top: '20%',
  }

  static infoGrid1 = {
    backgroundImage: `url(${info8})`,
    height: '25rem',
    width: '25rem',
    top: '-2%',
    right: '-2%',
  }

  static infoGrid2 = {
    backgroundImage: `url(${info9})`,
    height: '30rem',
    width: '30rem',
    bottom: '-23%',
    left: '-2%',
  }

  static entryMail1 = {
    backgroundImage: `url(${entry1})`,
    height: '3rem',
    width: '3rem',
    top: '-2%',
    left: '10%',
  }
  static entryMail2 = {
    backgroundImage: `url(${entry2})`,
    height: '3rem',
    width: '3rem',
    right: '7%',
    top: '1%',
  }
  static entryMail3 = {
    backgroundImage: `url(${entry3})`,
    height: '3rem',
    width: '3rem',
    bottom: '3%',
    left: '50%',
  }
  static entryMail4 = {
    backgroundImage: `url(${entry4})`,
    height: '3rem',
    width: '3rem',
    bottom: '54%',
    left: '13%',
  }
  static entryPostBox = {
    backgroundImage: `url(${entry5})`,
    height: '14rem',
    width: '14rem',
    right: '-4%',
    bottom: '-1%',
  }
  static entryAtMark1 = {
    backgroundImage: `url(${entry6})`,
    height: '3rem',
    width: '3rem',
    bottom: '60%',
    right: '12%',
  }
  static entryAtMark2 = {
    backgroundImage: `url(${entry7})`,
    height: '3rem',
    width: '3rem',
    bottom: '20%',
    left: '5%',
  }
  static entryCube1 = {
    backgroundImage: `url(${entry8})`,
    height: '2.25rem',
    width: '2.25rem',
    bottom: '40%',
    right: '7%',
  }
  static entryCube2 = {
    backgroundImage: `url(${entry9})`,
    height: '3.75rem',
    width: '3.75rem',
    top: '10%',
    left: '8%',
  }
}
