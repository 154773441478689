import {createBrowserRouter} from "react-router-dom";
import React from 'react';
import ErrorPage from "../pages/errorPage";
import App from "../App"
import Home from "../pages/home";
import Introduce from "../pages/introduce";
import Information from "../pages/information";
import Communication from "../pages/communication";
import Services from "../pages/services";
import History from "../components/body/introduce/history/history";
import Overview from "../components/body/introduce/overview";
import Messages from "../components/body/information/messages/messages";
import Maps from "../components/body/information/access/maps";
import ChosenReason from "../components/body/introduce/chosenReason/chosenReason";
import About from "../components/body/introduce/about/About";
import Employment from "../components/body/information/employment/employment";
import CommunicationContact from "../components/body/communicationContact";
import {Constants} from "../features/constants/appConstants";
import PrivacyPolicy from "../pages/privacyPolicy";
import WorkerDispatchingAct from "../pages/workerDispatchingAct";
import Production from "../pages/production";

export const router = createBrowserRouter([
  {
    path: Constants.URL_PATH_HOME,
    element: <App/>,
    errorElement: <App errorPage={<ErrorPage/>}/>,
    children: [
      {
        index: true,
        element: <Home/>,
      },
      {
        path: Constants.URL_PATH_INTRODUCE,
        element: <Introduce/>,
        children: [
          {
            path: Constants.URL_PATH_INTRODUCE_ABOUT,
            element: <About/>,
          },
          {
            path: Constants.URL_PATH_INTRODUCE_MERIT,
            element: <ChosenReason/>,
          },
          {
            path: Constants.URL_PATH_INTRODUCE_HISTORY,
            element: <History/>,
          },
          {
            path: Constants.URL_PATH_INTRODUCE_INFO,
            element: <Overview/>,
          },
        ]
      },
      {
        path: Constants.URL_PATH_SERVICES,
        element: <Services/>,
        children: [
          {
            path: Constants.URL_PATH_SERVICES_IT_SYSTEM_SALES,
            element: <Services/>,
          },
          {
            path: Constants.URL_PATH_SERVICES_IT_SYSTEM_MAINTENANCE,
            element: <Services/>,
          },
          {
            path: Constants.URL_PATH_SERVICES_IT_SYSTEM_INFRASTRUCTURE,
            element: <Services/>,
          },
          {
            path: Constants.URL_PATH_SERVICES_VIRTUALIZATION,
            element: <Services/>,
          },
          {
            path: Constants.URL_PATH_SERVICES_SOFTWARE_DEVELOPMENT,
            element: <Services/>,
          },
          {
            path: Constants.URL_PATH_SERVICES_ENGINEER_OUTSOURCING,
            element: <Services/>,
          },
          {
            path: Constants.URL_PATH_SERVICES_MSP_SERVICE,
            element: <Services/>,
          }
        ]
      },
      {
        path: Constants.URL_PATH_INFORMATION,
        element: <Information/>,
        children: [
          {
            path: Constants.URL_PATH_INFORMATION_MESSAGES,
            element: <Messages/>,
          },
          {
            path: Constants.URL_PATH_INFORMATION_MAPS,
            element: <Maps/>,
          },
          {
            path: Constants.URL_PATH_INFORMATION_EMPLOYMENT,
            element: <Employment/>,
          },
        ]
      },
      {
        path: Constants.URL_PATH_COMMUNICATION,
        element: <Communication/>,
        children: [
          {
            path: Constants.URL_PATH_COMMUNICATION_CONTACT,
            element: <CommunicationContact/>,
          },
        ]
      },
      {
        path: Constants.URL_PATH_PRIVACY_POLICY,
        element: <PrivacyPolicy/>
      },

      // 근로자 파견 사업의 마진율 공시 추가 -김한-
      {
        path: Constants.URL_PATH_WORKER_DISPATCHING_ACT,
        element: <WorkerDispatchingAct/>
      },

      {
        path: Constants.URL_PATH_PRODUCTION,
        children: [
          {
            path: Constants.URL_PATH_PRODUCTION_MINERVA,
            element: <Production/>,
          }
        ]
      }
    ],
  },
]);
