import React from 'react';
import {Container} from "@mui/material";
import {DecoImage} from "../features/decoration/decoration";
import {DecoImages} from "../features/decoration/decoImages";
import {useWindowResizeToMobile} from "../features/util/responsive/mobile";
import styled from "@emotion/styled";

const PrivacyPolicy = () => {
  const isMobile = useWindowResizeToMobile();

  return (
    <div className={`bg-[#F7F8F5] mt-14 overflow-hidden`}>
      <div className={`flex justify-center relative overflow-hidden py-10 md:py-16`}>
        {!isMobile &&
            <>
              <DecoImage style={DecoImages.paperAirplane}/>
              <DecoImage style={DecoImages.flyPaperAirPlane}/>
              <DecoImage style={DecoImages.boxPattern1}/>
              <DecoImage style={DecoImages.boxPattern2}/>
              <DecoImage style={DecoImages.boxPattern3}/>
              <DecoImage style={DecoImages.boxPattern4}/>
              <DecoImage style={DecoImages.boxPatternPink}/>
              <DecoImage style={DecoImages.boxPatternBlue}/>
            </>}
        <Container maxWidth={'md'} sx={{zIndex: '20'}}>
          <h2
            className={"text-xl md:text-2xl text-[#243242] text-center font-medium mb-6 md:mb-10"}>個人情報保護方針</h2>
          <div
            className={`border border-black border-b-0 h-[2.5rem] bg-[#45AF7D] flex items-center pl-4 rounded-tl-[1rem] rounded-tr-[1rem] rounded-br-none rounded-bl-none`}>
            <div className={`w-4 h-4 rounded-full m-2 bg-[#FF577F]`}/>
            <div className={`w-4 h-4 rounded-full m-2 bg-[#F8D637]`}/>
            <div className={`w-4 h-4 rounded-full m-2 bg-[#2EDB48]`}/>
          </div>
          <div
            className={`border-[1.5px] border-black rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-none rounded-tl-none bg-[#DDF1E9]`}>
            <div className={`${!isMobile ? 'm-8' : 'm-5'} p-4 bg-white border-[1.5px] border-black rounded-[0.85rem]`}>
              <div
                className={"space-y-6 space-x-0 md:space-y-8 overflow-y-scroll max-h-[30rem] md:max-h-[45rem] text-xs md:text-base"}>
                <div className="text-start text-base font-semibold mt-3">
                  NAMU TECH JAPAN株式会社
                </div>
                <div className="text-end text-base font-medium pr-1">
                  制定日2023年10月13日
                  <br/>
                  代表取締役 李 秀秉
                </div>
                <div className={`text-base privacy-policy`}>
                  <p>
                    {`　`}NAMU TECH
                    JAPAN株式会社は、コンピュータ機器等の販売・輸出入、コンピュータシステムの運用・保守に関する業務、その他のＩＴ及び自動化設備技術サービス関連業務を通じてＩＴ社会に貢献することを企業理念とし、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利を保護し、個人情報に関する法令等を遵守致します。
                  </p>
                  <p>
                    {`　`}また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言致します。
                  </p>
                  <br/>
                  <ol className="list-decimal pl-7">
                    <li>
                      <p>
                        当社はコンピュータ機器等の販売・輸出入、コンピュータシステムの運用・保守に関する業務、その他のＩＴ及び自動化設備技術サービス関連業務、並びに従業者の雇用、人事管理等において取扱う個人情報について、適切な取得・利用及び提供を行い、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱いを行いません。また、そのための措置を講じます。
                      </p>
                    </li>
                    <li>
                      <p>
                        当社は個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します
                      </p>
                    </li>
                    <li>
                      <p>
                        当社は個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく、事業の実情に合致した経営資源を投入し、個人情報のセキュリティ対策を継続的に向上させていきます。また、万一の際には速やかに安全対策の是正措置を講じます。
                      </p>
                    </li>
                    <li>
                      <p>
                        当社は個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に対応致します。
                      </p>
                    </li>
                    <li>
                      <p>
                        個人情報保護マネジメントシステムは、当社を取り巻く環境の変化と実情を踏まえて適時・適切に見直し、継続的に改善をはかります。
                      </p>
                    </li>
                  </ol>
                  <br/>
                  <p>
                    当社の個人情報保護方針についてのお問合せは、以下の相談窓口までお寄せください。
                  </p>
                  <address className={`my-4`}>
                    <p>
                      〒113-0033 東京都文京区本郷1-20-3 中山ビル5階
                    </p>
                    <p>
                      NAMU TECH JAPAN株式会社
                    </p>
                    <p>
                      個人情報に関するお問合せ窓口担当
                    </p>
                    <p>
                      電話番号：03-6240-0471 メールアドレス：pms@namutech.co.jp
                    </p>
                    <p>
                      窓口受付時間：10:00～17:00（土日祝日を除く月曜日～金曜日）
                    </p>
                  </address>
                  <ol className={`list-roman pl-6`}>
                    {/* 여기서 부터가 로마숫자 1 */}
                    <li>
                      <p className={`font-medium text-lg`}>個人情報の取扱いについて</p>
                      <ol className={`list-decimal pl-7`}>
                        <li>
                          <p>
                            事業者の名称
                          </p>
                          <p>
                            NAMU TECH JAPAN株式会社
                          </p>
                        </li>
                        <li>
                          <p>
                            管理者の氏名、職名、所属及び連絡先
                          </p>
                          <p>
                            管理者：個人情報保護管理者 権 泰旭
                          </p>
                          <p>
                            連絡先：電話 03-6240-0471
                          </p>
                        </li>
                        <li>
                          <p>
                            個人情報の利用目的
                          </p>
                          <ol>
                            <li>
                              <p>3.1 保有個人データ（注1）の利用目的</p>
                              <ListWithBrackets>
                                <li>
                                  <p>
                                    お客様情報
                                  </p>
                                  <p>
                                    商談及び業務上の諸連絡、受注業務、請求業務等のため
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    株主情報
                                  </p>
                                  <p>
                                    情報公開、配布物の送付、連絡のため
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    委託先情報
                                  </p>
                                  <p>
                                    商談及び業務上の諸連絡、発注業務、支払業務等のため
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    従業者情報
                                  </p>
                                  <p>
                                    従業者の人事労務管理、業務管理、健康管理、セキュリティ管理のため
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    採用応募者情報
                                  </p>
                                  <p>
                                    採用業務のため
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    お問合せ者情報
                                  </p>
                                  <p>
                                    お問合せにご対応するため
                                  </p>
                                </li>
                                <p>
                                  (注1)ホームページのお問合せフォームや電子メール等によるものを含みます。
                                </p>
                              </ListWithBrackets>
                            </li>

                          </ol>
                        </li>
                        <li>
                          <p>
                            個人情報の第三者提供
                          </p>
                          <p>
                            当社は以下に該当する場合、お客様等（注2）の個人情報を第三者へ提供することがあります。
                          </p>
                          <ListWithBrackets>
                            <li>
                              <p>法令に基づく場合</p>
                            </li>
                            <li>
                              <p>ご本人様の同意がある場合</p>
                            </li>
                            <li>
                              <p>人の生命、身体または財産の保護のために必要がある場合であって、ご本人様の同意を得ることが困難である場合</p>
                            </li>
                            <li>
                              <p>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人様の同意を得ることが困難である場合</p>
                            </li>
                            <li>
                              <p>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</p>
                            </li>
                            <li>
                              <p>当社が利用目的を達成するために必要な範囲内において、お客様等から提供された情報の取り扱いの全部または一部を委託する場合</p>
                            </li>
                            <p>
                              (注2)当社のお客様、委託先、採用応募者及び従業員を指します。
                            </p>
                          </ListWithBrackets>
                        </li>
                        <li>
                          <p>
                            個人情報取扱いの委託
                          </p>
                          <p>
                            当社は事業運営上、業務をより適切かつ効果的に遂行するために業務の一部を外部に委託しています。業務委託先に対しては、お客様等の個人情報を預けることがあります。この場合、個人情報を適切に取り扱っていると認められる委託先を選定し、契約書等によって個人情報の適正管理・機密保持など、お客様等の個人情報の漏洩や不適切な利用の防止に必要な事項を取決め、適切な管理を実施させます。
                          </p>
                        </li>
                        <li>
                          <p>
                            個人情報を提供されることの任意性について
                          </p>
                          <p>
                            お客様等が当社に個人情報を提供されるかどうかは、お客様等の任意によるものです。ただし、必要な項目をいただけない場合、各サービス等が適切な状態で提供できない場合があります。
                          </p>
                        </li>
                        <li>
                          <p>
                            ご本人が容易に認識できない方法による取得する場合について
                          </p>
                          <p>
                            クッキー（Cookies）は、お客様等が当社のサイトに再度訪問された際、より便利に当サイトを閲覧していただくためのものであり、お客様等のプライバシーを侵害するものではなく、またお客様等のコンピューターへ悪影響を及ぼすこともありません。
                          </p>
                          <p>
                            また当社のサイトでは個人情報を入力していただく部分にはすべてSSL（Secure Sockets
                            Layer）のデータ暗号化システムを利用しております。さらに、サイト内における情報の保護にもファイアーウォールを設置するなどの方策を採っております。ただし、インターネット通信の性格上、セキュリティを完全に保証するものではありませんのであらかじめご了承ください。
                          </p>
                        </li>
                        <li>
                          <p>
                            認定個人情報保護団体について
                          </p>
                          <p>
                            当社が加入する認定個人情報保護団体はありません。
                          </p>
                        </li>
                        <li>
                          <p>
                            個人情報の開示等の請求
                          </p>
                          <p>
                            お客様等は、当社に対してご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者提供記録の開示、第三者への提供の停止）に関して、当社「個人情報に関するお問合せ窓口」に申し出ることができます。その際、当社はお客様等ご本人を確認させていただいたうえで、合理的な期間内に対応いたします。開示等のお申し出の詳細につきましては、「Ⅱ
                            開示対象個人情報の請求手続きのご案内」をご覧くだい。
                          </p>
                        </li>
                        <li>
                          <p>
                            個人情報に関するお問合せ・苦情・相談
                          </p>
                          <p>
                            お客様等の個人情報に関するお問合せ、苦情、ご相談につきましては、下記窓口で受付けております。
                          </p>
                          <p>
                            〒113-0033 東京都文京区本郷1-20-3 中山ビル5階
                          </p>
                          <p>
                            NAMU TECH JAPAN株式会社 個人情報に関するお問合せ窓口担当
                          </p>
                          <p>
                            メールアドレス：pms@namutech.co.jp
                          </p>
                          <p>
                            TEL：03-6240-0471（受付時間 10:00～17:00）
                          </p>
                        </li>
                      </ol>
                    </li>
                    {/* 여기서 부터가 로마숫자 1 */}
                    <li>
                      <p className={`font-medium text-lg`}>開示対象個人情報の請求手続きのご案内</p>
                      <p>
                        当社で保有している開示対象個人情報に関して、ご本人様又はその代理人様からの利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去、第三者提供記録の開示及び第三者への提供の停止の請求（以下、「開示等の請求」といいます）につきましては、当社所定の請求書により、以下の手続きにて対応させていただきます。
                      </p>
                      <ol className={`list-decimal pl-7`}>
                        <li>
                          <p>
                            「開示等の請求」申出先
                          </p>
                          <p>
                            「開示等の請求」は、「個人情報開示等請求書」にご請求の内容を記載の上、必要書類と手数料（利用目的の通知、開示の場合のみ）を、「個人情報に関するお問合せ窓口」宛、郵送によりお願い申し上げます。
                          </p>
                        </li>
                        <li>
                          <p>
                            「開示等の請求」に際してご提出いただく書類
                          </p>
                          <ListWithBrackets>
                            <p>
                              ＜ご本人による請求の場合＞
                            </p>
                            <li>
                              <p>
                                個人情報開示等請求書（請求書様式は当社からご郵送します）
                              </p>
                            </li>
                            <li>
                              <p>
                                本人確認のための書類
                              </p>
                            </li>
                            <p>
                              ＜代理人による請求の場合＞（代理人による請求の場合は上記に加え下記も必要です。）
                            </p>
                            <li>
                              <p>委任状</p>
                            </li>
                            <li>
                              <p>
                                代理人本人確認のための書類
                              </p>
                            </li>
                            <div>
                              <p>※
                                本人確認のための書類、代理人本人確認のための書類とは、以下のいずれか１点の、本人の名前、住所の記載があるもののコピーです。</p>
                              <ul className={`list-disc pl-12`}>
                                <li>
                                  運転免許証
                                </li>
                                <li>
                                  各種健康保険証
                                </li>
                                <li>
                                  年金手帳
                                </li>
                                <li>
                                  パスポート
                                </li>
                                <li>住民基本台帳カード</li>
                              </ul>
                              <p>
                                ※ 開示を希望される方が未成年又は成年被後見人の場合は、以下のいずれか１点も必要です。
                              </p>
                              <ul className={`list-disc pl-12`}>
                                <li>
                                  作成より６ヵ月以内の戸籍謄本（原本）
                                </li>
                                <li>
                                  成年後見登記事項証明書等
                                </li>
                              </ul>
                            </div>
                          </ListWithBrackets>
                        </li>
                        <li>
                          <p>
                            「開示等の請求」の手数料
                          </p>
                          <p>
                            利用目的の通知又は開示の請求の場合にのみ、１回のご請求につき、手数料（当社からの郵送費を含む）を申し受けます。1,000円分の郵便定額小為替を請求書類の郵送時に同封してください。
                          </p>
                        </li>
                        <li>
                          <p>
                            「開示等の請求」に関する書類等の郵送先
                          </p>
                          <p>
                            〒113-0033 東京都文京区本郷1-20-3 中山ビル5階
                          </p>
                          <p>
                            NAMU TECH JAPAN株式会社
                          </p>
                          <p>
                            個人情報に関するお問合せ窓口 宛
                          </p>
                        </li>
                        <li>
                          <p>
                            「開示等の請求」に対するご回答方法
                          </p>
                          <p>
                            原則として、請求書記載のご本人様住所宛に書面（封書郵送）にてご回答申し上げます。
                          </p>
                          <p>
                            ◇「開示等の請求」にともない取得した個人情報は、開示等の請求への対応に必要な範囲に限り取り扱います。
                          </p>
                          <br/>
                          <p>
                            ◇以下の場合には、「開示等の請求」にお応えできない場合があります。その場合は、その旨と理由をご通知申し上げます。また、不開示の場合でも手数料をいただきますのでご承知ください。
                          </p>
                          <ListWithCircles>
                            <li>
                              ご本人様又は代理人様の本人確認ができない場合
                            </li>
                            <li>
                              所定の申請書類に不備があった場合
                            </li>
                            <li>開示等の請求の対象が「開示対象個人情報」（注3）に該当しない場合</li>
                            <li>利用目的を本人に通知し、又は公表することによって本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
                            <li>利用目的を本人に通知し、又は公表することによって当社の権利又は正当な利益を害するおそれがある場合</li>
                            <li>国の機関又は地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用目的を本人に通知し、又は公表することによって当該事務の遂行に支障を及ぼすおそれがある場合</li>
                            <p>
                              (注3)開示対象個人情報とは、当社が、ご本人から求められる開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の求めのすべてに応じることができる権限を有するものです。
                            </p>
                          </ListWithCircles>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

// 괄호를 넣는 ol 태그.
const ListWithBrackets = styled.ol`
  counter-reset: list-counter;

  > li {
    list-style: none;
    position: relative;
    padding-left: 2.8rem;

    //&:last-child::before {
    //  content: none; /* 마지막 li 요소는 숫자를 표시하지 않음 */
    //}
  }

  > li::before {
    content: ' ( ' counter(list-counter) ' ) ';
    counter-increment: list-counter;
    position: absolute;
    left: 0;
  }
`;
// 원안에 숫자
const ListWithCircles = styled.ol`
  counter-reset: list-counter;
  list-style: none;
  padding-left: 0;
  
  > li {
    position: relative;
    padding-left: 1.8rem;
    display: flex;
  }

  > li::before {
    counter-increment: list-counter;
    content: counter(list-counter, decimal); /* 0 없이 숫자를 표시 */
    position: absolute;
    left: 0;
    top: 0.15rem;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background-color: white; /* 원의 배경색 */
    border: 1px solid #c4c4c4;
    color: #2f2f2f; /* 숫자의 색상 */
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem; /* 숫자의 크기 */
  }
`;
