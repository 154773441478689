import React from 'react';
import {DataButton, DownloadButton} from "../../features/styledComponents/common/Button";

const DownloadButtonDeco = (props: any) => {
  let btn
  if (props.types === 'entry') {
    btn = <DownloadButton {...props}/>
  } else if (props.types === 'homeMain') {
    btn = <DataButton {...props}/>
  }
  return btn;
};

export default DownloadButtonDeco;
