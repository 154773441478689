import React from 'react';
import OfferingEssentialPoint from "./offeringEssentialPoint";
import SelectionProcess from "./selectionProcess";
import Entry from "./entry";
import Jobs from "./jobs";
import useIntersectionObserver from "../../../../features/util/commonFacility/sectionsObserver";
import {
  IsVisibleBoxDivStyled,
} from "../../../../features/styledComponents/common/isVisibleBox";

const Employment = () => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0});
  return (
    <IsVisibleBoxDivStyled isVisible={isVisible} ref={innerRef} className={`overflow-hidden`}>
      <Jobs/>
      <OfferingEssentialPoint/>
      <SelectionProcess/>
      <Entry/>
    </IsVisibleBoxDivStyled>
  );
};

export default Employment;
