import React, {useEffect, useState} from 'react';
import {useData} from '../../../features/reducer/navReducer'
import {useNavigate} from "react-router-dom";
import {Constants} from "../../../features/constants/appConstants";
import BannerStyle from "./bannerStyle";
import BannerProductionStyle from "./bannerProductionStyle";
import {navMenuIdForBanner} from "../../../features/util/commonFacility/objectsForEtc";
import {navMenuIdForBannerKeyType} from "../../../features/types/etcKeyType";
import {NavMenuData, SubNavMenuData} from "../../../features/types/globals.d";

const Banner = () => {
  let resultPage = null;
  const {state} = useData();
  const navigate = useNavigate();
  const subPageDesc = state.sharedData;
  const [content, setContent] = useState<any>(null); // 内容を管理する状態
  const [bannerStyleData, setImg] = useState<navMenuIdForBannerKeyType>()
  const value: { subNavMenu?: SubNavMenuData | NavMenuData | null | undefined } = subPageDesc

  useEffect(() => {
    // ローカルストレージが空になるとメインページに戻る
    if (!subPageDesc || Object.keys(subPageDesc).length === 0) {
      navigate('/');
    }

    updateBannerImageSrc();

    if (value) {
      setContent(value);
    }
  }, [state, navigate, subPageDesc]);

  const updateBannerImageSrc = () => {
    if (subPageDesc &&
        ('navMenuId' in subPageDesc) &&
        (typeof subPageDesc.navMenuId === 'number') &&
        ('subNavMenuId' in subPageDesc) &&
        (typeof subPageDesc.subNavMenuId === 'number')
    ) {
      const navMenuObject = navMenuIdForBanner[subPageDesc?.navMenuId]
      if (navMenuObject) {
        const bannerImageSrc: navMenuIdForBannerKeyType = navMenuObject[subPageDesc.subNavMenuId];
        setImg(bannerImageSrc)
      }
    }
  }

  if (Object.keys(subPageDesc).length > 1) {
    if (subPageDesc && ('navMenuId' in subPageDesc) && ('open' in subPageDesc) && ('subNavMenuId' in subPageDesc)) {
      if (!(subPageDesc.navMenuId === Constants.NAV_MENU_HOME ||
          (subPageDesc.navMenuId === Constants.NAV_MENU_INFORMATION && subPageDesc.open))) {
        let isProduction = subPageDesc.navMenuId === Constants.NAV_MENU_PRODUCTION
        resultPage = isProduction ? <BannerProductionStyle bannerStyleData={bannerStyleData} content={content} />
                                    :<BannerStyle bannerStyleData={bannerStyleData} content={content}/>
      }
    }
  }

  return resultPage;
};

export default Banner;
