import React, {useState} from 'react';
import {useWindowResizeToMobile} from "../../../../features/util/responsive/mobile";
import {commonServiceTypes} from "../../../../features/types/service.d";

const CommonServiceTypeA = ({serviceContent, decoImage, mainImageClassName, pClassName}: commonServiceTypes) => {
  const isMobile = useWindowResizeToMobile();
  const [isLoading, setIsLoading] = useState(true);

  let imgSrc = isMobile ? serviceContent.mobileSrcService1 : serviceContent.srcServices1;

  return (
    <div className={`
      flex items-center -mt-3 flex-col w-[60rem] z-20 justify-center min-h-96
      md:mt-5 md:mt-0 md:mb-5`}>
      <img className={`p-4 md:p-2 ${mainImageClassName}`}
           src={imgSrc}
           onLoad={() => setIsLoading(false)}
           alt={serviceContent.title}/>
      <div className={`
          flex flex-col items-center text-center ${isLoading ? 'opacity-0':'opacity-100'}
          md:flex-row md:items-center md:justify-center md:text-center md:mt-4`}>
        {decoImage}
        <p className={`text-[0.875rem] mt-5 leading-5 self-end text-center mb-0 w-80 h-auto
           md:font-medium md:text-xl md:leading-8 md:w-[32rem] 
         ${pClassName}`} dangerouslySetInnerHTML={{__html: serviceContent.desc}}></p>
      </div>
    </div>
  );
};

export default CommonServiceTypeA;
