import React, {useState} from 'react';
import {commonServiceTypes} from "../../../../features/types/service.d";

const CommonServiceTypeB = ({serviceContent, mainImageClassName, decoImage, pClassName}: commonServiceTypes) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="flex flex-col items-center justify-between py-5 h-full
    md:flex-row md:py-12 md:ml-10 md:h-auto">
      <img className={`${mainImageClassName}`}
           src={serviceContent.srcServices1}
           onLoad={() => setIsLoading(false)}
           alt={serviceContent.title}/>
      <div className={`w-[70%] h-[70%] flex flex-col items-center -mt-3
       ${isLoading ? 'opacity-0':'opacity-100'}
       md:w-full md:h-2/5 md:ml-6`}>
        <div className={`flex justify-center mb-0 mt-12 relative
        md:mt-0 md:-mb-5`}>
          {decoImage}
        </div>
        <p className={`${pClassName}`} dangerouslySetInnerHTML={{__html: serviceContent.desc}}/>
      </div>
    </div>
  );
};

export default CommonServiceTypeB;
