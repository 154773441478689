import ErrorPage404 from "../../assets/img/Error/not_found.png"

const ErrorPage = ({errMsg = "notFound"}) => {
  let msg;
  switch (errMsg) {
    case "noAccess":
      msg = "接近権限がありません"
      break;
    case 'notFound':
      msg = "ご指定のページが見つかりませんでした。"
      break;
    default:
      break
  }

  return (
      <article className={`pt-28 pb-40 px-0 flex justify-center overflow-hidden`}>
        <div className={`flex flex-col items-center w-[53rem] text-center`}>
          <img src={ErrorPage404} alt={'Not Found'} className={`animate-pulse px-14 mt-4 lg:px-36 lg:mt-9`}/>
          <div className={`py-4 flex flex-col justify-center align-center items-center`}>
            <h2 className={`font-bold my-7 mx-6 text-purple-600 text-lg md:text-2xl`}>{msg}</h2>
            <p className={`leading-relaxed mx-6 text-xs md:text-sm`}>
              アクセスしようとしたページは削除、変更されたか、現在利用できない可能性があります。<br/>
              お手数をおかけしますが、NAMUTECHJAPANホームページのトップ、<br/>
              または上部のメニューよりおさがしいただきますようお願いいたします。
            </p>
          </div>
          <hr/>
        </div>
      </article>
  );
};

export default ErrorPage;
