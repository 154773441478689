export const PersonalInformationContent: personalInformationContentData[] = [
  {
    contents: '<p>事業者の名称</p>' +
      '<p>NAMU TECH JAPAN株式会社</p>'
  },//1
  {
    contents: '<p className="font-semibold">個人情報保護管理者氏名、職名及び連絡先</p>' +
      '<p className="font-semibold">管理者：個人情報保護管理者　権 泰旭</p>' +
      '連絡先：電話 03-6240-0471'
  },//2
  {
    contents: '<p>個人情報の利用目的</p>' +
      '<p>お預かりした個人情報は、お客様のお問い合わせへのご回答のために利用します。</p>'
  },//3
  {
    contents: '<p>お預かりする個人情報の項目</p>' +
      '<p>お問い合わせ画面でご入力いただく項目</p>'
  },//4
  {
    contents: '<p>個人情報の第三者提供について</p>' +
      '<p>ご本人の同意がある場合または法令に基づく場合を除き、今回ご入力いただく個人情報は第三者に提供しません。</p>'
  },//5
  {
    contents: '<p>個人情報の委託について</p>' +
      '<p>個人情報の取り扱いを外部に委託する場合は、' +
      '当社が規定する個人情報管理基準を満たす企業を選定して委託を行い、' +
      '適切な取り扱いが行われるよう監督します。</p>'
  },//6
  {
    contents: '<p>取得した個人情報の開示等およびお問い合わせ窓口</p>' +
      '<p>ご本人からの求めにより、当社に対してご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者提供記録の開示、第三者への提供の停止）に関して、当社「個人情報に関するお問合せ窓口」に申し出ることができます。</p>' +
      '<p>個人情報に関するお問合せ窓口担当</p>' +
      '<p style="margin-left: 32px">〒113-0033 東京都文京区本郷1-20-3 中山ビル5階</p>' +
      '<p style="margin-left: 32px">NAMU TECH JAPAN株式会社</p>' +
      '<p style="margin-left: 32px">個人情報に関するお問合せ窓口担当</p>' +
      '<p style="margin-left: 32px">電話番号：03-6240-0471 メールアドレス：pms@namutech.co.jp</p>' +
      '<p style="margin-left: 32px">窓口受付時間：10:00～17:00（土日祝日を除く月曜日～金曜日）</p>'
  },//7
  {
    contents: '個個人情報を与えることの任意性及び当該情報を与えなかった場合に生じる結果<br/>' +
      '個人情報を取得する項目は、全てご本人によってご提供いただくものです。<br/>' +
      'ただし、必要な項目をいただけない場合、利用目的に記載の諸手続又は処理に支障が生じる可能性があります。<br/>'
  },//8
  {
    contents: '<p>本人が容易に知覚できない方法による個人情報の取得</p>' +
      '<p>本フォームでは、セッション管理のためCookieを使用しています。</p>'
  }//9
]
export type personalInformationContentData = {
  contents: string;
}
