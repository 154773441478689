import {Constants} from "../../constants/appConstants";
import {NavMenuData} from "../../types/globals.d";

export const navMenuIdForBanner = {
  [Constants.NAV_MENU_INTRODUCE]: {
    [Constants.SUB_NAV_INTRODUCE_ABOUT]: {
      // imageUrl: IntroduceAbout,
      imageUrl: Constants.SUB_NAV_INTRODUCE_ABOUT_IMAGE_URL,
      style: {transform: 'translate(80%,-15%) scale(1.2)',maxWidth:'34rem'},
      styleTablet: {transform: 'translate(70%,-20%) scale(1.2)'},
      styleMobile: {transform: 'translate(50%,-20%) scale(1.3)',maxWidth:'17.6rem'},
      titleStyle: {color: '#00B2A5',transform: 'translate(0%,100%)'},
      mobileTitleStyle:{color: '#00B2A5',transform: 'translate(-10%,20%)'},
      contentStyle: {color: '#243242'},
    },
    [Constants.SUB_NAV_INTRODUCE_MERIT]: {
      // imageUrl: IntroduceReason,
      imageUrl: Constants.SUB_NAV_INTRODUCE_MERIT_IMAGE_URL,
      style: {transform: 'translate(75%,-30%) scale(1.1)', top: '-5%',maxWidth:'34rem'},
      styleTablet: {transform: 'translate(100%,-20%) scale(1)', maxWidth:'17.6rem'},
      styleMobile: {transform: 'translate(20%,-34%) scale(0.9)', maxWidth:'17.6rem'},
      titleStyle: {color: '#F27900',transform: 'translate(0%,100%)'},
      mobileTitleStyle:{color: '#F27900', transform: 'translate(-10%,20%)'},
      contentStyle: {color: '#243242'},
    },
    [Constants.SUB_NAV_INTRODUCE_HISTORY]: {
      // imageUrl: IntroduceHistory,
      imageUrl: Constants.SUB_NAV_INTRODUCE_HISTORY_IMAGE_URL,
      style: {transform: 'translate(15%,-10%) scale(1.1)',maxWidth:'50rem'},
      styleTablet: {transform: 'translate(0%,-16%) scale(1)',maxWidth:'37rem'},
      styleMobile: {transform: 'translate(-5%,-16%) scale(1)',maxWidth:'30rem'},
      titleStyle: {color: '#00887E',transform: 'translate(0%,100%)'},
      mobileTitleStyle:{color: '#00887E', transform: 'translate(-10%,20%)'},
      contentStyle: {color: '#243242'},
    },
    [Constants.SUB_NAV_INTRODUCE_INFO]: {
      imageUrl: Constants.SUB_NAV_INTRODUCE_INFO_IMAGE_URL,
      style: {transform: 'translate(42%,-20%) scale(1.2)',maxWidth:'45rem'},
      styleTablet: {transform: 'translate(42%,-21%) scale(1)',maxWidth:'30rem'},
      styleMobile: {transform: 'translate(16%,-21%) scale(1)',maxWidth:'23rem'},
      titleStyle: {color: '#9850D6',transform: 'translate(0%,100%)'},
      mobileTitleStyle:{color: '#9850D6', transform: 'translate(-10%,20%)'},
      contentStyle: {color: '#243242'},
    },
  },
  [Constants.NAV_MENU_SERVICES]: {
    [Constants.SUB_NAV_SERVICES_IT_INFRASTRUCTURE]: {
      imageUrl: Constants.SUB_NAV_SERVICES_IT_INFRASTRUCTURE_IMAGE_URL,
      style: {transform: 'translate(7%,0%) scale(1.1)'},
      styleTablet: {transform: 'translate(0%,0%) scale(1)', maxWidth:'30rem'},
      styleMobile: {transform: 'translate(0%,0%) scale(0.8)'},
      titleStyle: {color: '#0A9057',transform: 'translate(0%,0%)'},
      mobileTitleStyle:{color: '#0A9057'},
      contentStyle: {color: '#243242'}
    },
    [Constants.SUB_NAV_SERVICES_VIRTUALIZATION]: {
      imageUrl: Constants.SUB_NAV_SERVICES_VIRTUALIZATION_IMAGE_URL,
      style: {transform: 'translate(5%,0%) scale(1.1)'},
      styleTablet: {transform: 'translate(10%,0%) scale(0.9)', maxWidth:'30rem'},
      styleMobile: {transform: 'translate(0%,0%) scale(0.9)'},
      titleStyle: {color: '#DE50A2',transform: 'translate(0%,0%)'},
      mobileTitleStyle:{color: '#DE50A2'},
      contentStyle: {color: '#243242'}
    },
    [Constants.SUB_NAV_SERVICES_MSP_SERVICE]: {
      // imageUrl: ServicesMSP_SERVICE,
      imageUrl: Constants.SUB_NAV_SERVICES_MSP_SERVICE_IMAGE_URL,
      style: {transform: 'translate(10%,0%) scale(1)'},
      styleTablet: {transform: 'translate(6%,0%) scale(0.9)', maxWidth:'30rem'},
      styleMobile: {transform: 'translate(0%,0%) scale(0.9)'},
      titleStyle: {color: '#EA5F12',transform: 'translate(21%,0%)'},
      mobileTitleStyle:{color: '#EA5F12'},
      contentStyle: {color: '#243242'}
    },
    [Constants.SUB_NAV_SERVICES_SOFTWARE_DEVELOPMENT]: {
      // imageUrl: ServicesSOFTWARE_DEVELOPMENT,
      imageUrl: Constants.SUB_NAV_SERVICES_SOFTWARE_DEVELOPMENT_IMAGE_URL,
      style: {transform: 'translate(10%,0%) scale(1.1)'},
      styleTablet: {transform: 'translate(6%,0%) scale(0.9)', maxWidth:'30rem'},
      styleMobile: {transform: 'translate(0%,0%) scale(0.8)'},
      titleStyle: {color: '#008596',transform: 'translate(0%,0%)'},
      mobileTitleStyle:{color: '#008596'},
      contentStyle: {color: '#243242'}
    },
    [Constants.SUB_NAV_SERVICES_ENGINEER_OUTSOURCING]: {
      // imageUrl: ServicesENGINEER_OUTOURCING,
      imageUrl: Constants.SUB_NAV_SERVICES_ENGINEER_OUTSOURCING_IMAGE_URL,
      style: {transform: 'translate(10%,0%) scale(1.1)'},
      styleTablet: {transform: 'translate(6%,0%) scale(0.9)', maxWidth:'30rem'},
      styleMobile: {transform: 'translate(0%,0%) scale(0.8)'},
      titleStyle: {color: '#006EA8',transform: 'translate(1%,0%)'},
      mobileTitleStyle:{color: '#006EA8'},
      contentStyle: {color: '#243242'}
    },
    [Constants.SUB_NAV_SERVICES_IT_SYSTEM_MAINTENANCE]: {
      // imageUrl: ServicesIT_SYSTEM_MAINTENANCE,
      imageUrl: Constants.SUB_NAV_SERVICES_IT_SYSTEM_MAINTENANCE_IMAGE_URL,
      style: {transform: 'translate(10%,0%) scale(1.1)'},
      styleTablet: {transform: 'translate(6%,0%) scale(0.9)', maxWidth:'30rem'},
      styleMobile: {transform: 'translate(0%,0%) scale(0.8)'},
      titleStyle: {color: '#F4B800',transform: 'translate(11%,0%)'},
      mobileTitleStyle:{color: '#F4B800'},
      contentStyle: {color: '#243242'}
    },
    [Constants.SUB_NAV_SERVICES_IT_SYSTEM_SALES]: {
      // imageUrl: ServicesIT_SYSTEM_SALES,
      imageUrl: Constants.SUB_NAV_SERVICES_IT_SYSTEM_SALES_IMAGE_URL,
      style: {transform: 'translate(15%,0%) scale(1.1)'},
      styleTablet: {transform: 'translate(6%,0%) scale(0.9)', maxWidth:'30rem'},
      styleMobile: {transform: 'translate(0%,0%) scale(0.8)'},
      titleStyle: {color: '#00A760',transform: 'translate(8%,0%)'},
      mobileTitleStyle:{color: '#00A760'},
      contentStyle: {color: '#243242'}
    },
  },
  [Constants.NAV_MENU_INFORMATION]: {
    [Constants.SUB_NAV_INFORMATION_MAPS]: {
      // imageUrl: InformationAccess,
      imageUrl: Constants.SUB_NAV_INFORMATION_MAPS_IMAGE_URL,
      style: {transform: 'translate(40%,-10%) scale(1.3)', maxWidth:'48rem'},
      styleTablet: {transform: 'translate(45%,0%) scale(1.9)'},
      styleMobile: {transform: 'translate(60%,45%) scale(1.9)'},
      titleStyle: {color: '#0056CC',transform: 'translate(-10%, 100%)'},
      mobileTitleStyle:{color: '#0056CC',transform: 'translate(-20%,0%)'},
      contentStyle: {color: '#243242'},
    },
    [Constants.SUB_NAV_INFORMATION_MESSAGES]: {
      // imageUrl: InformationMessage,
      imageUrl: Constants.SUB_NAV_INFORMATION_MESSAGES_IMAGE_URL,
      style: {transform: 'translate(40%,-25%) scale(1)'},
      styleTablet: {transform: 'translate(50%,-20%) scale(1.6)'},
      styleMobile: {transform: 'translate(50%,-10%) scale(1.6)'},
      titleStyle: {color: '#00A0C6',transform: 'translate(-10%,100%)'},
      mobileTitleStyle:{color: '#00A0C6',transform: 'translate(-20%,0%)'},
      contentStyle: {color: '#243242'},
    },
    [Constants.SUB_NAV_INFORMATION_EMPLOYMENT]: {
      // imageUrl: InformationRecruit,
      imageUrl: Constants.SUB_NAV_INFORMATION_EMPLOYMENT_IMAGE_URL,
      style: {transform: 'translate(30%,-28%) scale(1.3)', maxWidth:'48.3rem'},
      styleTablet: {transform: 'translate(50%,20%) scale(1.4)'},
      styleMobile: {transform: 'translate(50%,20%) scale(1.4)'},
      titleStyle: {color: '#1876D2',transform: 'translate(-10%,100%)'},
      mobileTitleStyle:{color: '#1876D2',transform: 'translate(-20%,0%)'},
      contentStyle: {color: '#243242'},
    },
  },
  [Constants.NAV_MENU_COMMUNICATION]: {
    [Constants.SUB_NAV_COMMUNICATION_CONTACT]: {
      // imageUrl: CommunicationContact,
      imageUrl: Constants.SUB_NAV_COMMUNICATION_CONTACT_IMAGE_URL,
      style: {transform: 'translate(14%, 8%) scale(1.8)',maxWidth:'48rem'},
      styleTablet: {transform: 'translate(50%,-0%) scaleX(-1)'},
      styleMobile: {transform: 'translate(30%,-0%) scaleX(-1)'},
      titleStyle: {color: '#10B6D5'},
      mobileTitleStyle:{color: '#10B6D5',transform: 'translate(-20%,0%)'},
      contentStyle: {color: '#243242'},
    },
  },
  [Constants.NAV_MENU_PRODUCTION]: {
    [Constants.SUB_NAV_PRODUCTION_MINERVA]: {
      imageUrl: Constants.SUB_NAV_PRODUCTION_MINERVA_IMAGE_URL,
      backgroundUrl:Constants.SUB_NAV_PRODUCTION_MINERVA_BACKGROUND_IMAGE_URL,
      mobileBackgroundUrl:Constants.SUB_NAV_PRODUCTION_MINERVA_MOBILE_BACKGROUND_IMAGE_URL,
      style: {transform: 'translate(-15%,0%) scale(0.7)',},
      styleTablet: {transform: 'translate(-25%,0%) scale(0.5)'},
      // styleMobile: {transform: 'translate(0%,-20%) scale(0.3)', },
      styleMobile: {transform: 'translate(-35%,0%) scale(0.4)'},
      // titleStyle: {color: '#2783FF', transform:'translate(0%,0%)'},
      titleStyle: {color: '#2783FF'},
      // mobileTitleStyle:{color: '#2783FF', transform:'translate(0%,0%) scale(0.65) ',},
      mobileTitleStyle:{color: '#2783FF', transform:'translate(-35%,0%) scale(0.65)', marginTop:'-15%'},
      contentStyle: {color: '#243242'},
    },
  },
}
