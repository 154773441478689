import React from 'react';
import {Theme, ThemeProvider} from "@mui/material";

const MuiTheme = ({children, theme}: { children: React.ReactNode, theme: Theme }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};
export default MuiTheme;
