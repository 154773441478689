import React from 'react';
import BodyGroup from "../components/common/bodyGroup/bodyGroup";
import {subNavMenuData} from "../../assets/data/menuData";
import {Constants} from "../features/constants/appConstants";
import {Tab} from "../features/types/globals.d";
import Messages from "../components/body/information/messages/messages";
import Employment from "../components/body/information/employment/employment";
import Maps from "../components/body/information/access/maps";


const Information = () => {
  const tabs: Tab[] = [
    {key: Constants.SUB_NAV_INFORMATION_MAPS, content: <Maps/>},
    {key: Constants.SUB_NAV_INFORMATION_MESSAGES, content: <Messages/>},
    {key: Constants.SUB_NAV_INFORMATION_EMPLOYMENT, content: <Employment/>},
  ]
  return (
    <BodyGroup
      data={subNavMenuData[Constants.NAV_MENU_INFORMATION]}
      tabs={tabs}
      type={Constants.NAV_MENU_INFORMATION}
    />
  );
};

export default Information;
