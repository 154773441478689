import React from 'react';
import {Box, IconButton, Modal} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useWindowResizeToTablet} from "../../features/util/responsive/tablet";

type muiModalProps = {
  open: boolean;
  onClose: () => void;
  children: React.JSX.Element;
  title?: string;
}
const MuiModal = ({open, onClose, children, title}: muiModalProps) => {
  const isMobile = useWindowResizeToTablet();
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'relative', // 또는 'fixed'로 화면에 고정
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // 화면의 정중앙에 배치
          minWidth: '17rem',
          maxWidth:'35rem',
        width: '70%',
        height: '20rem',
        borderRadius: '0.8rem',
        border: '1.5px solid black',
        bgcolor: '#DFEAF4', // 배경색 설정
        boxShadow: 24, // 그림자 효과
      }}>
        {/* X 버튼. 모달 바깥에 위치하도록 스타일링 */}
        <IconButton
          onClick={onClose}
          sx={{
            width: '3rem',
            position: 'fixed',
            right: isMobile ? 0 : 2, // 오른쪽에서 8px 떨어진 위치
            top: isMobile ? 0 : 4, // 상단에서 8px 떨어진 위치
          }}
        >
          <CloseIcon fontSize="large"/>
        </IconButton>
        {/* 모달의 내용 */}
        <Box>
          {title && <h2 style={{
            color: 'white',
            backgroundColor: title === '転送完了' ?  '#1876D2' : '#FF577F',
            borderTopLeftRadius: '0.7rem',
            borderTopRightRadius: '0.7rem',
            borderBottom: '1.5px solid black',
            width: '100%',
            textAlign: 'center',
            padding: '1rem',
            fontWeight: '400',
            fontSize: '1.2rem',
            boxShadow: '0rem 0.3rem 0.6rem -0.3rem rgba(0,0,0,0.1)'
          }}>{title}</h2>}
        </Box>
        <Box>
          {children}
        </Box>
      </Box>
    </Modal>

  );
};

export default MuiModal;
