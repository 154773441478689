import React, {useState} from 'react';
import background_engineer from "../../../../../assets/img/jobs/Engineering_1.svg"
import number_engineer from "../../../../../assets/img/jobs/Engineering_2.svg"
import background_officer from "../../../../../assets/img/jobs/Accounting1.svg"
import number_officer from "../../../../../assets/img/jobs/Accounting2.svg"
import BasicTabs from "../../../common/tabBtnBox";
import {useWindowResizeToMobile} from "../../../../features/util/responsive/mobile";
import {
  EngineerContainerBtnStyled,
  EngineerContainerDetailStyled,
  EngineerContainerImageStyled,
  JobsContainerStyled,
  OfficerContainerBtnStyled,
  OfficerContainerDetailStyled,
  OfficerContainerImageStyled
} from "../../../../features/styledComponents/information/employment/jobsStyled";
import mobileNumberOneIcon from "../../../../../assets/img/jobs/mobile_recruit_one_icon.svg"
import mobileNumberTwoIcon from "../../../../../assets/img/jobs/mobile_recruit_two_icon.svg"
import mobileDesktopIcon from "../../../../../assets/img/jobs/mobile_recruit_desktop_icon.svg"
import mobileLaptopIcon from "../../../../../assets/img/jobs/mobile_recruit_notebook_icon.svg"
import mobileLeftArrow from "../../../../../assets/img/jobs/mobile_recruit_leftarrow_icon.svg"
import mobileRightArrow from "../../../../../assets/img/jobs/mobile_recruit_rightarrow_icon.svg"
import mobileLeftArrowWhite from "../../../../../assets/img/jobs/mobile_recruit_leftarrow_w_icon.svg"
import mobileRightArrowWhite from "../../../../../assets/img/jobs/mobile_recruit_rightarrow_w_icon.svg"
import {useWindowResizeToTablet} from "../../../../features/util/responsive/tablet";

const Jobs = () => {

  type Data = {
    workType: string;
    desc: string;
  }

  const engineerData: Data[] = [
    {
      workType: "カスタムエンジニア",
      desc: "DELL、HP、IBMサーバー、ストレージの設計、保守を担当するCEの募集をしています。"
    },
    {
      workType: "システムエンジニア",
      desc: "Linuxサーバー、Windowsサーバー、Unixサーバーの設計、運用、または、Openstack、Openshift、Kubernetesなどの仮想化プラットフォームの設計、運用を担当するSEの募集をしています。"
    },
    {
      workType: "ネットワークエンジニア",
      desc: "Ciscoネットワーク機器、無線ネットワーク、基地局機器の設計、運用、保守を担当するネットワークエンジニアの募集をしています。"
    },
    {
      workType: "ソフトウェアエンジニア",
      desc: "フロントエンド、バックエンドの開発を担当する開発者の募集をしています。"
    }
  ]

  const officerData: Data[] = [
    {
      workType: "経理、総務",
      desc: "経理、総務の担当を募集しています。"
    },
    {
      workType: "営業",
      desc: "IT機器、ソフトウェアの営業の担当を募集しています。"
    }
  ]
  const isMobile = useWindowResizeToMobile();
  const isTablet = useWindowResizeToTablet();
  const [engineerSlide, setEngineerSlide] = useState(0);
  const engineerNextSlide = () => {
    const newSlide = engineerSlide + 1 >= engineerData.length ? 0 : engineerSlide + 1;
    setEngineerSlide(newSlide);
  };
  const engineerPrevSlide = () => {
    const newSlide = engineerSlide - 1 < 0 ? engineerData.length - 1 : engineerSlide - 1;
    setEngineerSlide(newSlide);
  };

  const [officerSlide, setOfficerSlide] = useState(0);
  const officerNextSlide = () => {
    const newSlide = officerSlide + 1 >= officerData.length ? 0 : officerSlide + 1;
    setOfficerSlide(newSlide);
  };
  const officerPrevSlide = () => {
    const newSlide = officerSlide - 1 < 0 ? officerData.length - 1 : officerSlide - 1;
    setOfficerSlide(newSlide);
  };

  return !(isMobile || isTablet) ? (
    <JobsContainerStyled>
      <h1 className="font-semibold mb-4">仕事</h1>
      <div className={"flex justify-center"}>
        <div className="flex flex-row flex-wrap bg-white ml-28 -mb-16 relative xl:max-w-7xl ">
          <img style={{width: '100%', flex: '1', paddingRight: '10rem', paddingLeft: '2rem'}} src={number_engineer}
               alt={"Engineer"}/>
          <EngineerContainerDetailStyled className="text-3xl text-[#414042] ml-20 mt-8 font-normal">
            <div style={{marginBottom: '1rem'}}>
              エンジニアリング
            </div>
            <BasicTabs data={engineerData}/>
          </EngineerContainerDetailStyled>
          <EngineerContainerImageStyled className="-mb-10">
            <img style={{width: '90%', position: 'relative',  right: '-2%', top: '-40%'}} src={background_engineer} alt={"Engineer"}/>
            {/*<img style={{position: 'relative', right: '-13%'}} src={background_engineer} alt={"Engineer"}/>*/}
          </EngineerContainerImageStyled>

        </div>
      </div>

      <div className={"flex justify-center"} style={{backgroundColor: "#1876D2"}}>
        <OfficerContainerBtnStyled className="mt-16 mb-20 xl:max-w-7xl ">
          <img style={{width: '100%', flex: '1', paddingRight: '7rem'}} src={number_officer}
               alt={"Engineer"}/>
          <OfficerContainerDetailStyled className="text-3xl mt-10 font-normal">
            <div style={{marginBottom: '1rem'}}>
              経理、総務 ＆ 営業
            </div>
            <BasicTabs data={officerData}/>
          </OfficerContainerDetailStyled>
          <OfficerContainerImageStyled>
            <img style={{position: 'relative', left: '-6%', top: '15%'}} src={background_officer} alt={"officer"}/>
          </OfficerContainerImageStyled>
        </OfficerContainerBtnStyled>
      </div>
    </JobsContainerStyled>
  ) : (

    //   下からモバイル
    <section>
      <h1 className="text-center font-medium text-xl text-[#243142] mt-5">仕事</h1>

      <div className="flex flex-col justify-center items-center pt-3 pb-8 px-6">
        <img src={mobileNumberOneIcon} alt={''}></img>
        <div>
          <h1 className="text-center text-[#414042] text-1.2rem font-medium my-4">エンジニアリング</h1>
          <div>
            <div className={"flex justify-evenly mb-4 HeaderNotComeDown"}>
              <img src={mobileLeftArrow} alt="" onClick={engineerPrevSlide}></img>
              <span className={"font-medium min-w-56 text-center text-[#1876D2] font-base"}>{engineerData[engineerSlide].workType}</span>
              <img src={mobileRightArrow} alt="" onClick={engineerNextSlide}></img>
            </div>
            <div className="w-[308.133px] h-[232.187px] bg-auto text-[#1876D2] font-normal pt-5 px-7 pb-20"
                 style={{background: `url(${mobileDesktopIcon}) no-repeat center center`}}>
              <p className="text-sm">{engineerData[engineerSlide].desc}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center py-8 px-6 bg-[#1876D2]">
        <img src={mobileNumberTwoIcon} alt={''}></img>
        <div>
          <h1 className="text-center text-white text-1.2rem my-4">経理、総務 ＆ 営業</h1>
          <div>
            <div className={"flex justify-evenly mb-4 HeaderNotComeDown"}>
              <img src={mobileLeftArrowWhite} alt="" onClick={officerPrevSlide}></img>
              <span className={"min-w-56 text-center font-base text-white"}>{officerData[officerSlide].workType}</span>
              <img src={mobileRightArrowWhite} alt="" onClick={officerNextSlide}></img>
            </div>
            <div className="w-[311.932px] h-[187.408px] bg-auto font-normal text-white pt-7 px-10 pb-10"
                 style={{background: `url(${mobileLaptopIcon}) no-repeat center center`}}>
              <p className="text-white text-sm">{officerData[officerSlide].desc}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Jobs;
