import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MuiTheme from "./muiTheme";
import {theme} from "../../features/util/meterialThemeSettings/MeterialThemeSetting";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      hidden={value !== index}
      {...other}
      style={{width: 'auto', padding: '2rem 0'}}
    >
      {value === index && (
        <Box sx={{p: 1}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type Data = {
  workType: string;
  desc: string;
}

interface BasicTabsProps {
  data: Data[];
}

export default function BasicTabs(props: BasicTabsProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MuiTheme theme={theme}>
      <Box className={'tabBox'}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs value={value} onChange={handleChange} className={'HeaderNotComeDown'}>
            {
              props.data.map((data, index) => {
                return <Tab label={data.workType} key={`Tab-${index}`}/>
              })
            }
          </Tabs>
        </Box>
        {
          props.data.map((data, index) => {
            return <CustomTabPanel value={value} index={index} key={`Panel-${index}`}>
              {data.desc}
            </CustomTabPanel>
          })
        }
      </Box>
    </MuiTheme>
  );
}
