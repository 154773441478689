import {ApiConstants} from "./apiConstants";

export class Constants extends ApiConstants {
  // 공백
  static WHITE_SPACE = ``

  // 타입들
  static TYPE_HOME = `Home`;
  static TYPE_INTRODUCE = `Introduce`;
  static TYPE_INFORMATION = `Information`;
  static TYPE_SERVICE = `Service`;
  static TYPE_COMMUNICATION = `Communication`;

  // 메인영상이 시작 하고 16초후에 메인 영상에 글이 쓰여짐
  static HOME_MAIN_VIDEO_TIME_FINISH_TEXT = 36
  // 메인영상이 시작 하고 36초후에 메인 영상에 글이 지워짐
  static HOME_MAIN_VIDEO_TIME_START_TEXT = 1

  // 네브 메뉴 인덱스
  static NAV_MENU_HOME = 0
  static NAV_MENU_INTRODUCE = 1
  static NAV_MENU_SERVICES = 2
  static NAV_MENU_INFORMATION = 3
  static NAV_MENU_COMMUNICATION = 4
  // 프라이버스 정책 메뉴 인덱스
  static PRIVACY_POLICY = 5
  // 제품 메뉴 인덱스
  static NAV_MENU_PRODUCTION = 6
  // 근로자 파견 사업의 마진율 공시 추가 -김한-
  static WORKER_DISPATCHING_ACT = 7

  // 서브 네브 메뉴 인덱스
  // 서브 네브 메뉴 인덱스 - INTRODUCE
  static SUB_NAV_INTRODUCE_ABOUT = 0
  static SUB_NAV_INTRODUCE_MERIT = 1
  static SUB_NAV_INTRODUCE_HISTORY = 2
  static SUB_NAV_INTRODUCE_INFO = 3

  // 서브 네브 메뉴 인덱스 - SERVICES
  static SUB_NAV_SERVICES_IT_INFRASTRUCTURE = 0
  static SUB_NAV_SERVICES_VIRTUALIZATION = 1
  static SUB_NAV_SERVICES_MSP_SERVICE = 2
  static SUB_NAV_SERVICES_SOFTWARE_DEVELOPMENT = 3
  static SUB_NAV_SERVICES_ENGINEER_OUTSOURCING = 4
  static SUB_NAV_SERVICES_IT_SYSTEM_MAINTENANCE = 5
  static SUB_NAV_SERVICES_IT_SYSTEM_SALES = 6

  // 서브 네브 메뉴 인덱스 - INFORMATION
  static SUB_NAV_INFORMATION_MAPS = 0
  static SUB_NAV_INFORMATION_MESSAGES = 1
  static SUB_NAV_INFORMATION_EMPLOYMENT = 2

  // 서브 네브 메뉴 인덱스 - COMMUNICATION
  static SUB_NAV_COMMUNICATION_CONTACT = 0

  // 서브 네브 메뉴 인덱스 - PRODUCTION
  static SUB_NAV_PRODUCTION_SKPACK = 0
  static SUB_NAV_PRODUCTION_MINERVA = 1

  // 월
  static MONTH_LITERALS: { [key: number]: string } = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  };

  // history2번째에 아이디 있는지 확인하기위한 배열
  static CHECK_HISTORY_ID_FOR_SECTION = 0

  // html
  static TITLE = `title`
  static ROOT = `root`
  static ID = `id`
  // scroll
  static SECTION_SCROLL_THRESHOLD_550 = 550
  static SECTION_SCROLL_THRESHOLD_450 = 450
  static SECTION_SCROLL_THRESHOLD_350 = 350
  static SECTION_SCROLL_THRESHOLD_150 = 150

  // reducer
  static LOCAL_STORAGE_SHARED_DATA = `sharedData`
  static SET_DATA = `SET_DATA`
  static SET_PAGE = `SET_PAGE`
  static SET_SCROLL = `SET_SCROLL`
  static SET_MOBILE_HISTORY = `SET_MOBILE_HISTORY`
  static SET_HEADER_BAR_TOUCH_TO_HISTORY = `SET_HEADER_BAR_TOUCH_TO_HISTORY`
}
