import React, {useState} from 'react';
import DownloadButtonDeco from "../../common/downloadButtonDeco";
import {useWindowResizeToTablet} from "../../../features/util/responsive/tablet";
import {useWindowResizeToMobile} from "../../../features/util/responsive/mobile";
import {DecoImage} from "../../../features/decoration/decoration";
import {DecoImages} from "../../../features/decoration/decoImages";
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";

const HomeMain = () => {
  const isTablet = useWindowResizeToTablet();
  const isMobile = useWindowResizeToMobile();
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0});
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={`${isVisible ? 'opacity-100':'opacity-0'} flex flex-col items-center transition-all ease-in-out duration-500`} ref={innerRef}>
      <div className="pb-20 lg:flex-row-reverse max-w-screen-2xl flex flex-col lg:h-full">
        <div className="lg:w-3/5 w-full flex justify-center items-center">
          <img src="/assets/home/main_image.png" alt="" className="md:max-w-[800px] w-full" onLoad={() => setIsLoading(false)}/>
        </div>
        {/* 화면 깜빡임을 방지하기 위한 것 */}
        <div className={`${isLoading ? 'block h-screen' : 'hidden'}`}/>
        <div className={`flex flex-col justify-center items-center -mt-16
        sm:-mt-40 sm:-ml-36
        lg:mt-0 lg:ml-8 lg:w-2/5 ${isLoading ? 'opacity-0':'opacity-100'}`}>
          <div className="flex justify-items-start lg:w-full lg:pl-5 lg:pb-6">
            {(!isTablet && !isMobile) && <DecoImage style={{...DecoImages.homeMainImage, position: 'static', height: '4rem'}}/>}
          </div>
          <div className="px-3">
            <h1 className="text-3xl mb-4 font-semibold px-2">
              <span className="text-green-600">NAMU TECH JAPAN</span><span className="text-gray-500">は</span>
            </h1>
            <h2 className="font-normal text-home-black px-2 py-4">
              サーバーの構築、運用、仮想化から<br/>クラウド、DevOps、ソフトウェア開発まで<br/>幅広く対応可能なワンストップソリューション企業です。
            </h2>
            <div className="flex justify-center
            sm:justify-start
            md:justify-start
            lg:justify-start
            xl:justify-start">
              <DownloadButtonDeco component="a" href={`/document/namutechjapan.pdf`}
                                  download="NAMUTECHJAPAN会社紹介資料.pdf"
                                  types={'homeMain'}>会社紹介資料</DownloadButtonDeco>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default HomeMain;
