import React from 'react';
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";
import {ServicesContentsDivineStyled} from "../../../features/styledComponents/services/servicesMainStyled";
import CommonServiceTypeA from "./serviceContent/commonServiceTypeA";
import ServicePartner from "./serviceContent/servicePartner";
import {serviceType} from "../../../features/types/service.d";

const SoftwareDevelopment = ({serviceContent}: serviceType) => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.1});
  const mainImageClassName = `
      w-11/12 h-full md:w-full`;
  const pClassName = `
     font-light mt-0 h-[3.3rem] text-white
     md:text-center md:h-[3.3rem] md:w-[37.5rem] md:mt-[4.0rem] md:mb-[1rem]`;
  const partnerImageClassName = [
    'w-4/12 h-full lg:w-[54%] lg:h-[52%]',
    'w-4/12 h-full lg:w-[54%] lg:h-[52%]',
    'w-4/12 h-9/12 lg:w-[54%] lg:h-[52%]',
    'w-4/12 h-[77%] lg:w-[54%] lg:h-[52%]',
  ]

  return (
    <>
      <section className={`service-main-mold md:-mt-4 lg:mt-16 md:min-h-[650px] bg-[#243142]`}>
        <ServicesContentsDivineStyled className="lg:mb-10" ref={innerRef} isVisible={isVisible}>
          {isVisible && <CommonServiceTypeA serviceContent={serviceContent}
                                            mainImageClassName={mainImageClassName}
                                            pClassName={pClassName}/>}
        </ServicesContentsDivineStyled>
      </section>
      <ServicePartner serviceContent={serviceContent} isOpenPTag={true} partnerImageStyle={partnerImageClassName}/>
    </>
  );
};

export default SoftwareDevelopment;
