export const offeringEssentialPoint = [
  {title: "募集者の名称", desc: "NAMU TECH JAPAN株式会社"},
  {title: "応募資格", desc: "募集ポジションにより異なる。"},
  {title: "契約期間", desc: "期間の定めなし"},
  {
    title: "試用期間",
    desc: "あり(3か月)<br />※会社の判断により、試用期間の免除、短縮または延長する場合があります。<br />※試用期間中の雇用条件の変更はありません。"
  },
  {
    title: "就業場所",
    desc: "本社(東京都文京区本郷1-20-3)、ほか各事業所<br />※採用時の勤務地は、各ポジションの求人票および内定通知書にてお伝えします。"
  },
  {
    title: "労働時間",
    desc: "標準勤務時間帯 9:00～18:00<br />※所定労働時間8時間、休憩時間1時間です。<br />※ただし一部のポジションでは、企画業務型裁量労働制または専門業務型裁量労働制の適用対象となる場合があります。<br />※一部、フレックスタイム制を適用しています。"
  },
  {title: "賞与", desc: "あり<br />※会社の業績や本人の評価により支給します。"},
  {title: "退職金", desc: "あり"},
  {title: "保険", desc: "厚生年金保険、健康保険、労災保険、雇用保険 など"},
  {title: "業務関連技術資格取得援助", desc: "業務関連資格の取得のために必要な費用を全額または一部を補助します。"},
  {title: "昇給", desc: "あり<br />※1月、7月に、会社実績や本人の評価により見直しを行います。"},
  {
    title: "休日／休暇",
    desc: "週休2日制(土日)・祝日<br />年次有給休暇・年末年始休暇・産前産後の休暇・子の看護休暇及び介護休暇<br />前各号の休暇のほか、会社が必要と認めた場合に与えられる休暇"
  },
]
