import {useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {DataContext, useData} from './navReducer';
import {mainMenuKeys, subNavMenuData} from "../../../assets/data/menuData";
import {Constants} from "../constants/appConstants";

export const LocationListener = () => {
  const {dispatch} = useContext(DataContext);
  const location = useLocation();
  const {state} = useData();
  const subPageDesc = state.sharedData;
  useEffect(() => {
    let nav = Constants.WHITE_SPACE;
    let subNav = Constants.WHITE_SPACE;
    if (location.pathname !== `/${nav}`) {
      const path = location.pathname.slice(1); // 앞의 '/' 제거
      [nav, subNav = Constants.WHITE_SPACE] = path.split('/');
    }

    // 경로가 변경될 때마다 특정 액션을 디스패치
    const navKey = mainMenuKeys[`/${nav}`]
    // 네브키 없으면 빈배열 반환 후 그냥 나간다.
    if (navKey === undefined || navKey === null) {
      dispatch && dispatch({type: Constants.SET_DATA, payload: {err: '該当する情報がありません', tagTitle: 'エラーページ | ナムテックジャパン'}});
      return
    }
    // 네브키 있으면,
    const subNavKey = subNavMenuData[navKey];
    if (subNav !== Constants.WHITE_SPACE) {
      // 서브 네브정보가 있으면 해당 정보를 로컬 스토리지에 입력 작업을 진행한다.
      const selectedSubNav = subNavKey.find(subNav => subNav.link === location.pathname)
      dispatch && dispatch({type: Constants.SET_DATA, payload: selectedSubNav ?? {err: '該当する情報がありません', tagTitle: 'エラーページ | ナムテックジャパン'}})
    } else if (navKey === Constants.PRIVACY_POLICY) {
      // 여기를 어떻게 할지 고민해서 수정하기
      dispatch && dispatch({
        type: Constants.SET_DATA,
        payload: {tagTitle: "プライバシーポリシー || ナムテックジャパン"}
      });

      // 근로자 파견 사업의 마진율 공시 추가 -김한-
    } else if (navKey === Constants.WORKER_DISPATCHING_ACT) {
      dispatch && dispatch({
        type: Constants.SET_DATA,
        payload: {tagTitle: "労働者派遣事業におけるマージン率の公開 || ナムテックジャパン"}
      });

    } else {
      // subNav 가 없을 시에는 해당네브의 0번째를 반환시킨다.
      dispatch && dispatch({type: Constants.SET_DATA, payload: subNavKey[0]});
    }

    if (subPageDesc && 'navLink' in subPageDesc && typeof subPageDesc.navLink === 'string') {
      // if (!location.pathname.includes(subPageDesc.navLink)) { 여기는 같은 부모 링크일때는 여기를 거치지 않는다.
      dispatch && dispatch({
        type: Constants.SET_PAGE,
        page: true,
      })
      // }
      dispatch && dispatch({
        type: Constants.SET_MOBILE_HISTORY, historyHeaderForMobile: false
      })
    }
  }, [location.pathname]);
  return null;
};
