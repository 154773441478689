import React from 'react';
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";
import {ServicesContentsDivineStyled} from "../../../features/styledComponents/services/servicesMainStyled";
import CommonServiceTypeA from "./serviceContent/commonServiceTypeA";
import ServicePartner from "./serviceContent/servicePartner";
import {serviceType} from "../../../features/types/service.d";

const MspService = ({serviceContent}: serviceType) => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.1});
  const mainImageClassName = `
      w-[87%] h-[87%]`;
  const pClassName = `
      font-normal mt-0 h-[5rem] text-[#243242]
      md:text-center md:h-[7rem] md:w-[30rem] md:mt-[1.0rem] md:mb-[1rem]`;
  const partnerImageClassName = [
    'w-8/12 h-[63%] lg:w-2/3 lg:h-2/3',
    'w-full h-full lg:w-full lg:h-full',
    'w-8/12 h-full lg:w-[95%] lg:h-[95%]',
  ]

  return (
    <>
      <section className={`service-main-mold md:-mt-4 lg:mt-16 md:min-h-[650px] bg-[#FFB66E]`}>
        <ServicesContentsDivineStyled className="-my-2 lg:my-5" ref={innerRef} isVisible={isVisible}>
          {isVisible && <CommonServiceTypeA serviceContent={serviceContent}
                                            mainImageClassName={mainImageClassName}
                                            pClassName={pClassName}/>}
        </ServicesContentsDivineStyled>
      </section>
      <ServicePartner serviceContent={serviceContent} isOpenPTag={false} partnerImageStyle={partnerImageClassName}/>
    </>
  );
};

export default MspService;
