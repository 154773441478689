import React from 'react';
import {useData} from '../../../features/reducer/navReducer'
import {Constants} from "../../../features/constants/appConstants";
import {useWindowResizeToMobile} from "../../../features/util/responsive/mobile";
import {useWindowResizeToTablet} from "../../../features/util/responsive/tablet";

const BannerStyle = (props: any) => {
  let resultPage = null;
  const {state} = useData();
  const subPageDesc = state.sharedData;
  const isMobile = useWindowResizeToMobile();
  const isTablet = useWindowResizeToTablet();

  if (Object.keys(subPageDesc).length > 1) {
    if (subPageDesc && ('navMenuId' in subPageDesc) && ('open' in subPageDesc) && ('subNavMenuId' in subPageDesc)) {
      if (!(subPageDesc.navMenuId === Constants.NAV_MENU_HOME ||
          (subPageDesc.navMenuId === Constants.NAV_MENU_INFORMATION && subPageDesc.open))) {
        let isService = subPageDesc.navMenuId === Constants.NAV_MENU_SERVICES
        let imgStyle = isMobile? props.bannerStyleData?.styleMobile:isTablet? props.bannerStyleData?.styleTablet:props.bannerStyleData?.style
        let titleStyle= isMobile? props.bannerStyleData?.mobileTitleStyle:props.bannerStyleData?.titleStyle
        resultPage = (
            <div className={`flex justify-center overflow-hidden md:w-full md:h-[23rem] lg:h-[30rem]
                                            ${isService? "h-[18rem]":"h-[16rem]"}`}>
              <div className={`${isService? "flex flex-col items-center justify-center mt-6 md:flex-row-reverse lg:max-w-9xl"
                                              :"max-w-sm w-3/4 lg:max-w-4xl"}`}>
                <div className={`${isService? "flex lg:w-1/2 max-h-52":""}`}>
                  <img style={imgStyle} src={props.bannerStyleData?.imageUrl} />
                </div>
                <h1 className={`text-2xl font-semibold md:text-2xl md:top-30 md:text-left lg:text-4xl
                                  ${isService ? "none lg:w-90":"absolute top-44"} `}
                    style={titleStyle}>{props.content?.title}</h1>
              </div>
            </div>
        )
      }
    }
  }

  return resultPage;
};

export default BannerStyle;
