import styled from "@emotion/styled";
import {etcKeyType} from "../../../types/etcKeyType";
import mobileSelectionProcess from "../../../../../assets/img/selectionProcess/mobile_recruit_processes_image.svg";

interface SelectionProcessIndexStyledProps {
  processIndex: number;
}

export const SelectionProcessContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .mobileSelectionProcess {
    position: relative;
    background: url(${mobileSelectionProcess});
    width: 282.74px;
    height: 527.87px;
    color: #367CDE;
    font-weight: 700;
    font-size: 0.875rem;

    span {
      position: absolute;
      text-align: center;
    }
  }
`

export const SelectionProcessSquareStyled = styled.div<SelectionProcessIndexStyledProps>`
  width: 25%;
  padding-top: 25%; /* 너비와 동일한 비율 */
  position: relative;
  background-color: ${(props) => {
    const colorValue: etcKeyType = {
      '0': '#2E4E3D',
      '1': '#F3E3A1',
      '2': '#F8D3D6',
      '3': '#68BFC8',
    }
    return props.processIndex !== undefined ?
        colorValue[props.processIndex.toString()] : undefined;
  }};
`
export const SelectionProcessSquareContentStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SelectionProcessContentsCircleBox = styled.div<SelectionProcessIndexStyledProps>`
  width: 11rem;
  height: 11rem;
  border-radius: 100%;
  border: ${(props) => {
    const borderValue: etcKeyType = {
      '0': '4px dotted white',
      '1': '4px dashed #F0C248',
      '2': '4px dashed #EE838C',
      '3': '4px solid #06467E',
    }
    return props.processIndex !== undefined ?
        borderValue[props.processIndex.toString()] : undefined;
  }};
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 9rem;
    height: 9rem;
    border-radius: 100%;
    background-color: ${(props) => {
      const backgroundValue: etcKeyType = {
        '0': '#CD8052',
        '1': '#C28DC3',
        '2': 'white',
        '3': 'white',
      }
      return props.processIndex !== undefined ?
          backgroundValue[props.processIndex.toString()] : undefined;
    }};
    display: flex;
    align-items: center;
    justify-content: center;

    h4 {
      font-weight: bold;
      color: ${(props) => {
        const backgroundValue: etcKeyType = {
          '0': 'white',
          '1': 'white',
          '2': 'black',
          '3': 'black',
        }
        return props.processIndex !== undefined ?
            backgroundValue[props.processIndex.toString()] : undefined;
      }};
    }
  }
`
