import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

type InputFileMoldProps = {
  contents?: string
  file?: File | null
  handleRemove?: () => void
}
const InputFileMold = ({contents, file}: InputFileMoldProps) => {
  return (
    <div className={`flex bg-white w-full border-[1.5px] border-[#1876D2] h-[2.3625rem] items-center mb-3 rounded-[0.6rem] ${contents !== 'ポジションを選択して下さい' ? 'hover:bg-[#DFEAF4]':''} transition duration-300 `}>
      <div className={`flex w-12 justify-center p-[0.4rem]`}>
        <AddCircleIcon color="primary"/>
      </div>
      <p className={`text-xs text-[#051B4F] font-medium`}>
        {file ? file.name : contents}
      </p>
    </div>
  )
};

export default InputFileMold;
