import React from 'react';
import {subNavMenuData} from "../../assets/data/menuData";
import BodyGroup from "../components/common/bodyGroup/bodyGroup";
import {Constants} from "../features/constants/appConstants";
import {Tab} from "../features/types/globals.d";
import About from "../components/body/introduce/about/About";
import ChosenReason from "../components/body/introduce/chosenReason/chosenReason";
import Overview from "../components/body/introduce/overview";
import History from "../components/body/introduce/history/history";

const Introduce = () => {
  const tabs: Tab[] = [
    {key: Constants.SUB_NAV_INTRODUCE_ABOUT, content: <About/>},
    {key: Constants.SUB_NAV_INTRODUCE_MERIT, content: <ChosenReason/>},
    {key: Constants.SUB_NAV_INTRODUCE_HISTORY, content: <History/>},
    {key: Constants.SUB_NAV_INTRODUCE_INFO, content: <Overview/>},
  ]
  return (
    <BodyGroup
      data={subNavMenuData[Constants.NAV_MENU_INTRODUCE]}
      tabs={tabs}
      type={Constants.NAV_MENU_INTRODUCE}
    />
  );
};

export default Introduce;
