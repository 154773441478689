import {createTheme, Theme} from "@mui/material";
import mPlus1Black from '../../../../assets/fonts/M_PLUS_1/MPLUS1-Black.ttf';
import mPlus1Bold from '../../../../assets/fonts/M_PLUS_1/MPLUS1-Bold.ttf';
import mPlus1ExtraBold from '../../../../assets/fonts/M_PLUS_1/MPLUS1-ExtraBold.ttf';
import mPlus1ExtraLight from '../../../../assets/fonts/M_PLUS_1/MPLUS1-ExtraLight.ttf';
import mPlus1Light from '../../../../assets/fonts/M_PLUS_1/MPLUS1-Light.ttf';
import mPlus1Medium from '../../../../assets/fonts/M_PLUS_1/MPLUS1-Medium.ttf';
import mPlus1Regular from '../../../../assets/fonts/M_PLUS_1/MPLUS1-Regular.ttf';
import mPlus1SemiBold from '../../../../assets/fonts/M_PLUS_1/MPLUS1-SemiBold.ttf';
import mPlus1Thin from '../../../../assets/fonts/M_PLUS_1/MPLUS1-Thin.ttf';

export const theme: Theme = createTheme({
  typography: {
    fontFamily: [
      mPlus1Black,
      mPlus1Bold,
      mPlus1ExtraBold,
      mPlus1ExtraLight,
      mPlus1Light,
      mPlus1Medium,
      mPlus1Regular,
      mPlus1SemiBold,
      mPlus1Thin
    ].join(','),
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius: '0.6rem',
            fontSize: '0.875rem',
            height: '1.3rem',
            paddingLeft: '1.6rem',
            // paddingLeft: '6.2rem'
          },
          '& textarea': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius: '0.6rem',
            paddingLeft: '0.6rem',
            fontSize: '0.875rem',
            color: 'black',
          },
          '& fieldset': {
            border: '0.1rem solid black',
            borderRadius: '0.6rem',
          },
          "& .MuiInputLabel-root": {
            fontSize: '0.75rem',
            color: '#A6A6A6',
            paddingLeft: '0.5rem',
          },
          '& .MuiInputLabel-shrink': {
            // top: '-3.5px',
          },
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderRadius: '0.6rem',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: 'white',
          margin: '0',
          borderRadius: '0.6rem',
          fontSize: '0.875rem',
          height: '1.3rem',
          paddingLeft: '1.6rem'
        }
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          marginTop: '0.125rem',
          transition: 'opacity 0.4s',
          paddingLeft: '0.5rem',
          opacity: 0,
        },
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // width: '8rem',
          borderRadius: '0.6rem',
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: '1rem',
          height: '1rem',
          margin: '0 0.5rem 0 0.4rem',
          color: 'black',
          backgroundColor: 'white',
          border: '1.5px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.6rem'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: `2.7rem`
          // height: `4rem`
        }
      }
    }
  }
});
