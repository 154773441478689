import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {RouterProvider} from "react-router-dom";
import {router} from "./app/router/router";
import {Constants} from "./app/features/constants/appConstants";
// ReactDom.Root
const root = ReactDOM.createRoot(
  document.getElementById(Constants.ROOT) as HTMLElement
);
root.render(
  // <React.StrictMode>
  <RouterProvider router={router}/>
  // </React.StrictMode>
);
