import React, {forwardRef, useEffect, useState} from 'react';
import {NavAllPageProps} from "./navAllPage";
import {navMenuData, subNavMenuData} from "../../../../assets/data/menuData";
import {Constants} from "../../../features/constants/appConstants";
import {useNavigate} from "react-router-dom";
import {
  MobileHeaderNavContainerStyled, MobileHeaderSubNavBarStyled,
} from "../../../features/styledComponents/header/mobileHeaderNavStyled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import mobileNavHomeIcon from "../../../../assets/img/nav/mobile/mobile_nav_home_icon.svg";
import mobileNavOfficeIcon from "../../../../assets/img/nav/mobile/mobile_nav_namu_icon.svg";
import mobileNavServiceIcon from "../../../../assets/img/nav/mobile/mobile_nav_service_icon.svg";
import mobileNavProductIcon from "../../../../assets/img/nav/mobile/mobile_nav_product_icon.svg";
import mobileNavInformationIcon from "../../../../assets/img/nav/mobile/mobile_nav_information_icon.svg";
import mobileNavContactIcon from "../../../../assets/img/nav/mobile/mobile_nav_contact_icon.svg";
import {useData} from "../../../features/reducer/navReducer";
import {NavMenuData, SubNavMenuData} from "../../../features/types/globals.d";

const NavMobileAllPage = forwardRef<HTMLDivElement, NavAllPageProps>(({isOpenAllNavPage, openClosePage,}, ref) => {
  const height: { [key: string]: number } = {};
  const {state} = useData();
  const subPageDesc = state.sharedData as SubNavMenuData | NavMenuData | null;
  const [selectedNavIndex, setSelectedNavIndex] = useState<number | null>(null);
  // ref 랑 효율 비교하기
  const olElement = document.querySelectorAll('.nav_mobile_subNavBar');
  olElement.forEach((element, index) => {
    const liElements = element.querySelectorAll('li');
    let totalHeight = 0;
    liElements.forEach(li => {
      totalHeight += (li.offsetHeight + 7);
    });
    // rem 으로 계산 하기
    height[index] = totalHeight
  });
  let navigate = useNavigate();
  const mobileMenuIcon: string[] = [
    mobileNavHomeIcon,
    mobileNavOfficeIcon,
    mobileNavServiceIcon,
    mobileNavProductIcon,
    mobileNavInformationIcon,
    mobileNavContactIcon
  ]
  useEffect(() => {
    setSelectedNavIndex(subPageDesc?.navMenuId ?? null);
  }, [subPageDesc]);

  function clickMobileNav(navIndex: number, path: string) {
    if (navIndex === Constants.NAV_MENU_HOME || navIndex === Constants.NAV_MENU_COMMUNICATION) {
      goToLink(path)
      openClosePage()
      setSelectedNavIndex(navIndex)
      return;
    }
    if (navIndex === selectedNavIndex) {
      setSelectedNavIndex(null)
      return;
    } else {
      setSelectedNavIndex(navIndex)
      return;
    }
  }

  const goToLink = (path: string) => {
    if (path.includes('skpack')) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  return (
    <nav className={`w-full overflow-y-auto ${isOpenAllNavPage ? `opacity-100 z-[100] pointer-events-auto`:'opacity-0 z-[-6] pointer-events-none'} shadow-md transition-all duration-100 delay-0 ease-in`} ref={ref}>
      <ol>
        {
          navMenuData.map((navData, navIndex) => {
            return (
              <React.Fragment key={navData.title}>
                <li
                  className={`${navData.navMenuId} w-full h-12 align-middle flex items-center justify-between text-sm font-bold px-8 cursor-pointer bg-[#F1F3F7] border-b border-[#FFFFFF]`}
                  onClick={() => clickMobileNav(navData.navMenuId, navData.link)}>
                  <div className={`flex flex-row justify-center`}>
                    <img className={`mr-[1.2rem]`} src={mobileMenuIcon[navIndex]} alt={''}/>
                    <h5 className={(subPageDesc?.navMenuId === navData.navMenuId ? 'text-[#E454D6] font-medium':'text-[#7C4DFF] font-medium') }>{navData.title}</h5>
                  </div>
                  {
                    (navData.navMenuId === Constants.NAV_MENU_HOME ||
                      navData.navMenuId === Constants.NAV_MENU_COMMUNICATION) ||
                    <ExpandMoreIcon fontSize="small"
                                    style={{
                                      transform: selectedNavIndex === navData.navMenuId ? 'rotate(180deg)' : 'rotate(0deg)',
                                      color: selectedNavIndex === navData.navMenuId ? '#E454D6' : '#7C4DFF',
                                      transition: 'transform 0.3s ease, color 0.3s ease',
                                    }}/>
                  }
                </li>
                <menu id={'NavMenu-Mobile'} className={`bg-white`}>
                  <MobileHeaderSubNavBarStyled
                    className={"nav_mobile_subNavBar"} //이 클래스는 높이를 구하기 위해서 있는 클래스 이룸이다.
                    subNavBarHeight={height[navIndex]}
                    isSelectedNavIndex={selectedNavIndex === navData.navMenuId}>
                    {
                      subNavMenuData[navData.navMenuId].map((navSubData, _) => {
                        return (
                          (navData.navMenuId === Constants.NAV_MENU_HOME ||
                            navData.navMenuId === Constants.NAV_MENU_COMMUNICATION) ||
                          <li
                            className={`${
                              (
                                (subPageDesc?.navMenuId === navSubData.navMenuId) &&
                                (('subNavMenuId' in subPageDesc && subPageDesc.subNavMenuId) === navSubData.subNavMenuId)
                              ) ? 'text-[#E454D6]':'text-[#637492]'} bg-white p-[0.5rem] my-[0.1rem] mx-[4rem] font-normal cursor-pointer text-sm`
                            }
                            key={navSubData.title} onClick={() => {
                            openClosePage();
                            goToLink(navSubData.link);
                          }}>
                            {navSubData.title}
                          </li>
                        )
                      })
                    }
                  </MobileHeaderSubNavBarStyled>
                </menu>
              </React.Fragment>
            )
          })
        }
      </ol>
    </nav>
  );
});

export default NavMobileAllPage;
