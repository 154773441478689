import styled from "@emotion/styled";

export const IsVisibleBoxArticleStyled = styled.article<{isVisible: boolean}>`
  opacity: ${props => props.isVisible ? '1' : '0'};
  transform: ${props => props.isVisible ? 'translate(0%,0%)' : 'translate(0%,1.5%)'};
  transition: opacity 1s ease, transform 1s ease-in;
`
export const IsVisibleBoxSectionStyled = styled.section<{isVisible: boolean}>`
  opacity: ${props => props.isVisible ? '1' : '0'};
  transform: ${props => props.isVisible ? 'translate(0%,0%)' : 'translate(0%,1.5%)'};
  transition: opacity 0.5s ease, transform 0.5s ease-in;
`
export const IsVisibleBoxDivStyled = styled.div<{isVisible?: boolean}>`
  opacity: ${props => props.isVisible ? '1' : '0'};
  transform: ${props => props.isVisible ? 'translate(0%,0%)' : 'translate(0%,.1.5%)'};
  transition: opacity 1s ease, transform 1s ease-in;
`
