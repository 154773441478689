import React, {useEffect, useRef, useState} from 'react';
import {useData} from "../../../features/reducer/navReducer";
import {NavMenuData, SubNavMenuData} from "../../../features/types/globals.d";
import {throttle} from "lodash";
import rightArrow from "../../../../assets/img/topicBtn/right_arrow_icon.svg"
import leftArrow from "../../../../assets/img/topicBtn/left_arrow_icon.svg"
import {Constants} from "../../../features/constants/appConstants";
import {ArrowButton, MenuMoveBar, TopicBtnContainer} from "../../../features/styledComponents/common/topicTab";

const Tabs = (props: any) => {
  const {state} = useData(); // 전체 상태
  const subPageDesc: { subNavMenu?: SubNavMenuData | NavMenuData | null } = state.sharedData;
  const ButtonsContainer = props.btnContainer; // 버튼 컨테이너
  const [transitionEnabled, setTransitionEnabled] = useState(false); // 리사이즈 할 때에는 트랜지션 기능을 제어한다.
  const buttonMold = useRef<HTMLElement | null>(null) // 버튼들의 틀
  const moveBar = useRef<HTMLDivElement | null>(null) // 버튼 클릭시 버튼을 표시하는 이동식 바
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(true); // 왼쪽 화살표 보일지 말지 (스크롤 되고 난후에)
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(true); // 오른쪽 화살표 보일지 말지 (스크롤 되고 난후에)
  const [isArrowVisible,] = useState(props.type === Constants.NAV_MENU_SERVICES ? true : false); // arrow 버튼은 서비스일 때 보이게끔한다.
  const [isClicked, setIsClicked] = useState(false); // 스크롤 이동시 함수가 여러번 클릭되는 것을 막는다.
  const scrollBarLocationXScroll = {
    [Constants.SUB_NAV_SERVICES_IT_SYSTEM_SALES]: 9000,
    [Constants.SUB_NAV_SERVICES_IT_SYSTEM_MAINTENANCE]: 9000,
    [Constants.SUB_NAV_SERVICES_IT_INFRASTRUCTURE]: 0,
    [Constants.SUB_NAV_SERVICES_VIRTUALIZATION]: 0,
    // [Constants.SUB_NAV_SERVICES_SOFTWARE_DEVELOPMENT]: 700,
    [Constants.SUB_NAV_SERVICES_ENGINEER_OUTSOURCING]: 9000,
    [Constants.SUB_NAV_SERVICES_MSP_SERVICE]: 0,
  }

  useEffect(() => {
    const prepareMoveBarPosition = (transition: boolean = true) => {
      let buttons = buttonMold.current && buttonMold.current.querySelectorAll('.topic-button')
      setTransitionEnabled(true); // resize 되고 난후 다시 버튼 클릭시 반응하게 하는 함수
      // 초기 버튼 바 위치 설정 및 이동
      if (subPageDesc && ('subNavMenuId' in subPageDesc) && (typeof subPageDesc.subNavMenuId === 'number')) {
        const firstButton = buttons && buttons[subPageDesc.subNavMenuId] as HTMLElement;
        moveBar.current && firstButton && (moveBar.current.style.left = `${firstButton.offsetLeft}px`);
        moveBar.current && firstButton && (moveBar.current.style.top = `${(firstButton.offsetTop + firstButton.offsetHeight + 4)}px`);
        moveBar.current && firstButton && (moveBar.current.style.width = `${firstButton.offsetWidth}px`);
        moveBar.current && firstButton && (moveBar.current.style.transition = (transitionEnabled && transition) ? '0.3s' : 'none');
        if (buttonMold.current && isArrowVisible) {
          if (subPageDesc.subNavMenuId === Constants.SUB_NAV_SERVICES_SOFTWARE_DEVELOPMENT) {
            if (buttonMold.current?.scrollLeft === 0) {
              buttonMold.current.scrollLeft = 0
            } else {
              buttonMold.current.scrollLeft = 9000
            }
          } else {
            buttonMold.current.scrollLeft = scrollBarLocationXScroll[subPageDesc.subNavMenuId];
          }
        }
      }
    }
    if (props.isLoaded) {
      prepareMoveBarPosition()
    }

    // resize 될 때 마다 트랜직션 없애기
    const handleResize = throttle(() => prepareMoveBarPosition(false), 200);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.isLoaded, transitionEnabled, subPageDesc]);


  useEffect(() => {
    let maxScrollLeft: number = 0
    if (buttonMold.current) {
      maxScrollLeft = buttonMold.current.scrollWidth - buttonMold.current.clientWidth;
    }
    const handleScroll = () => {
      if (buttonMold.current?.scrollLeft === 0) {
        setIsLeftArrowVisible(false);
      } else {
        setIsLeftArrowVisible(true);
      }
      if ((buttonMold.current?.scrollLeft ?? maxScrollLeft) >= maxScrollLeft - 1) {
        setIsRightArrowVisible(false);
      } else {
        setIsRightArrowVisible(true);
      }
    };
    // 초기 마운트 되었을 떄 실행시킨다.
    handleScroll();
    const element = buttonMold.current;
    element?.addEventListener('scroll', handleScroll);

    // 클린업 함수에서 이벤트 리스너 제거
    return () => {
      element?.removeEventListener('scroll', handleScroll);
    };
  }, [buttonMold.current?.scrollLeft]);

  // topic 스크롤을 이동시켜줍니다. -는 왼쪽 +는 오른쪽
  const moveScroll = (direction: number) => {
    if (!isClicked) {
      if (buttonMold.current) {
        buttonMold.current?.scrollBy({
          left: direction, // -는 왼쪽 +는 오른쪽
          behavior: 'smooth'
        });
      }
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 1000);
    }
  };

  return (
    // 버튼이 준비가 되면 보여지게
    <div className={`flex justify-center ${props.isLoaded ? 'opacity-100' : 'opacity-0'}`}>
      <ArrowButton isVisible={isLeftArrowVisible}
                   onClick={() => moveScroll(-800)} aria-label="Scroll Left Move" id={'page-scroll-left'}
                   disabled={!isArrowVisible}>
        <img src={leftArrow} className={`w-[0.8rem] h-4`} alt={''}/>
      </ArrowButton>
      <ButtonsContainer className={props.className} id={'buttonMold'} ref={buttonMold} type={props.type}>
        <MenuMoveBar id={'moveBar'} ref={moveBar}/>
        {props.buttons}
      </ButtonsContainer>
      <ArrowButton isVisible={isRightArrowVisible}
                   onClick={() => moveScroll(800)} aria-label="Scroll Right Move" id={'page-scroll-right'}
                   disabled={!isArrowVisible}>
        <img src={rightArrow} className={`w-[0.8rem] h-4`} alt={''}/>
      </ArrowButton>
      {props.children}
    </div>
  );
};
export default Tabs;
