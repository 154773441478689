import React from 'react';
import blueQuotes from "../../../../../assets/img/messages/mobileBlueQuotes.svg";
import trianglePattern from "../../../../../assets/img/messages/trianglePattern.svg";

const VisionMessage = () => {
  return (
    <div className="bg-[#F7F7F7] w-full">
      <div className="px-6 pb-16 pt-8 flex justify-center relative
      lg:flex-col lg:items-center lg:gap-12 lg:py-24">
        {/* web background image */}
        {/*<DecoImage className="hidden opacity-5 lg:block" style={DecoImages.bTriangle}/>*/}
        {/* web title */}
        <h1 className="hidden lg:block text-2xl font-bold">ビジョン</h1>
        <div
          className="bg-white rounded-2xl py-7 px-3 flex flex-col items-center shadow-xl text-[#2F67A7] text-[1.1rem] font-semibold w-[40rem] relative gap-5
          sm:text-2xl sm:px-8 sm:py-12
          lg:w-[55rem] lg:pt-10 lg:pb-16 lg:text-[28px] lg:leading-relaxed lg:gap-0">
          {/* mobile title */}
          <h2 className="lg:hidden">ビジョン</h2>
          {/* mobile quotes image */}
          <img className="lg:hidden" src={blueQuotes} alt=""/>
          {/* web quotes image */}
          <div className="hidden lg:flex lg:justify-center lg:items-center lg:block lg:w-full lg:relative"><img className="w-14 h-14 mt-3 mb-8" src={blueQuotes} alt=""/></div>
          <div>
            <p className="text-center mb-4">
              「<span className="text-[#FA581D]">未来価値</span>」を追求し、<br/>
              「<span className="text-[#FA581D]">高度先端技術</span>」の導入に先駆け、<br/>
              世界での「<span className="text-[#FA581D]">DevOps</span>」<br className="lg:hidden"/>
              トップ企業を目指します。
            </p>
          </div>
          {/* web triangle image */}
          <div className="hidden lg:block lg:w-full relative"><img className="absolute w-32 h-32 -right-12 -bottom-20" src={trianglePattern} alt=""/></div>
        </div>
      </div>
    </div>
  )
};

export default VisionMessage;
