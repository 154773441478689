// 텍스트와 색상을 받아서 동적으로 원을 만드는 함수
import {DecoImage} from "./decoration";
import whiteSpaceForMobile from "../../../assets/img/history/history_white.svg";
import React from "react";

export function historyKoreaOrJapanDivine() {
  return (
    <div className={`flex justify-center`}>
      <div className={`flex-1 flex justify-center pl-12`}>
        {CircleWithDynamicText('NAMU TECH<br>韓国本社', '#48A2FA')}
      </div>
      <div className={`flex-1 flex justify-center pr-12`}>
        {CircleWithDynamicText('NAMU TECH<br>JAPAN', '#8969F5')}
      </div>
    </div>
  )
}
function CircleWithDynamicText(text: string, strokeColor: string) {
  const size = 180; // 원의 전체 크기
  const strokeWidth = 7; // 테두리 두께
  const radius = (size - strokeWidth) / 2; // 실제 원의 반지름
  const center = size / 2; // 원의 중심 위치

  return (
    <svg width={size} height={size}>
      <circle
        cx={center}
        cy={center}
        r={radius}
        stroke={strokeColor} // 테두리 색상
        strokeWidth={strokeWidth}
        fill="transparent" // 원 내부는 채우지 않음
      />
      <foreignObject x="0" y="0" width={size} height={size}>
        <div style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontWeight: "bold"
        }}>
          <div dangerouslySetInnerHTML={{__html: text}}/>
        </div>
      </foreignObject>
    </svg>
  );
}

export function FramingHistoryMobile() {
  return (
    <>
      <DecoImage style={{
        backgroundImage: `url(${whiteSpaceForMobile})`,
        height: '43.60px',
        zIndex: 30,
        width: '64.69px',
        left: '-1.2rem',
      }}/>
      <DecoImage style={{
        backgroundImage: `url(${whiteSpaceForMobile})`,
        height: '43.60px',
        zIndex: 30,
        width: '64.69px',
        right: '-1.2rem',
        transform: 'rotate(180deg)'
      }}/>
    </>
  )
}
