import styled from "@emotion/styled";
import fullBackgroundImageAllPageNavMenu from "../../../../assets/img/nav/navigation_full_background.svg";

interface HeaderSubNavTitleStyledProps {
  isOpenAllNavPage: boolean
  navBarHeight?: number
}

interface MobileHeaderSubNavBarAndIconStyledProps {
  isSelectedNavIndex: boolean;
  subNavBarHeight?: number;
}


export const AllPageNavMenuStyled = styled.menu<HeaderSubNavTitleStyledProps>`
  height: ${(props) => props.isOpenAllNavPage ? `75vh` : '0'};
  background-color: #FFFFFF;
  background-image: url(${fullBackgroundImageAllPageNavMenu});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  box-shadow: 0rem 0.3rem 0.6rem -0.3rem rgba(0, 0, 0, 0.4);

`
export const MobileHeaderNavContainerStyled = styled.nav<HeaderSubNavTitleStyledProps>`
  width: 100%;
  overflow-y: auto;
  opacity: ${props => props.isOpenAllNavPage ? '1' : '0'};
  z-index: ${props => props.isOpenAllNavPage ? '99' : '-6'};
  box-shadow: 0rem 0.3rem 0.6rem -0.3rem rgba(0, 0, 0, 0.4);
  transition: opacity 0.1s ease;
`

export const MobileHeaderSubNavBarStyled = styled.ol<MobileHeaderSubNavBarAndIconStyledProps>`
  overflow: hidden;
  height: ${(props) => props.isSelectedNavIndex ? `${props.subNavBarHeight}px` : '0'};
  transition: height 0.3s ease;
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: center;
  background-color: white;
`
