import React from 'react';
import {
  OverviewDivineLifeStyleStyled,
  OverviewSectionStyled
} from "../../../../features/styledComponents/introduce/chosenReason";
import OverviewDivineLifeStyleStyledIcon from "../../../../../assets/img/peopleLoveUs/people_love_us_icon2.svg";
import companyPeople1 from "../../../../../assets/img/peopleLoveUs/people_love_us_image1.png";
import companyPeople2 from "../../../../../assets/img/peopleLoveUs/people_love_us_image2.png";
import companyPeople3 from "../../../../../assets/img/peopleLoveUs/people_love_us_image3.png";
import companyPeople4 from "../../../../../assets/img/peopleLoveUs/people_love_us_image4.png";
import companyPeople5 from "../../../../../assets/img/peopleLoveUs/people_love_us_image5.png";
import companyPeople6 from "../../../../../assets/img/peopleLoveUs/people_love_us_image6.png";
import {useWindowResizeToTablet} from "../../../../features/util/responsive/tablet";
import {useWindowResizeToMobile} from "../../../../features/util/responsive/mobile";

const LifeStyle = () => {
  const images = [
    companyPeople1, companyPeople2, companyPeople3, companyPeople4, companyPeople5, companyPeople6,
  ]
  const isTablet = useWindowResizeToTablet();
  const isMobile = useWindowResizeToMobile();
  return (
    <div className="bg-white flex justify-center">
      <div className="py-11 lg:py-20 px-6 w-full
      md:max-w-2xl
      lg:max-w-full lg:flex lg:gap-4 lg:justify-center
      xl:gap-20
      ">
        <div className="flex flex-col gap-3 items-center
        lg:max-w-[30.5rem] lg:w-full lg:items-start lg:gap-5
        ">
          <div className="w-full flex justify-center lg:pr-10">
            <img className="max-w-lg w-full lg:max-w-sm lg:-ml-40 lg:mt-10" src={OverviewDivineLifeStyleStyledIcon} alt={''}/>
          </div>
          <p className="text-1.2rem text-[#243142] font-bold text-center
          lg:text-2xl lg:text-left
          ">ビジネスの成功は<br/>私たちのライフスタイル</p>
          <p className="text-sm text-[#838383] font-medium text-center max-w-[31rem] mb-5 leading-relaxed
          md:max-w-[31rem]
          lg:text-base lg:text-left
          ">最上の成果を出す人材が集まったNAMU TECH JAPANは、
            ICT基盤の最高の技術と差別化された
            クラウドソリューションを提供することで、
            お客様のイノベーションに貢献します。
          </p>
        </div>
        <div className="hidden z-10
        lg:grid lg:grid-cols-3 lg:w-full lg:max-w-[30.5rem]
        ">
          {
            images.map((img, index) => (
              <img className={`w-[10rem] h-[15rem] p-1 ${(index === 1 || index === 4) && "mt-5"}`} src={img} key={`imgKey-${index}`} alt={''} loading={"lazy"}/>
            ))
          }
        </div>
      </div>
    </div>
    // <OverviewSectionStyled style={{backgroundColor: 'white', margin: (isTablet && isMobile) ? '4rem 0' : '4rem 0'}}>
    //   <OverviewDivineLifeStyleStyled style={{}}>
    //     <div style={{display: 'flex', justifyContent: 'center', }}>
    //       <div style={{width: isMobile ?  '100%' : '30rem', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
    //         <img src={OverviewDivineLifeStyleStyledIcon} alt={''}/>
    //         <h3 dangerouslySetInnerHTML={{__html: 'ビジネスの成功は<br/>私たちのライフスタイル'}}></h3>
    //         <p>最上の成果を出す人材が集まったNAMU TECH JAPANは、{!isMobile && <br/>}
    //           ICT基盤の最高の技術と差別化された{!isMobile && <br/>}
    //           クラウドソリューションを提供することで、{!isMobile && <br/>}
    //           お客様のイノベーションに貢献します。</p>
    //       </div>
    //     </div>
    //   </OverviewDivineLifeStyleStyled>
    //   {(!isTablet && !isMobile) &&
    //     <OverviewDivineLifeStyleStyled>
    //     <div style={{display: 'flex', justifyContent: 'center', position: 'relative'}}>
    //         <div style={{width: '30rem', height: '32rem', display: "flex", flexWrap: "wrap",}}>
    //           {
    //             images.map((img, imgIndex) => (
    //               <img src={img} key={`imgKey-${imgIndex}`} alt={''} style={{
    //                 width: "10rem", height: "15rem", padding: '0.3rem',
    //                 marginTop: (imgIndex === 1 || imgIndex === 4) ? '1.3rem' : '0'
    //               }} loading={"lazy"}/>
    //             ))
    //           }
    //         </div>
    //       </div>
    //     </OverviewDivineLifeStyleStyled>}
    // </OverviewSectionStyled>
  );
};

export default LifeStyle;
