import React, {Suspense, useEffect, useState} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loading from "../../common/loading";
import {callData} from "../../../features/util/data/callData";
import {Constants} from "../../../features/constants/appConstants";
import {PartnerData} from "../../../features/types/globals.d";
import {DecoImage} from "../../../features/decoration/decoration";
import {DecoImages} from "../../../features/decoration/decoImages";
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";
import {useWindowResizeToMobile} from "../../../features/util/responsive/mobile";

const Partner = () => {
  const [data, setData] = useState<PartnerData[] | []>([]);
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.5});
  const isMobile = useWindowResizeToMobile();
  const imageResize = () => isMobile ? {width: '5rem', height: '4rem', top: '-10%'} : {}

  // 데이터 불러오기 작업
  useEffect(() => {
    const fetchData = async () => {
      const url = Constants.PARTNER_DATA_URL;
      const topicData = await callData(url);
      setData(topicData);
    };
    fetchData().then();
  }, []);

  return (
	  <article className="pt-10 relative pb-8 md:mb-0 mb-8" ref={innerRef}>
		  <DecoImage style={{...DecoImages.partnerImage1, ...imageResize()}}/>

		  <div className="justify-items-center pt:5 sm:pt-0 md:pt-5 lg:pt-10 xl:pt-10">
			  <div className="text-center py-6 sm:py-6 md:py-16 lg:py-16 xl:py-16"
			       style={{background: 'linear-gradient(to bottom, #F7F7F7, #FFFFFF)'}}>
				  <h1 className="text-[#243142] font-semibold text-lg
          lg:text-3xl">メインパートナー</h1>
				  <p className="text-[#637492] pt-4 px-4">全世界の企業と共に成長を続けております。</p>
			  </div>
		  </div>
		  {
			  data.length > 0 ?
				  <div className={`grid grid-cols-3 pt-3 px-16 gap-y-3 gap-x-10 justify-items-center max-w-screen-2xl mx-auto bg-white
          sm:gap-x-0
          md:grid-cols-4 md:content-center md:gap-y-10 transition duration-1000 ease-in delay-0 ${isVisible ? 'opacity-100 translate-y-0 translate-x-0' : 'opacity-0 translate-y-[-3%]'}`}>
					  {
						  isVisible &&
						  data.map((img, index) => (
							  <div key={index} className="w-[90px] h-[50px] flex justify-center
                sm:w-[90px] sm:h-[50px]
                md:w-[110px] md:h-[70px]
                lg:w-[110px] lg:h-[70px]
                xl:w-[110px] xl:h-[70px]">
								  <img src={img.src} alt={img.name} className="h-full object-contain"/>
							  </div>
						  ))
					  }
				  </div>
				  :
				  <Loading/>
		  }
	  </article>
  );
};

export default Partner;
