import {Tab} from "../features/types/globals.d";
import {Constants} from "../features/constants/appConstants";
import BodyGroup from "../components/common/bodyGroup/bodyGroup";
import {subNavMenuData} from "../../assets/data/menuData";
import React from "react";
import Minerva from "../components/body/production/minerva/minerva";

const Production = () => {
  const tabs: Tab[] = [
    {key: Constants.SUB_NAV_PRODUCTION_MINERVA, content: <Minerva/>},
  ]
  return (
    <BodyGroup
      data={subNavMenuData[Constants.NAV_MENU_PRODUCTION]}
      tabs={tabs}
      type={Constants.NAV_MENU_PRODUCTION}
    />
  );
}

export default Production;
