import React from 'react';
import {Constants} from "../../../../features/constants/appConstants";

type SubNavItemsImagesProps = {
  src?: string,
  navMenuIdKey: number,
  navMenuId: number,
  subNavItemIndex: number,
  subNavId: number,
  showHeaderSubNavBar: boolean
}

const SubNavItemsImage = React.memo((
  {
    src,
    navMenuIdKey,
    navMenuId,
    subNavItemIndex,
    subNavId,
    showHeaderSubNavBar
  }: SubNavItemsImagesProps) => {
  return (
    <img src={src}
         className={`${setImageResize[navMenuIdKey][subNavId]}
           ${(navMenuIdKey === Constants.NAV_MENU_INTRODUCE || navMenuIdKey === Constants.NAV_MENU_INFORMATION) ? 'object-fill' : 'object-cover'}
           ${((navMenuId === navMenuIdKey) && (subNavItemIndex === subNavId) && showHeaderSubNavBar) ? 'opacity-100' : 'opacity-0'}
           absolute w-full `}
         alt={''}/>
  );
}, (prevProps, nextProps) => {
  if (prevProps.subNavId !== nextProps.subNavId) {
    return false
  } else if (prevProps.navMenuId !== nextProps.navMenuId) {
    return false
  }

  return true
});

export default SubNavItemsImage;
const setImageResize = {
  [Constants.NAV_MENU_INTRODUCE]: {
    [Constants.SUB_NAV_INTRODUCE_ABOUT]: 'w-[17rem]',
    [Constants.SUB_NAV_INTRODUCE_MERIT]: 'w-60 h-60',
    [Constants.SUB_NAV_INTRODUCE_HISTORY]: 'w-[16rem]',
    [Constants.SUB_NAV_INTRODUCE_INFO]: 'w-[16rem]',
  },
  [Constants.NAV_MENU_SERVICES]: {
    [Constants.SUB_NAV_SERVICES_IT_INFRASTRUCTURE]: 'scale-[2] translate-x-[-73%] translate-y-0',
    [Constants.SUB_NAV_SERVICES_VIRTUALIZATION]: 'scale-x-150 scale-y-[1.4] translate-x-[50%] translate-y-0',
    [Constants.SUB_NAV_SERVICES_MSP_SERVICE]: 'scale-[2.1] translate-x-[73.0%] translate-y-0',
    [Constants.SUB_NAV_SERVICES_SOFTWARE_DEVELOPMENT]: 'scale-[1.4] translate-x-[24%] translate-y-0',
    [Constants.SUB_NAV_SERVICES_ENGINEER_OUTSOURCING]: 'scale-[1.3] translate-x-[48%] translate-y-0',
    [Constants.SUB_NAV_SERVICES_IT_SYSTEM_MAINTENANCE]: 'scale-[1.5] translate-x-[-45%] translate-y-0',
    [Constants.SUB_NAV_SERVICES_IT_SYSTEM_SALES]: 'scale-150 translate-x-[-33%] translate-y-0',
  },
  [Constants.NAV_MENU_PRODUCTION]: {
    [Constants.SUB_NAV_PRODUCTION_SKPACK]: 'my-0 px-8',
    [Constants.SUB_NAV_PRODUCTION_MINERVA]: 'my-0 px-8',
  },
  [Constants.NAV_MENU_INFORMATION]: {
    [Constants.SUB_NAV_INFORMATION_MAPS]: 'scale-x-[1.6] scale-y-[1.75] h-full left-[30%]',
    [Constants.SUB_NAV_INFORMATION_MESSAGES]: 'scale-[1.5] h-full top-[7%] left-[-5%]',
    [Constants.SUB_NAV_INFORMATION_EMPLOYMENT]: 'scale-x-[1.2] scale-y-[1.46] h-full object-right-top',
  },
}
