import styled from "@emotion/styled";
import {colorAndTargetYear} from "./historyContents";
import {css} from "@emotion/react/macro";
import {mobileBlink} from "./historyFrames";
import {HistoryNavBarGroup, navBarGroup} from "./historyMenu";

export const HistoryContentsMobile = styled.div<colorAndTargetYear>`
  text-align: left;
  padding: 4rem 1.5rem 1.7rem 2.7rem;
  position: relative;

  &::before {
    background-color: rgba(230, 230, 230, 1); /* 선의 색상 설정 */
    content: " ";
    display: block;
    position: absolute;
    width: 0.15rem; /* 선의 너비 */
    height: 100%; /* 선의 높이 */
    top: 0;
    left: 3rem;
    bottom: 0;
  }

  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 1.5rem; /* 점의 크기 */
    height: 1.5rem; /* 점의 크기 */
    border-radius: 50%; /* 원형으로 만들기 */
    background: white;
    border: 0.2rem solid ${props => props.color};
    top: 0.2rem;
    z-index: 2;
    left: 2.29rem; /* 왼쪽 상단에 점을 위치 */
      // animation: ${(props) => props.targetHistoryYear ? css`${mobileBlink(props.color)} 1s infinite ease-in-out` : ''};
  }
`

export const HistoryNavBarGroupForMobile = styled(HistoryNavBarGroup)<navBarGroup>`
  flex-wrap: nowrap;
  scrollbar-width: none;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  //margin: 0 5%;
  display: flex;
  transition: top 0.6s, opacity 0.6s;
  box-shadow: none;
  //border-radius: 2rem;
  height: 2.7rem;

`
