import styled from "@emotion/styled";
import {keyframes} from "@emotion/react";

const hoverAnimator = keyframes`
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
`;

// 스타일드 컴포넌트 정의
export const HoverInputFileTitle = styled.p`
  text-align: center;

  width: 100%;
  padding: 0rem 0rem;
  font-size: 0.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: inline-block;
  color: #3fa2fa;
  letter-spacing: 2px;
  position: relative;
  box-sizing: border-box;
  border-radius: 1rem;

  &::after {
    content: 'ファイルを載せて下さい';
    position: absolute;
    padding: 0rem 0rem;
    left: 0;
    top: 0;
    color: #FFF;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    animation: ${hoverAnimator} 1.5s linear infinite; 
  }
`;
