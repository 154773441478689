export class ApiConstants {
  // api
  static BASE_URL_API = `/api`;
  static CALL_API_RECRUITMENT = `/recruitment`
  static CALL_API_CONTACTS = `/contacts`
  // 데이터 부르는 url-path
  static SERVICES_DATA_URL = `/data/servicesData.json`
  static CORPORATE_HISTORY_DATA_URL = `/data/corporateHistory.json`
  static CORPORATE_INFO_DATA_URL = `/data/corporateInfoData.json`
  static INTRODUCE_MERIT_DATA_URL = `/data/introduceMerit.json`
  static PARTNER_DATA_URL = `/data/partnerData.json`

  // banner image api
  // 회사 소개
  static SUB_NAV_INTRODUCE_ABOUT_IMAGE_URL = `/assets/bannerImage/introduce/intro1.svg`
  static SUB_NAV_INTRODUCE_MERIT_IMAGE_URL = `/assets/bannerImage/introduce/intro2.svg`
  static SUB_NAV_INTRODUCE_HISTORY_IMAGE_URL = `/assets/bannerImage/introduce/intro3.svg`
  static SUB_NAV_INTRODUCE_INFO_IMAGE_URL = `/assets/bannerImage/introduce/intro4.svg`
  // 회사 서비스
  static SUB_NAV_SERVICES_IT_SYSTEM_SALES_IMAGE_URL = `/assets/bannerImage/services/IT_SYSTEM_SALES.svg`
  static SUB_NAV_SERVICES_IT_SYSTEM_MAINTENANCE_IMAGE_URL = `/assets/bannerImage/services/IT_SYSTEM_MAINTENANCE.svg`
  static SUB_NAV_SERVICES_IT_INFRASTRUCTURE_IMAGE_URL = `/assets/bannerImage/services/IT_INFRASTRUCTURE.svg`
  static SUB_NAV_SERVICES_VIRTUALIZATION_IMAGE_URL = `/assets/bannerImage/services/VIRTUALIZATION.svg`
  static SUB_NAV_SERVICES_MSP_SERVICE_IMAGE_URL = `/assets/bannerImage/services/MSP_SERVICE.svg`
  static SUB_NAV_SERVICES_SOFTWARE_DEVELOPMENT_IMAGE_URL = `/assets/bannerImage/services/SOFTWARE_DEVELOPMENT.svg`
  static SUB_NAV_SERVICES_ENGINEER_OUTSOURCING_IMAGE_URL = `/assets/bannerImage/services/ENGINEER_OUTSOURCING.svg`
  // 회사 정보
  static SUB_NAV_INFORMATION_MAPS_IMAGE_URL = `/assets/bannerImage/information/access_banner.svg`
  static SUB_NAV_INFORMATION_MESSAGES_IMAGE_URL = `/assets/bannerImage/information/message_banner.svg`
  static SUB_NAV_INFORMATION_EMPLOYMENT_IMAGE_URL = `/assets/bannerImage/information/recruit_banner.svg`
  // 회사 연락
  static SUB_NAV_COMMUNICATION_CONTACT_IMAGE_URL = `/assets/bannerImage/communication/contact2.svg`
  // 제휴 회사
  static SUB_NAV_PRODUCTION_MINERVA_IMAGE_URL = `/assets/bannerImage/production/minerva-logo.svg`
  static SUB_NAV_PRODUCTION_MINERVA_BACKGROUND_IMAGE_URL = `/assets/bannerImage/production/minerva_banner.svg`
  static SUB_NAV_PRODUCTION_MINERVA_MOBILE_BACKGROUND_IMAGE_URL = `/assets/bannerImage/production/mobile_minerva_banner.svg`

  // namu logo
  static MAIN_LOGO = `/assets/logo/namu-logo.svg`
  static MAIN_LOGO_GRADIENT = `/assets/logo/namu-logo-gradient.svg`
  static MAIN_LOGO_CIRCLE = `/assets/logo/namu-logo-gradient-circle.svg`
  static MAIN_WHITE_LOGO = `/assets/logo/white_namu_logo.svg`
  static MAIN_PURPLE_LOGO = `/assets/logo/mobile_namu_purple_logo.svg`

  // 홈
  static URL_PATH_HOME = `/`
  // 소개
  static URL_PATH_INTRODUCE = `/introduce`; // Main Url Key
  static URL_PATH_INTRODUCE_ABOUT = `/introduce/about`
  static URL_PATH_INTRODUCE_MERIT = `/introduce/merit`
  static URL_PATH_INTRODUCE_HISTORY = `/introduce/history`
  static URL_PATH_INTRODUCE_INFO = `/introduce/info`
  // 정보
  static URL_PATH_INFORMATION = `/information`; // Main Url Key
  static URL_PATH_INFORMATION_MAPS = `/information/maps`
  static URL_PATH_INFORMATION_MESSAGES = `/information/messages`
  static URL_PATH_INFORMATION_EMPLOYMENT = `/information/employment`
  // 서비스
  static URL_PATH_SERVICES = `/services`; // Main Url Key
  static URL_PATH_SERVICES_IT_SYSTEM_INFRASTRUCTURE = `/services/it-infrastructure`
  static URL_PATH_SERVICES_VIRTUALIZATION = `/services/virtualization`
  static URL_PATH_SERVICES_MSP_SERVICE = `/services/msp-service`
  static URL_PATH_SERVICES_SOFTWARE_DEVELOPMENT = `/services/software-development`
  static URL_PATH_SERVICES_ENGINEER_OUTSOURCING = `/services/engineer-outsourcing`
  static URL_PATH_SERVICES_IT_SYSTEM_MAINTENANCE = `/services/it-system-maintenance`
  static URL_PATH_SERVICES_IT_SYSTEM_SALES = `/services/it-system-sales`
  // 커뮤니케이션
  static URL_PATH_COMMUNICATION = `/communication`; // Main Url Key
  static URL_PATH_COMMUNICATION_CONTACT = `/communication/contact`
  // 프라이버시
  static URL_PATH_PRIVACY_POLICY = `/privacy-policy`
  // 근로자 파견 사업의 마진율 공시 추가 -김한-
  static URL_PATH_WORKER_DISPATCHING_ACT = `/worker-dispatching-act`
  // 제품
  static URL_PATH_PRODUCTION = `/production`// Main Url Key
  static URL_PATH_PRODUCTION_SKPACK = `https://skpack.namutech.co.jp/`
  static URL_PATH_PRODUCTION_MINERVA = `/production/minerva`
}
