import {Box} from "@mui/material";
import styled from "@emotion/styled";

export const FormContactMold = styled(Box)`
  height: 100%;
  background-color: #DFEAF4;
  border: 0.1rem solid black;
  position: relative;
  border-radius: 0 0 1rem 1rem;
`

export const FormContainerFieldset = styled.fieldset`
  display: flex;
  justify-content: center;
  background-color: white;
  position: relative;
  overflow: hidden;
  padding: 3rem 0 12rem 0;
  @media screen and (max-width: 768px) {
    padding: 1rem 0 2rem 0;
  }
`
