import React from 'react';
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";
import {ServicesContentsDivineStyled} from "../../../features/styledComponents/services/servicesMainStyled";
import CommonServiceTypeA from "./serviceContent/commonServiceTypeA";
import ServicePartner from "./serviceContent/servicePartner";
import {serviceType} from "../../../features/types/service.d";

const Virtualization = ({serviceContent}: serviceType) => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.1});
  const imgTab =
    <img alt={'virtualIcon'}
         className={`z-10 w-[11%] h-[11%]
                      md:mt-8 md:mr-8 md:ml-10
                      sm:z-auto sm:items-center sm:m-0 sm:mb-4 sm:w-[15%]`}
         src={serviceContent.srcIcon}/>
  const mainImageClassName = `
      w-3/4 h-3/4`;
  const pClassName = `
     font-normal mt-4 text-[#243242]
     md:text-left md:w-[29rem] md:mt-[1.3rem] md:mb-[1rem]`
  const partnerImageClassName = [
    'w-full h-[96%] lg:w-[54%] lg:h-[54%]',
    'w-3/4 h-[68%] lg:w-[95.9%] lg:h-[52.5%]',
    'w-3/5 h-full lg:w-[92%] lg:h-[92%]',
    'w-1/2 h-[76%] lg:w-11/12 lg:h-full',
  ]

  return (
    <>
      <section className={`service-main-mold md:-mt-4 lg:mt-16 md:min-h-[650px] bg-[#F8D3D4]`}>
        <ServicesContentsDivineStyled className="-my-3 lg:mb-5" ref={innerRef} isVisible={isVisible}>
          {isVisible && <CommonServiceTypeA serviceContent={serviceContent}
                                            decoImage={imgTab}
                                            mainImageClassName={mainImageClassName}
                                            pClassName={pClassName}/>}
        </ServicesContentsDivineStyled>
      </section>
      <ServicePartner serviceContent={serviceContent} isOpenPTag={false} partnerImageStyle={partnerImageClassName}/>
    </>
  );
};

export default Virtualization;
