export async function callData(url: string) {
  try {
    const res = await fetch(url);
    if (!res.ok) {
      throw new Error(`정보 불러오기 실패: ${res.statusText}`);
    }
    const resData = await res.json();
    return resData;
  } catch (error) {
    console.error("데이터를 불러오는 중 오류 발생:", error);
    return null; // 오류가 발생한 경우 null 반환
  }
}
