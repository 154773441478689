export const validateCategory = (value: string | null) => {
  if (value === "null" || value === null || value === '') {
    return "カテゴリーを選んで下さい。";
  }
  return true;
};
export const validatePosition = (value: string | null) => {
  if (value === "null" || value === null || value === '') {
    return "ポジションをお選び下さい。";
  }
  return true;
};
