import React from 'react';
import SolutionService from "./solutionService/solutionService";
import SearchDataProcessingFeature from "./searchDataProcessingFeature/searchDataProcessingFeature";
import FileType from "./fileType/fileType";

const Minerva = () => {
  return (
    <div className={`overflow-hidden`}>
      <SolutionService/>
      <SearchDataProcessingFeature/>
      <FileType/>
    </div>
  );
};

export default Minerva;
