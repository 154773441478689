import React, {FC, ReactNode} from 'react';
import {Outlet} from "react-router-dom";
import {useData} from "../features/reducer/navReducer";
import {UseOverflowControl} from "../features/util/commonFacility/notScroll";

interface MainPageProps {
    errorPage?: ReactNode;
}

const MainPage: FC<MainPageProps> = ({errorPage}: { errorPage?: ReactNode }) => {
    const {state} = useData();
    UseOverflowControl(state.isNavAllPageOpen)
    return (
        <main>{!errorPage ? <Outlet/> : errorPage}</main>
    );
};

export default MainPage;
