import React, {useEffect, useState} from 'react';
import {FC} from "react";
import {ReactNode} from "react";
import {
  OverviewContainerStyled,
} from "../../../../features/styledComponents/introduce/aboutUs";
import {Constants} from "../../../../features/constants/appConstants";
import {callData} from "../../../../features/util/data/callData";
import {MeritContentData} from "../../../../features/types/globals.d";
import AboutUs from "./aboutUs/aboutUs";
import StrengthsOfUs from "./strengthsOfUs";

type AboutProps = {
  children?: ReactNode;
}
const About: FC<AboutProps> = () => {
  const [data, setData] = useState<MeritContentData[] | []>([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = Constants.INTRODUCE_MERIT_DATA_URL;
      const topicData = await callData(url);
      setData(topicData);
    };
    fetchData().then();
  }, []);
  return (
    <div className="flex flex-col items-center overflow-hidden">
      <AboutUs/>
      <StrengthsOfUs data={data}/>
    </div>
  );
};

export default About;
