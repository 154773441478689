import React from 'react';
import {Container} from "@mui/material";
import {DecoImage} from "../features/decoration/decoration";
import {DecoImages} from "../features/decoration/decoImages";
import {useWindowResizeToMobile} from "../features/util/responsive/mobile";
import styled from "@emotion/styled";

const WorkerDispatchingAct = () => {
  const isMobile = useWindowResizeToMobile();

  return (
    <div className={`bg-[#F7F8F5] mt-14 overflow-hidden`}>
      <div className={`flex justify-center relative overflow-hidden py-10 md:py-16`}>
        {!isMobile &&
            <>
              <DecoImage style={DecoImages.paperAirplane}/>
              <DecoImage style={DecoImages.flyPaperAirPlane}/>
              <DecoImage style={DecoImages.boxPattern1}/>
              <DecoImage style={DecoImages.boxPattern2}/>
              <DecoImage style={DecoImages.boxPattern3}/>
              <DecoImage style={DecoImages.boxPattern4}/>
              <DecoImage style={DecoImages.boxPatternPink}/>
              <DecoImage style={DecoImages.boxPatternBlue}/>
            </>}
        <Container maxWidth={'md'} sx={{zIndex: '20'}}>
          <h2
            className={"text-xl md:text-2xl text-[#243242] text-center font-medium mb-6 md:mb-10"}>労働者派遣事業におけるマージン率の公開</h2>
          <div
            className={`border border-black border-b-0 h-[2.5rem] bg-[#45AF7D] flex items-center pl-4 rounded-tl-[1rem] rounded-tr-[1rem] rounded-br-none rounded-bl-none`}>
            <div className={`w-4 h-4 rounded-full m-2 bg-[#FF577F]`}/>
            <div className={`w-4 h-4 rounded-full m-2 bg-[#F8D637]`}/>
            <div className={`w-4 h-4 rounded-full m-2 bg-[#2EDB48]`}/>
          </div>
          <div
            className={`border-[1.5px] border-black rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-none rounded-tl-none bg-[#DDF1E9]`}>
            <div className={`${!isMobile ? 'm-8' : 'm-5'} p-4 bg-white border-[1.5px] border-black rounded-[0.85rem]`}>
              <div
                className={"space-y-6 space-x-0 md:space-y-8 overflow-y-scroll max-h-[30rem] md:max-h-[45rem] text-xs md:text-base"}>
                {/*<div className="text-start text-lg font-semibold mt-3">*/}
                {/*  労働者派遣事業におけるマージン率の公開*/}
                {/*</div>*/}
                {/*<div className="text-end text-base font-medium pr-1">*/}
                {/*  制定日2024年6月1日*/}
                {/*  <br/>*/}
                {/*  代表取締役 李 秀秉*/}
                {/*{`　`}*/}
                {/*</div>*/}
                <div className={`text-base worker-dispatching-act`}>
                  <p>
                    平成24年10月1日付の改正労働者派遣法施行に伴い、派遣元事業主（当社）は、毎事業年度終了後、派遣先から受け取る派遣料金と派遣労働者に支払う賃金の差額（マージン率）を公開することが義務付けられました（法第23条第５項）。
                  </p>
                  <br/>
                  <p>
                    このマージン率は以下の計算式で算出されます。
                  </p>
                  <br/>
                <div className="box">
                  <p className="margin-rate">
                    マージン率 =
                    <div className="line-container">
                      <div className="text-above">派遣料金の平均額 – 派遣労働者の賃金の平均額</div>
                      <div className="line"></div>
                      <div className="text-below">派遣料金の平均額</div>
                      <div className="text-note">（小数点第2位以下は四捨五入）</div>
                    </div>
                  </p>
                </div>
                  <p>
                    令和5年度（対象期間令和5年1月1日～令和5年12月31日）
                  </p>
                  <ListWithBrackets>
                    <li>
                      <p>
                        派遣労働者数 12名（2024年6月1日付）
                      </p>
                    </li>
                    <li>
                      <p>
                        派遣先の数 1社
                      </p>
                    </li>
                    <li>
                      <p>
                        派遣料金の平均額（1日8時間あたり）40,343円
                      </p>
                    </li>
                    <li>
                      <p>
                        派遣労働者の賃金の平均額（1日8時間あたり）24,747円
                      </p>
                    </li>
                    <li>
                      <p>
                        マージン率 38.7%
                      </p>
                    </li>
                    <p className="text-xs">
                      ※マージン率に含まれるもの
                    </p>
                    <p className="text-xs">
                      ・派遣元事業者として負担する社会保険料（厚生年金保険、健康保険、雇用保険、労災保険等）
                    </p>
                    <p className="text-xs">
                      ・諸経費（派遣労働者が取得する有給休暇・慶弔休暇の費用、資格取得や研修の費用、営業担当者の人件費や営業活動費、オフィス賃貸料、福利厚生費等）
                    </p>
                    <li>
                      <p>
                        派遣労働者の待遇の決定に係る労使協定を締結しているか否かの別
                      </p>
                      <p>
                        労使協定の締結の有無：有
                      </p>
                      <p>
                        協定書の有効期間の終期：令和７年3月31日
                      </p>
                      <p>
                        労使協定書の対象となる労働者の範囲：全ての派遣労働者
                      </p>
                    </li>
                    <li>
                      <p>
                        労働者のキャリア形成支援制度に関する事項
                      </p>
                      <p>
                        ビジネスマナー研修
                      </p>
                      <p>
                        ITセキュリティ基礎研修
                      </p>
                      <p>
                        文書作成・文書管理・プレゼンテーション研修
                      </p>
                      <p>
                        技術研修（Linux、ハードウェア、プラットフォーム）
                      </p>
                      <p>
                        マネジメント研修
                      </p>
                    </li>
                    <li>
                      <p>
                        キャリアコンサルティング相談窓口 営業部 派遣事業担当
                      </p>
                    </li>
                  </ListWithBrackets>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default WorkerDispatchingAct;

// 괄호를 넣는 ol 태그.
const ListWithBrackets = styled.ol`
  counter-reset: list-counter;

  > li {
    list-style: none;
    position: relative;
    padding-left: 2.8rem;

    //&:last-child::before {
    //  content: none; /* 마지막 li 요소는 숫자를 표시하지 않음 */
    //}
  }

  > li::before {
    content: ' ( ' counter(list-counter) ' ) ';
    counter-increment: list-counter;
    position: absolute;
    left: 0;
  }
`;
// 원안에 숫자
const ListWithCircles = styled.ol`
  counter-reset: list-counter;
  list-style: none;
  padding-left: 0;
  
  > li {
    position: relative;
    padding-left: 1.8rem;
    display: flex;
  }

  > li::before {
    counter-increment: list-counter;
    content: counter(list-counter, decimal); /* 0 없이 숫자를 표시 */
    position: absolute;
    left: 0;
    top: 0.15rem;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background-color: white; /* 원의 배경색 */
    border: 1px solid #c4c4c4;
    color: #2f2f2f; /* 숫자의 색상 */
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem; /* 숫자의 크기 */
  }
`;
