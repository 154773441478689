import styled from "@emotion/styled";
import {Constants} from "../../constants/appConstants";

export const ButtonContainer = styled.div<{ index: number, indexLength: number, type: number }>`
  min-width: 10rem;
  display: flex;
  justify-content: center;
  margin: ${props => {
    let value;
    if (props.type === Constants.NAV_MENU_SERVICES) {
      if (props.index === 0) {
        value = '0 2rem 0 0rem'
      } else if (props.indexLength === props.index) {
        value = '0 0rem 0 2rem'
      } else {
        value = '0rem 2rem'
      }
    }
    return value;
  }};
  //@media screen and (max-width: 1024px) {
  //    width: 100%;
  //    border: none;
  //}
`
export const TopicButton = styled.button<{ active: boolean }>`
  display: flex; // Flex 컨테이너 설정
  align-items: center; // 수직 방향으로 중앙 정렬
  justify-content: center;
  margin: 0.4rem 0rem 0.4rem;
  width: 100%;
  color: ${props => props.active ? '#243142' : '#24314275'};
  font-weight: 500;
  font-size: 0.9rem;
  transition: color 0.2s ease;
  white-space: nowrap;

    &:hover {
        color: #243142; // 호버시에 글자색상 변경
    }
  @media screen and (max-width: 1024px) {
    margin: 0.7rem 1rem;
  }
`
