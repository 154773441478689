import {useEffect} from "react";

export const UseOverflowControl = (isOpen: boolean) => {
  useEffect(() => {
    // 컴포넌트가 마운트될 때 body 스타일을 변경하여 스크롤을 비활성화
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      // 컴포넌트가 언마운트될 때 실행될 클린업 함수에서 스크롤을 다시 활성화
      document.body.style.overflow = 'auto';
    }
    // 클린업 함수를 반환하여 컴포넌트가 언마운트될 때 스크롤을 활성화
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);
};
