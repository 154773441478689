import './App.scss';
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import React, {ReactNode, useRef, useState} from "react";
import MainPage from "./pages/mainPage";
import {DataProvider} from "./features/reducer/navReducer";
import {LocationListener} from "./features/reducer/locationReducer";
import UpButton from "./components/common/upButton";

function App({errorPage = null}: { errorPage?: null | ReactNode }) {
  const [touchScreen, setTouchScreen] = useState<boolean>(false);
  const startTouch = useRef<{ x: number, y: number }>({x: 0, y: 0});
  const handleTouchStart = (e: any) => {
    startTouch.current = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    };
  };

  // 가만히 있는 페이지를 클릭 시 헤더바가 내려오거나 올라가게 끔 하는 함수
  const handleTouchEnd = (e: any) => {
    if (e.target.closest('#Header') || e.target.closest('.HeaderNotComeDown')) return
    const endTouch = {
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY
    };

    // Calculate the distance between start and end touches
    const deltaX = Math.abs(endTouch.x - startTouch.current.x);
    const deltaY = Math.abs(endTouch.y - startTouch.current.y);

    // Threshold for a "slight touch" gesture (e.g., 10 pixels)
    if (deltaX <= 3 && deltaY <= 3) {
      setTouchScreen(!touchScreen); // Toggle the state if the touch is slight
    }
  };

  // 가만히 있는 페이지를 클릭 시 헤더바가 내려오거나 올라가게 끔 하는 변수
  function cancelTouchEnd() {
    setTouchScreen(false);
  }

  return (
    <div className="App" id="NamuHome" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <DataProvider>
        <LocationListener/>
        <Header touchScreen={touchScreen} cancelTouchEnd={cancelTouchEnd}/>
        <MainPage errorPage={errorPage}/>
        <UpButton/>
        <Footer/>
      </DataProvider>
    </div>
  );
}

export default App;
