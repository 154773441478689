import { useState, useEffect } from 'react';

const DeviceComponent = () => {
  const [device, setDevice] = useState('Unknown');

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('android') > -1) {
      setDevice('Android');
    } else if (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1 || userAgent.indexOf("ipod") > -1) {
      setDevice('iOS');
    } else {
      setDevice('Non-Android or iOS mobile device');
    }
  }, []);

  return device
};

export default DeviceComponent;
