import React, {useEffect, useState} from 'react';
import {PostRequest} from "../../../../features/util/data/postRequest";
import {Checkbox, Container, FormControlLabel, Grid, MenuItem, TextField} from "@mui/material";
import MuiTheme from "../../../common/muiTheme";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {formatPhoneNumber} from "../../../../features/util/commonFacility/formatPhoneNumber";
import InputFile from "../../../common/inputFile/inputFile";
import {useWindowResizeToTablet} from "../../../../features/util/responsive/tablet";
import {theme} from "../../../../features/util/meterialThemeSettings/MeterialThemeSetting";
import {validatePosition} from "../../../../features/util/validator/validator";
import {ClickEventButton} from "../../../../features/styledComponents/common/Button";
import {DecoImage} from "../../../../features/decoration/decoration";
import {DecoImages} from "../../../../features/decoration/decoImages";
import MuiModal from "../../../common/muiModal";
import DownloadButtonDeco from "../../../common/downloadButtonDeco";
import {useWindowResizeToMobile} from "../../../../features/util/responsive/mobile";
import mobilePaperIcon from "../../../../../assets/img/recruitment/mobile_recruit_paper_icon.svg";
import {PersonalInformationContent} from "../../../../../assets/data/personalInformationContents";

export interface ResumeFormInput {
  position: string;
  lastNameJp: string;
  firstNameJp: string;
  lastNameEn: string;
  firstNameEn: string;
  phone: string;
  email: string;
  resume: File | null;
  jobResume?: File | null;
  additionalInfo: string;
  agreement: boolean;
}

const Entry = () => {
  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    setValue,
    reset,
    trigger,
    control,
    watch,
  } = useForm<ResumeFormInput>({
    mode: 'onChange',
    defaultValues: {
      position: '',
      agreement: false,
    },
  })
  const [jobDescription, setJobDescription] = useState<string>('');
  const isTablet = useWindowResizeToTablet();
  const isMobile = useWindowResizeToMobile();
  const gridSize = (!isTablet && !isMobile) ? 2 : 0;
  const gridItemSize = (!isTablet && !isMobile) ? 6 : 12;
  const selectedPosition = watch('position');
  const [msgTitle, setMsgTitle] = useState<string>('　');
  const [msgContent, setMsgContent] = useState<string>('　');
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (selectedPosition === '経理、総務' || selectedPosition === '営業') {
      setJobDescription('職務経歴書');
    } else if (selectedPosition === '') {
      setJobDescription('');
    } else {
      setJobDescription('スキルシート');
    }
  }, [selectedPosition]);

  const onSubmit: SubmitHandler<ResumeFormInput> = (data: ResumeFormInput) => {
    const formData = new FormData();
    formData.append('position', data.position);
    formData.append('lastNameJp', data.lastNameJp);
    formData.append('firstNameJp', data.firstNameJp);
    formData.append('lastNameEn', data.lastNameEn);
    formData.append('firstNameEn', data.firstNameEn);
    formData.append('phone', data.phone);
    formData.append('email', data.email);
    formData.append('resume', data.resume as Blob);
    formData.append('jobResume', data.jobResume as Blob);
    formData.append('additionalInfo', data.additionalInfo);
    formData.append('agreement', data.agreement.toString());
    const applicantFullName = data.lastNameJp + ' ' + data.firstNameJp
    PostRequest.PostRecruitment(formData)
      .then(res => {
        reset({
          position: '',
          lastNameJp: '',
          firstNameJp: '',
          lastNameEn: '',
          firstNameEn: '',
          phone: '',
          email: '',
          resume: null,
          jobResume: null,
          additionalInfo: '',
          agreement: false,
        });
        setOpen(true)
        setMsgTitle('転送完了')
        setMsgContent(`${applicantFullName} 様、エントリーを受け取りました。<br/>ありがとうございます。`);
      })
      .catch(error => {
        setOpen(true)
        setMsgTitle('転送失敗')
        setMsgContent(`${applicantFullName} 様、エントリーの受け取りができませんでした。`);
      })
  }
  type typeResume = 'resume' | 'jobResume' | 'skillSheet';

  function resetData() {
    reset({
      position: '',
      lastNameJp: '',
      firstNameJp: '',
      lastNameEn: '',
      firstNameEn: '',
      phone: '',
      email: '',
      resume: null,
      jobResume: null,
      additionalInfo: '',
      agreement: false,
    });
  }

  const onBlurPosition = async () => {
    await trigger("position"); // 'category' 필드에 대한 유효성 검사를 트리거합니다.
  };
  const onBlurLastNameJp = async () => {
    await trigger("lastNameJp");
  }
  const onBlurFirstNameJp = async () => {
    await trigger("firstNameJp");
  }
  const onBlurLastNameEn = async () => {
    await trigger("lastNameEn");
  }
  const onBlurFirstNameEn = async () => {
    await trigger("firstNameEn");
  }
  const onBlurPhone = async () => {
    await trigger("phone");
  }
  const onBlurEmail = async () => {
    await trigger("email");
  }
  const handlePhoneChange = (event: any) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setValue('phone', formattedPhoneNumber);
  };
  const handleRemove = (event: any, type: typeResume) => {
    event.preventDefault();
    event.stopPropagation();
    if (type === 'resume') {
      setValue('resume', null);
    } else if (type === 'jobResume') {
      setValue('jobResume', null);
    }
  };

  function closeModal() {
    setOpen(false)
  }

  return (
    <section className={`relative`}>
      <MuiModal open={open} onClose={closeModal} title={msgTitle}>
        <>
          <div className={`flex w-full h-48 justify-center items-center`}>
            <p dangerouslySetInnerHTML={{
              __html: msgContent
            }} className={`text-center`}/>
          </div>
          <div className={`flex justify-center`}>
            <ClickEventButton onClick={closeModal}
                              className={`py-[0.7rem] px-0 w-2/5 rounded-[0.6rem] text-white 
                              -mt-4 h-12 shadow-none ${msgTitle === '転送完了' ? 'bg-[#1876D2]' : 'bg-[#FF577F]'} `}>閉じる</ClickEventButton>
          </div>
        </>
      </MuiModal>
      <div className={`flex items-center justify-center gap-4 pt-8 px-0 pb-4 lg:py-12 lg:px-0`}>
        <img className={`w-[1.7rem] block md:hidden`} src={mobilePaperIcon} alt=""/>
        <h2 className={`text-center font-medium text-xl text-[#243142]`}>エントリー</h2>
      </div>
      {!isMobile && <DecoImage style={{...DecoImages.entryMail2,}}/>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset
          className={`bg-white flex justify-center relative overflow-hidden md:pt-12 md:px-0 md:pb-48 pt-4 px-0 pb-8`}>
          {!isMobile &&
            <>
              <DecoImage style={{...DecoImages.entryCube2,}}/>
              <DecoImage style={{...DecoImages.entryAtMark1,}}/>
              <DecoImage style={{...DecoImages.entryMail4,}}/>
              <DecoImage style={{...DecoImages.entryCube1,}}/>
              <DecoImage style={{...DecoImages.entryAtMark2,}}/>
              <DecoImage style={{...DecoImages.entryMail3,}}/>
              <DecoImage style={{...DecoImages.entryPostBox,}}/>
            </>}
          <MuiTheme theme={theme}>
            <Container maxWidth={'md'} sx={{padding: '0 1.5rem'}}>
              <div
                className={`h-full bg-[#DFEAF4] rounded-[0.6rem] relative border-[1.5px] border-black md:py-[2.8rem] md:px-[2.3rem] py-6 px-6`}>
                <div className={`flex justify-end ${isMobile ? 'flex-col' : 'flex-row'}`}>
                  <DownloadButtonDeco component="a"
                                      margin="normal"
                                      variant="outlined"
                                      href={`/document/resume.doc`}
                                      download="履歴書.doc"
                                      className={'HeaderNotComeDown font-medium border-[1.5px] px-5 rounded-[0.6rem] h-[2.3625rem] w-auto my-2'}
                                      types={'entry'}>履歴書ダウンロード</DownloadButtonDeco>
                  <DownloadButtonDeco component="a"
                                      margin="normal"
                                      variant="outlined"
                                      href={`/document/jobResume.doc`}
                                      download="職務経歴書.doc"
                                      className={'HeaderNotComeDown font-medium border-[1.5px] px-5 rounded-[0.6rem] h-[2.3625rem] w-auto my-2'}
                                      types={'entry'}>職務経歴書ダウンロード</DownloadButtonDeco>
                  <DownloadButtonDeco component="a"
                                      margin="normal"
                                      variant="outlined"
                                      href={`/document/skill_sheet.xlsx`}
                                      download="スキルシート.xlsx"
                                      className={'HeaderNotComeDown font-medium border-[1.5px] px-5 rounded-[0.6rem] h-[2.3625rem] w-auto my-2'}
                                      types={'entry'}>スキルシートダウンロード</DownloadButtonDeco>
                </div>
                <Controller
                  name="position"
                  control={control}
                  rules={{
                    required: "ポジションをお選び下さい。",
                    validate: validatePosition
                  }}
                  render={({field}) => (
                    <TextField
                      select fullWidth size="small" label="ポジション" required margin="normal"
                      {...field}
                      error={Boolean(errors.position)}
                      variant="outlined"
                      onBlur={onBlurPosition}
                      helperText={errors.position?.message || '　'}
                      FormHelperTextProps={{
                        style: {opacity: errors.position?.message ? 1 : 0}
                      }}
                      className={'HeaderNotComeDown'}
                    >
                      <MenuItem value={'CE(ハードウェアエンジニア)'} className={'HeaderNotComeDown'}>
                        CE(ハードウェアエンジニア)
                      </MenuItem>
                      <MenuItem value={'SE(システムエンジニア)'} className={'HeaderNotComeDown'}>
                        SE(システムエンジニア)
                      </MenuItem>
                      <MenuItem value={'ネットワークエンジニア'} className={'HeaderNotComeDown'}>
                        ネットワークエンジニア
                      </MenuItem>
                      <MenuItem value={'開発 (フロントエンド)'} className={'HeaderNotComeDown'}>
                        開発 (フロントエンド)
                      </MenuItem>
                      <MenuItem value={'開発 (バックエンド)'} className={'HeaderNotComeDown'}>
                        開発 (バックエンド)
                      </MenuItem>
                      <MenuItem value={'経理、総務'} className={'HeaderNotComeDown'}>
                        経理、総務
                      </MenuItem>
                      <MenuItem value={'営業'} className={'HeaderNotComeDown'}>
                        営業
                      </MenuItem>

                    </TextField>
                  )}
                />
                <Grid container spacing={gridSize}>
                  <Grid item xs={gridItemSize}>
                    <TextField margin="normal" fullWidth label="性" variant="outlined"
                               className={'HeaderNotComeDown'}
                               placeholder="例）山田" error={Boolean(errors.lastNameJp)} required
                               helperText={errors.lastNameJp?.message || '　'}
                               FormHelperTextProps={{
                                 style: {opacity: errors.lastNameJp?.message ? 1 : 0}
                               }}
                               {...register("lastNameJp", {
                                 required: "お名前(性)は必須項目です。"
                               })}
                               onBlur={onBlurLastNameJp}
                               size="small"/>
                  </Grid>
                  <Grid item xs={gridItemSize}>
                    <TextField margin="normal" fullWidth label="名" variant="outlined"
                               className={'HeaderNotComeDown'}
                               size="small" required placeholder="例）太郎"
                               error={Boolean(errors.firstNameJp)}
                               helperText={errors.firstNameJp?.message || '　'}
                               FormHelperTextProps={{
                                 style: {opacity: errors.firstNameJp?.message ? 1 : 0}
                               }}
                               {...register("firstNameJp", {
                                 required: "お名前(名)は必須項目です。",
                                 minLength: {
                                   value: 2,
                                   message: "お名前(名)は2文字以上入力してください。",
                                 }
                               })}
                               onBlur={onBlurFirstNameJp}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={gridSize}>
                  <Grid item xs={gridItemSize}>
                    <TextField margin="normal" fullWidth label="性(ローマ字)" required
                               className={'HeaderNotComeDown'}
                               placeholder="例）Yamada" error={Boolean(errors.lastNameEn)}
                               helperText={errors.lastNameEn?.message || '　'}
                               FormHelperTextProps={{
                                 style: {opacity: errors.lastNameEn?.message ? 1 : 0}
                               }}
                               variant="outlined" size="small"
                               {...register("lastNameEn", {
                                 required: "性(ローマ字)は必須項目です。",
                               })}
                               onBlur={onBlurLastNameEn}
                    />
                  </Grid>
                  <Grid item xs={gridItemSize}>
                    <TextField margin="normal" fullWidth label="名(ローマ字)" variant="outlined"
                               className={'HeaderNotComeDown'}
                               placeholder="例）Taro" required
                               size="small" error={Boolean(errors.firstNameEn)}
                               helperText={errors.firstNameEn?.message || '　'}
                               FormHelperTextProps={{
                                 style: {opacity: errors.firstNameEn?.message ? 1 : 0}
                               }}
                               {...register("firstNameEn", {
                                 required: "名(ローマ字)は必須項目です。",
                               })}
                               onBlur={onBlurFirstNameEn}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSize}>
                  <Grid item xs={gridItemSize}>
                    <TextField margin="normal" fullWidth label="お電話番号" variant="outlined"
                               className={'HeaderNotComeDown'}
                               placeholder="例）080-1111-1111" required
                               size="small" error={Boolean(errors.phone)}
                               helperText={errors.phone?.message || '　'}
                               FormHelperTextProps={{
                                 style: {opacity: errors.phone?.message ? 1 : 0}
                               }}
                               {...register("phone", {
                                 required: "お電話番号は必須項目です。",
                               })}
                               onBlur={onBlurPhone}
                               inputProps={{maxLength: 13}}
                    />
                  </Grid>
                  <Grid item xs={gridItemSize}>
                    <TextField margin="normal" fullWidth label="メールアドレス" required
                               placeholder="例）example@email.com" error={Boolean(errors.email)}
                               className={'HeaderNotComeDown'}
                               helperText={errors.email?.message || '　'}
                               FormHelperTextProps={{
                                 style: {opacity: errors.email?.message ? 1 : 0}
                               }}
                               variant="outlined" size="small"
                               {...register("email", {
                                 required: "メールアドレスは必須項目です。",
                                 pattern: {
                                   value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                   message: "正しいメールアドレスではありません。",
                                 },
                               })}
                               onBlur={onBlurEmail}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSize} sx={{marginTop: isMobile ? '1rem' : '0rem'}}
                      className={'HeaderNotComeDown'}>
                  <Grid item xs={gridItemSize}>
                    <Controller
                      name='resume'
                      control={control}
                      defaultValue={null}
                      rules={{required: '履歴書をアップロードしてください。'}}
                      render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
                        <InputFile
                          onBlur={onBlur}
                          contents={"履歴書"}
                          file={value}
                          handleRemove={(e) => handleRemove(e, 'resume')}
                          inputFile={(e) => onChange(e)}
                          error={error} label={'resume'}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={gridItemSize}>
                    <Controller
                      name='jobResume'
                      control={control}
                      defaultValue={null}
                      rules={{required: '職務経歴書をアップロードしてください。'}}
                      render={({field: {onChange, onBlur, value}}) => (
                        <InputFile
                          onBlur={onBlur}
                          contents={jobDescription}
                          file={value}
                          handleRemove={(e) => handleRemove(e, 'jobResume')}
                          inputFile={(e) => onChange(e)}
                          label={'jobResume'}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <TextField
                  label="補足" variant="outlined" rows={3}
                  className={'HeaderNotComeDown'}
                  multiline size="small" margin="normal"
                  fullWidth
                  {...register("additionalInfo",)}
                  InputProps={{
                    style: {
                      padding: 0,
                    }
                  }}
                />
                {/* 여기서 부터 개인 정보 보호법 에 대한 설명이 나온다. */}
                <div className={`mt-[47px]`}>
                  <div className={`border-[1.5px] border-black mb-4 p-[0.3rem] bg-white rounded-[0.6rem]`}>
                    <div
                      className={`w-full text-sm h-[15rem] overflow-y-auto rounded-[0.6rem] mt-2 bg-white text-black`}>
                      <p className={`text-center pt-4 pb-2 px-2 font-medium text-sm`}>個人情報の取り扱い</p>
                      <ol className={`m-2 ml-5 p-0 md:p-2 md:pt-0 md:px-3 md:pb-6 text-sm list-decimal`}>
                        {PersonalInformationContent.map((data, index: number) => (
                          <li key={`privacyKey-${index}`}
                              className={`whitespace-pre-wrap leading-[1.1rem] mb-4 pl-1`}
                              dangerouslySetInnerHTML={{__html: data.contents}}/>
                        ))}
                      </ol>
                    </div>
                  </div>

                  {/* 동의 하는 버튼을 이곳에서 받는 작업을 진행한다. */}
                  <div className={`ml-1`}>
                    <Controller
                      name="agreement"
                      control={control}
                      rules={{required: '同意する'}}
                      render={({field, fieldState: {error}}: any) => (
                        <FormControlLabel
                          className={'HeaderNotComeDown'}
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                            />
                          }
                          label={
                            <span
                              className={`text-sm ${(error || field.value === false) ? 'text-[#d32f2f]' : 'text-black'} leading-7`}>同意する</span>
                          }
                          labelPlacement="end"
                        />
                      )}
                    />
                  </div>

                  {/* 버튼 클릭 (송신 삭제) */}
                  <div className={`flex justify-end ${(!isTablet && !isMobile) ? 'mt-12' : 'mt-4'}`}>
                    <ClickEventButton size="small" variant="contained" type="reset"
                                      style={{backgroundColor: '#FF577F'}}
                                      className={'HeaderNotComeDown border-[1.5px] text-sm font-normal'}
                                      onClick={() => resetData()}
                                      sx={{marginRight: `0.5rem`}}> 取消 </ClickEventButton>
                    <ClickEventButton disabled={!isValid} size="small" variant="contained"
                                      sx={{marginLeft: `0.5rem`}}
                                      className={'HeaderNotComeDown border-[1.5px] text-sm font-normal'}
                                      style={{
                                        backgroundColor: isValid ? '#1876D2' : 'rgba(0, 0, 0, 0.12)',
                                        color: 'white'
                                      }}
                                      type="submit">送信</ClickEventButton>
                  </div>
                </div>
              </div>
            </Container>
          </MuiTheme>
        </fieldset>
      </form>
    </section>
  )
    ;
};

export default Entry;
const privacyInfo = [
  {title: "事業者名", subject: "NAMU TECH JAPAN株式会社"},
  {title: "個人情報保護管理者", subject: "営業部 執行役員　権 泰旭"},
  {
    title: "個人情報の利用目的",
    subject: "当フォームで入力いただく個人情報は、弊社採用業務と結果のご連絡のためにのみ利用します。"
  },
  {title: "個人情報の第三者提供", subject: "当フォームで入力いただく個人情報は、第三者への提供を行いません。"},
  {
    title: "個人情報の取扱いの委託",
    subject: "当フォームで入力いただいた個人情報の取扱いを外部に委託することはありません。"
  },
  {
    title: "個人情報の開示等の請求",
    subject: "お客様は、ご自身の個人情報の開示、内容の訂正、利用の停止等を当社の「個人情報に関するお問合せ窓口」に請求することができます。"
  },
  {
    title: "個人情報をご提供いただくことの任意性",
    subject: "お客様が当社に個人情報を提供されるかどうかはお客様の任意によるものです。ただし、個人情報の一部をご提供いただけない場合は、お問い合わせ等に対し、適切なご対応ができない場合があります。"
  }
];
