import {Constants} from "../../constants/appConstants";
import {ContactFormInput} from "../../../components/body/communicationContact";

export class PostRequest {
  static async PostRecruitment(formData: FormData): Promise<Response> {
    return await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}${Constants.BASE_URL_API}${Constants.CALL_API_RECRUITMENT}`, {
      method: 'POST',
      body: formData,
      mode: 'cors',
    })
  }

  static async postContact(data: ContactFormInput): Promise<Response> {
    return await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}${Constants.BASE_URL_API}${Constants.CALL_API_CONTACTS}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
  }
}
