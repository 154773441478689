import React from 'react';
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";
import {ServicesContentsDivineStyled} from "../../../features/styledComponents/services/servicesMainStyled";
import CommonServiceTypeA from "./serviceContent/commonServiceTypeA";
import ServicePartner from "./serviceContent/servicePartner";
import {serviceType} from "../../../features/types/service.d";

const EngineerOutsourcing = ({serviceContent}: serviceType) => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.1});
  const imgTab = <img alt={'mobilePlusIcon'}
                      src={serviceContent.mobileSrcService2}
                      className={`mt-4 w-3/12 h-2/5 block md:hidden`}/>
  const mainImageClassName = `
       md:w-[80%] md:h-[80%] w-[90%] h-[90%]`;
  const pClassName = `
      font-normal mt-0 h-[4.5rem] text-[#243242]
      md:text-center md:h-[4.5rem] md:w-[25rem] md:mt-[3.0rem] md:mb-[1rem]`
  const partnerImageClassName = [
    'w-1/2 h-5/6 lg:w-1/2 lg:h-1/2',
    'w-1/2 h-5/6 lg:w-1/2 lg:h-1/2',
    'w-1/2 h-5/6 lg:w-1/2 lg:h-1/2',
    'w-1/2 h-5/6 lg:w-1/2 lg:h-1/2',
  ]

  return (
    <>
      <section className={`service-main-mold md:-mt-4 lg:mt-16 bg-[#EEF9FE]`}>
        <ServicesContentsDivineStyled className="my-2 lg:mb-5" ref={innerRef} isVisible={isVisible}>
          {
            isVisible &&
            <CommonServiceTypeA serviceContent={serviceContent}
                                decoImage={imgTab}
                                mainImageClassName={mainImageClassName}
                                pClassName={pClassName}/>
          }
        </ServicesContentsDivineStyled>
      </section>
      <ServicePartner serviceContent={serviceContent} isOpenPTag={true} partnerImageStyle={partnerImageClassName}/>
    </>
  );
};

export default EngineerOutsourcing;
