import styled from "@emotion/styled";


export const JobsContainerStyled = styled.section`
  padding: 6rem 0 0;
  align-items: center;
  position: relative;

  h1 {
    text-align: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.75rem;
    padding-bottom: 1rem;
  }

  > div {

    padding-bottom: 4rem;
    display: flex;
    justify-content: center;
  }

  img {
    width: 100%;
    height: auto;
  }

  .MuiTypography-root {
    font-size: 0.85rem
  }
`

export const EngineerContainerBtnStyled = styled.div`
    //background-color: #ffffff;
    //display: flex;
    //flex-direction: row;
    //flex-wrap: wrap;
    //margin-left: 7rem;
    //position: relative;

    .MuiButtonBase-root {
        font-weight: 600;
        color: black;
        width: auto;
    }

    .MuiBox-root {
        border-color: rgba(0, 0, 0, 0.3);
        color: #505051;
    }
`

export const EngineerContainerDetailStyled = styled.div`
  //font-size: 1.6rem;
  //font-weight: 500;
  //color: #414042;
  //position: relative;
  //z-index: 1;
  //width: 100%;
  //@media (min-width: 1100px) {
  //  position: absolute;
  //  left: 0.5%;
  //  top: 13%;
  //}

  .tabBox {
    width: auto;
  }

  .MuiTabs-indicator {
    background-color: #1876D2;
  }

  .MuiTypography-root {
    padding-right: 16rem;
	  min-height: 5rem;
    font-weight: 400;
      font-size: 1rem;
	  color: #1876D2;
  }

  .MuiButtonBase-root {
    font-size: 1rem;
      font-weight: normal;
    padding: 0.8rem 1rem;
  }

  .MuiBox-root {
    width: 50.8rem;
  }
    @media (max-width: 1300px) {
        .MuiTypography-root {
            padding-right: 22rem;
            font-weight: 400;
            font-size: 1rem;
        }
    }
  @media (max-width: 1124px) {
    font-size: 1rem;
    .MuiButtonBase-root {
      font-size: 1rem;
      padding: 0.6rem 0.6rem;
    }

    .MuiBox-root {
      width: 48rem;
    }

    .MuiTypography-root {
        padding-right: 25rem;
        font-weight: 400;
        font-size: 1rem;
    }
  }
`

export const EngineerContainerImageStyled = styled.div`
  //position: relative;
  //width: 100%;
  //height: 100%;
  //@media (max-width: 1100px) {
  //  right: 15%;
  //}
`


export const OfficerContainerBtnStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 7rem;
  position: relative;

  .MuiButtonBase-root {
    font-size: 0.75rem;
    font-weight: 600;
    color: white;
    width: 50%;;
  }

  .MuiTabs-indicator {
    background-color: white;
  }

  .MuiBox-root {
    color: white;
    border-color: rgba(255, 255, 255, 0.6);
    width: 25rem;
  }

  .MuiTabs-flexContainer {
    justify-content: space-around;
  }
`

export const OfficerContainerDetailStyled = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
  position: relative;
  z-index: 1;
  width: 100%;
  @media (min-width: 0px) {
    position: absolute;
    left: 65%;
    top: 13%;
  }

  .tabBox {
    width: auto;
  }

  .MuiTabs-indicator {
    background-color: white;
  }

  .MuiTypography-root {
    font-weight: 400;
	  font-size: 1rem;
  }

  .MuiButtonBase-root {
    font-size: 1.1rem;
	  font-weight: normal;
    padding: 0.8rem 0.3rem;
  }

  .MuiBox-root {
    width: 17rem;
  }

  @media (max-width: 680px) {
    font-size: 1.2rem;
  }
`

export const OfficerContainerImageStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  //@media (max-width: 1100px) {
  //  left: 10%;
  //}
`

export const MobileJobsContainerStyled = styled.section`
  align-items: center;

  .jobMobileTitle {
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
    color: #243142;
  }

  .jobMobileContentEngineer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1.5rem;

    h1 {
      text-align: center;
      color: #414042;
      font-size: 1.2rem;
      font-weight: 600;
      margin: 1rem 0;
    }
  }

  .desktopBackground {
    padding: 0.65rem 0.75rem 5rem;
    width: 308.133px;
    height: 232.187px;
    background-size: cover;
    font-weight: 500;
  }

  .jobMobileContentOfficer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #1876D2;
    padding: 2rem 1.5rem;

    h1 {
      text-align: center;
      color: white;
      font-size: 1.2rem;
      margin: 1rem 0;
    }
  }

  .laptopBackground {
    padding: 1rem 1.7rem 2.5rem;
    width: 311.932px;
    height: 187.408px;
    background-size: cover;
    color: white;
  }
`
