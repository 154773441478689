import React, {useEffect, useState} from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import styled from "@emotion/styled";

const UpButton = () => {
  const [scrolled, setScrolled] = useState(false);
  const [scrollZero, setScrollZero] = useState(false)
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      const isScrollZero = window.scrollY < 100;
      setScrollZero(isScrollZero);
      setScrolled(isScrolled);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <ArrowButtonUp className="drop-shadow hover:text-white hover:bg-[#7C4DFF] hover:animate-pulse" id={'arrow-button-up'} onClick={scrollToTop} isScrolled={scrolled}
                   aria-label="Scroll Top Move" style={{
      cursor: scrollZero ? 'initial' : 'pointer',
    }}>
      <ExpandLessIcon/>
    </ArrowButtonUp>
  );
};

export default UpButton;

const ArrowButtonUp = styled.button<{ isScrolled: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  color: #7C4DFF;
  border-radius: 100%;
  //border: 2px solid #7C4DFF;
  z-index: 100;
  //bottom: 2%;
  bottom: ${props => props.isScrolled ? '1.9rem' : '-3rem'};
  opacity: ${props => props.isScrolled ? '1' : '0'};
  right: 1rem;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease, bottom 0.2s ease, opacity 0.3s;
`
