import React, {useEffect} from 'react';
import {TopicBtnProps} from "../../../features/types/globals.d";
import {ButtonContainer, TopicButton} from "../../../features/styledComponents/common/topic";

interface BtnProps extends TopicBtnProps {
  btnClassName?: string;
  active: string;
}

const Btn = (
  {btnData, handleSelect, goToLink, btnClassName, onLoaded, index, type}: BtnProps) => {
  let btn = [];
  useEffect(() => {
    // 버튼을 준비를 먼저 시킨다. 글고 나서 버튼바를 활성화
    onLoaded && onLoaded();
  }, []);
  for (let i = 0; i < btnData.length; i++) {
    if (btnData[i].notTopic) {
      continue
    } else {
      btn.push(
        <ButtonContainer
          key={`btnKey-${i}`} className={"btn-container"}
          index={i} indexLength={btnData.length - 1}
          type={type}
        >
          <TopicButton
            onClick={() => {
              handleSelect(btnData[i].subNavMenuId ?? 0)
              if (goToLink) {
                goToLink(btnData[i].link, btnData[i].subNavMenuId ?? 0)
              }
            }}
            title={`【${btnData[i].title}】 ページに移動`}
            id={`topic-button-${btnData[i].title}`} // seo 최적화
            active={index === i}
            className={`${btnClassName}`}>
            {btnData[i].title}
          </TopicButton>
        </ButtonContainer>
      )
    }
  }
  return (
    btn
  );
};

export default Btn
