import React from 'react';
import CommunicationContact from "../components/body/communicationContact";

const Communication = () => {
  return (
    <CommunicationContact/>
  );
};

export default Communication;
