import React, {Suspense} from 'react';
import {DecoImage} from "../../../../features/decoration/decoration";
import {DecoImages} from "../../../../features/decoration/decoImages";
import Loading from "../../../common/loading";
import useIntersectionObserver from "../../../../features/util/commonFacility/sectionsObserver";
import {
  IsVisibleBoxDivStyled,
} from "../../../../features/styledComponents/common/isVisibleBox";
import LifeStyle from "../chosenReason/lifeStyle";
import ThreeReason from "./threeReason/threeReason";
import {useWindowResizeToTablet} from "../../../../features/util/responsive/tablet";
import {useWindowResizeToMobile} from "../../../../features/util/responsive/mobile";

const ChosenReason = () => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0});
  const isTablet = useWindowResizeToTablet();
  const isMobile = useWindowResizeToMobile();
  const circleStyle = (isTablet || isMobile) ? {
    bottom: (isTablet || isMobile) ? '24%' : '',
    width: '10%'
  } : {}
  const countryStyle = (isTablet && isMobile) ? {
    bottom: (isTablet && isMobile) ? '13%' : '',
    width: '19%'
  } : {}
  const hexagonStyle = (isTablet && isMobile) ? {
    bottom: (isTablet && isMobile) ? '-6.5%' : '',
    width: '19%'
  } : {}
  return (
    <IsVisibleBoxDivStyled className={`relative overflow-hidden`}
                           isVisible={isVisible}
                           ref={innerRef}>
      <Suspense fallback={<Loading/>}>
        {isVisible ?
          <>
            <DecoImage style={{
              ...DecoImages.twoCircle,
              ...circleStyle
            }}/>
            <DecoImage style={{
              ...DecoImages.countCircle,
              ...countryStyle
            }}/>
            <DecoImage style={{
              ...DecoImages.hexagon,
              ...hexagonStyle
            }}/>
            <ThreeReason/>
            <LifeStyle/>
          </>
          : <Loading style={{height: '90rem'}}/>}
      </Suspense>
    </IsVisibleBoxDivStyled>
  );
};

export default ChosenReason;
