import React from 'react';
import {Container} from "@mui/material";
import {offeringEssentialPoint} from "../../../../../assets/data/offeringEssentialPoint";
import {DecoImage} from "../../../../features/decoration/decoration";
import {DecoImages} from "../../../../features/decoration/decoImages";
import recruitment10 from "../../../../../assets/img/recruitment/letter.svg"
import {useWindowResizeToMobile} from "../../../../features/util/responsive/mobile";
import mobileCubeIcon from "../../../../../assets/img/recruitment/mobile_recruit_cube_icon.svg";

const OfferingEssentialPoint = () => {
  const isMobile = useWindowResizeToMobile();
  return !isMobile ? (
    <section className={`py-40 relative bg-[#DFEAF4]`}>
        <div className="absolute bg-contain bg-no-repeat" style={DecoImages.recruitment2}/>
        <div className="absolute bg-contain bg-no-repeat" style={DecoImages.recruitment3}/>
        <div className="absolute bg-contain bg-no-repeat" style={DecoImages.recruitment4}/>
        <div className="absolute bg-contain bg-no-repeat" style={DecoImages.recruitment5}/>
        <div className="absolute bg-contain bg-no-repeat" style={DecoImages.recruitment6}/>
        <div className="absolute bg-contain bg-no-repeat" style={DecoImages.recruitment7}/>
        <div className="absolute bg-contain bg-no-repeat" style={DecoImages.recruitment8}/>
        <div className="absolute bg-contain bg-no-repeat" style={DecoImages.recruitment9}/>
        <div className="absolute bg-contain bg-no-repeat" style={DecoImages.recruitment11}/>
      <Container maxWidth={'md'} sx={{position: 'relative'}}>
        <div className={`h-full rounded-[0.6rem] relative flex`}>
          <div className={`flex justify-center border-[3px] border-[#1876D2] rounded-[1rem] flex-col text-[#051B4F] bg-[white] relative`}>
            <DecoImage style={DecoImages.recruitment1}/>
            <div className={`absolute w-[13rem] h-[13rem] border-[3px] border-[#05467E] rounded-full bg-[#1876D2] top-[-6%] right-[-8%] flex justify-center items-center`}>
              <img src={recruitment10} alt={''} className={`w-4/5 h-4/5`}/>
            </div>
            <div className={`text-center pt-11 px-0 pb-6 text-2xl font-semibold text-[#243142]`}>募集要項</div>
            <ol className={`pb-12 text-sm font-medium`}>
              {offeringEssentialPoint.map((data, index) => (
                <li key={index} className={`flex justify-center py-2 px-12 font-normal`}>
                  <h6 dangerouslySetInnerHTML={{__html: data.title}} className={`w-1/4 mr-7 leading-7`}/>
                  <p dangerouslySetInnerHTML={{__html: data.desc}} className={`w-2/3 leading-7`}/>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </Container>
    </section>
  ) : (
      // 下からモバイル
    <section className={`flex bg-[#DFEAF4] flex-col py-8 px-6`}>
      <div className={"flex justify-center gap-4"}>
        <img style={{width: '1.5rem'}} src={mobileCubeIcon} alt=""/>
        <h1 className={`text-[#243142] py-3 font-medium text-xl`}>募集要項</h1>
      </div>
      <div className={"bg-white flex flex-col justify-center mt-4 p-4 border-2 border-[#1876D2] rounded-[0.6rem]"}>
        {offeringEssentialPoint.map((data, index) => (
          <div key={index} className={`flex flex-col py-1.5 px-2.5`}>
            <h1 className={`text-xs text-[#3141FF] pb-0.5 font-medium`} dangerouslySetInnerHTML={{__html: data.title}}/>
            <p className={`text-sm text-[#243142] font-normal`} dangerouslySetInnerHTML={{__html: data.desc}}/>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OfferingEssentialPoint;
