import styled from "@emotion/styled";
import {Constants} from "../../constants/appConstants";

export const TopicBtnContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const TopicBtnMenu = styled.nav<{ type: number }>`
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  scrollbar-width: none;
  width: 100%;
  position: relative;
  width: ${props => {
    let value = '50rem';
    if (props.type === Constants.NAV_MENU_SERVICES) {
      value = '52.05rem'
    } else if (props.type === Constants.NAV_MENU_INFORMATION) {
      value = '35rem'
    } else if (props.type === Constants.NAV_MENU_PRODUCTION) {
      value = '25rem'
    }
    
    return value
  }};
  border-bottom: 1px solid #243142;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    //display:inline-block;
    display: flex;
    white-space: nowrap;
    width: 100%;
    padding-top: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    overflow-x: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
    //flex-wrap: nowrap;
    justify-content: space-between;
    text-align: center;
    box-shadow: 0px 1px 5px #d0d0d0;
    padding-left: 3rem;
    padding-right: 3rem;
  }
`
export const MenuMoveBar = styled.div`
  position: absolute;
  width: 0;
  top: 0;
  left: 0;
  height: 0.9rem;
  background-color: #243142;
`
export const ArrowButton = styled.button<{ isVisible: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 1.7rem;
  opacity: ${props => props.isVisible ? '1' : '0.0'};
  cursor: ${props => props.isVisible ? 'pointer' : 'initial'};
`
