import React from 'react';
import {ServicesContentsDivineStyled} from "../../../features/styledComponents/services/servicesMainStyled";
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";
import CommonServiceTypeB from "./serviceContent/commonServiceTypeB";
import ServicePartner from "./serviceContent/servicePartner";
import {serviceType} from "../../../features/types/service.d";

const ItInfrastructure = ({serviceContent}: serviceType) => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.1});
  const decoImage = <img src={serviceContent.srcServices2} alt={serviceContent.title} className={`w-9/12`}/>;
  const mainImageClassName = `
      w-[60%] w-[60%]
      md:w-[28rem] md:h-[32rem]`
  const pClassName = `
    mt-5 -mb-3 h-[3.3rem] text-[#243242] text-sm font-normal
    md:text-center md:h-[3.3rem] md:w-full md:mt-[4rem] md:mb-[1rem] md:font-medium md:max-w-sm md:text-xl`
  const partnerImageClassName = [
    'w-3/5 h-2/3 lg:w-10/12 lg:h-10/12',
    'w-5/12 h-11/12 lg:w-2/3 lg:h-2/3',
    'w-9/12 h-8/12 lg:w-full lg:h-full',
  ]

  return (
    <>
      <section className={`service-main-mold md:-mt-4 lg:mt-16 bg-[#F3E3A1]`}>
        <ServicesContentsDivineStyled ref={innerRef} isVisible={isVisible}>
          {isVisible && <CommonServiceTypeB serviceContent={serviceContent}
                                            decoImage={decoImage}
                                            mainImageClassName={mainImageClassName}
                                            pClassName={pClassName}/>}
        </ServicesContentsDivineStyled>
      </section>
      <ServicePartner serviceContent={serviceContent} isOpenPTag={false} partnerImageStyle={partnerImageClassName}/>
    </>
  );
};

export default ItInfrastructure;
