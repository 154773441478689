import React, {useEffect, useState} from 'react';
import {callData} from "../features/util/data/callData";
import BodyGroup from "../components/common/bodyGroup/bodyGroup";
import Loading from "../components/common/loading";
import {Constants} from "../features/constants/appConstants";
import {subNavMenuData} from "../../assets/data/menuData";
import {ServiceData, Tab} from "../features/types/globals.d";
import ItInfrastructure from "../components/body/services/it-infrastructure";
import Virtualization from "../components/body/services/virtualization";
import MspService from "../components/body/services/msp-service";
import SoftwareDevelopment from "../components/body/services/software-development";
import EngineerOutsourcing from "../components/body/services/engineer-outsourcing";
import ItSystemMaintenance from "../components/body/services/it-system-maintenance";
import ItSystemSales from "../components/body/services/it-system-sales";

const Services = () => {
  const [contents, setContents] = useState<ServiceData[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const tabKeys = {
    [Constants.SUB_NAV_SERVICES_IT_INFRASTRUCTURE]: ItInfrastructure,
    [Constants.SUB_NAV_SERVICES_VIRTUALIZATION]: Virtualization,
    [Constants.SUB_NAV_SERVICES_MSP_SERVICE]: MspService,
    [Constants.SUB_NAV_SERVICES_SOFTWARE_DEVELOPMENT]: SoftwareDevelopment,
    [Constants.SUB_NAV_SERVICES_ENGINEER_OUTSOURCING]: EngineerOutsourcing,
    [Constants.SUB_NAV_SERVICES_IT_SYSTEM_MAINTENANCE]: ItSystemMaintenance,
    [Constants.SUB_NAV_SERVICES_IT_SYSTEM_SALES]: ItSystemSales,
  };
  // 탭 배열 생성
  const tabs: Tab[] = Object.entries(tabKeys).map(([key, Component]) => ({
    key: Number(key),
    content: <Component serviceContent={contents[Number(key)]}/>
  }));

  // 데이터 불러오기 작업
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const url = Constants.SERVICES_DATA_URL;
      const topicData = await callData(url);
      setContents(topicData);
      setIsLoading(false)
    };
    fetchData().then();
  }, []);

  if (isLoading) return <Loading/>;
  return (
      <div className={`overflow-hidden`}>
        <BodyGroup data={subNavMenuData[Constants.NAV_MENU_SERVICES]}
                   tabs={tabs}
                   type={Constants.NAV_MENU_SERVICES}/>
      </div>
  )
    ;
};

export default Services;
