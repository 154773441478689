import React from 'react';
import useIntersectionObserver from "../../../../features/util/commonFacility/sectionsObserver";
import {DecoImage} from "../../../../features/decoration/decoration";
import {DecoImages} from "../../../../features/decoration/decoImages";
import {MeritContentData} from "../../../../features/types/globals.d";
import {useWindowResizeToMobile} from "../../../../features/util/responsive/mobile";

const StrengthsOfUs = ({data}: { data: MeritContentData[] }) => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.9});
  const isMobile = useWindowResizeToMobile()
  const styleObject = isMobile ? {
    width: isMobile ? '3rem' : '',
    height: isMobile ? '3rem' : '',
    top: isMobile ? '-1.5rem' : '',
    right: isMobile ? '-1.5rem' : '',
  } : {}
  return (
    <div className="bg-[#F7F7F7] w-full px-10 py-8
    sm:px-20
    md:px-10 md:py-32
    lg:px-20
    xl:flex xl:justify-center
    ">
      <div className="flex flex-col gap-14
      xl:max-w-5xl
      ">
        <div className="relative">
          <h2 className="text-center text-1.2rem text-[#333333] font-bold
          md:text-2xl
          ">NAMU TECH JAPANの強み</h2>
          <DecoImage className={"decoImage"} style={{
            ...DecoImages.backgroundDots,
            ...styleObject
          }}/>
        </div>
        <div className="grid grid-cols-1 gap-y-14
        md:grid-cols-2 md:gap-16
        ">
          {
            data.map((merit: MeritContentData) => (
              <div className="flex flex-col gap-5
              md:gap-10
              " key={`meritKey-${merit.title}`}>
                <div className="flex justify-center">
                  <img className="w-[5rem] h-[5rem]
                 lg:w-[6rem] lg:h-[6rem]
                 " src={merit.src} alt=""/>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex gap-3">
                    <img className="w-5
                   lg:w-6
                   " src={'/assets/about/about_us_icon.svg'} alt={''}/>
                    <h4 className="text-[#333333] font-bold
                   md:text-1.2rem
                   lg:text-2xl
                   ">{merit.title}</h4>
                  </div>
                  <div className="ml-6">
                    <ul className="list-disc">
                      {
                        merit.description.map((meritDescribe: string) => {
                          return (
                            <li className="text-sm text-[#333333]
                           md:text-base
                           " key={`meritDescribeKey-${meritDescribe}`}
                                dangerouslySetInnerHTML={{__html: `${meritDescribe}`}}>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default StrengthsOfUs;
