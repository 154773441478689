import {Constants} from "../../app/features/constants/appConstants";
import infoIcon1 from "../img/nav/info/navIconInfo1.svg"
import infoIcon2 from "../img/nav/info/navIconInfo3.svg"
import introIcon1 from "../img/nav/intro/navIconIntro1.svg"
import introIcon2 from "../img/nav/intro/navIconIntro2_.svg"
import serviceIcon1 from "../img/nav/service/navIconService1.svg"
import serviceIcon2 from "../img/nav/service/navIconService2.svg"
import productionIcon1 from "../img/nav/production/navProductionIcon1.svg"
import productionIcon2 from "../img/nav/production/navProductionIcon2.svg"
import {NavMenuData, SubNavMenuData} from "../../app/features/types/globals.d";

// 해당 키 Main Url 키를 넣기로 하기 (Main URL)
export const mainMenuKeys = {
  [Constants.URL_PATH_HOME]: Constants.NAV_MENU_HOME,
  [Constants.URL_PATH_INTRODUCE]: Constants.NAV_MENU_INTRODUCE,
  [Constants.URL_PATH_INFORMATION]: Constants.NAV_MENU_INFORMATION,
  [Constants.URL_PATH_SERVICES]: Constants.NAV_MENU_SERVICES,
  [Constants.URL_PATH_COMMUNICATION]: Constants.NAV_MENU_COMMUNICATION,
  [Constants.URL_PATH_PRIVACY_POLICY]: Constants.PRIVACY_POLICY,
  // 근로자 파견 사업의 마진율 공시 추가 -김한-
  [Constants.URL_PATH_WORKER_DISPATCHING_ACT]: Constants.WORKER_DISPATCHING_ACT,
  [Constants.URL_PATH_PRODUCTION]: Constants.NAV_MENU_PRODUCTION,
};
// mainMenuKey 에 키값을 넣기 배열로 돌리는거보단 키값으로 찾기
export const navMenuData: NavMenuData[] = [
  {title: "ホーム", link: Constants.URL_PATH_HOME, navMenuId: Constants.NAV_MENU_HOME, describe: "home", open: true},
  {
    title: "会社紹介",
    link: Constants.URL_PATH_INTRODUCE_ABOUT,
    navMenuId: Constants.NAV_MENU_INTRODUCE,
    describe: "introduce"
  },
  {
    title: "サービス",
    link: Constants.URL_PATH_SERVICES_IT_SYSTEM_INFRASTRUCTURE,
    navMenuId: Constants.NAV_MENU_SERVICES,
    describe: "services"
  },
  {
    title: "製品",
    link: Constants.URL_PATH_PRODUCTION,
    navMenuId: Constants.NAV_MENU_PRODUCTION,
    describe: "production"
  },
  {
    title: "会社情報",
    link: Constants.URL_PATH_INFORMATION_MAPS,
    navMenuId: Constants.NAV_MENU_INFORMATION,
    describe: "information"
  },
  {
    title: "お問い合わせ",
    link: Constants.URL_PATH_COMMUNICATION_CONTACT,
    navMenuId: Constants.NAV_MENU_COMMUNICATION,
    describe: "communication"
  },
]
export type SubNavMenus = {
  [key: number]: SubNavMenuData[];
};
// 배열의 0번째에 이미지소스 넣어서 그거를 바탕에 뿌리기.
export const subNavMenuData: SubNavMenus = {
  [Constants.NAV_MENU_HOME]: [
    {
      title: "ホーム",
      iconSrc1: '',
      iconSrc2: '',
      link: Constants.URL_PATH_HOME,
      describe: "home",
      navMenuId: Constants.NAV_MENU_HOME,
      navLink: Constants.URL_PATH_INTRODUCE,
      navTitle: "",
      subNav: "",
      open: true,
      tagTitle: "NAMU TECH JAPAN",
    },
  ],
  [Constants.NAV_MENU_INTRODUCE]: [
    {
      title: "私たちについて",
      iconSrc1: introIcon1,
      iconSrc2: introIcon2,
      link: Constants.URL_PATH_INTRODUCE_ABOUT,
      describe: "お客様の成功と共に成長を続けております。",
      navMenuId: Constants.NAV_MENU_INTRODUCE,
      navLink: Constants.URL_PATH_INTRODUCE,
      navTitle: "会社紹介",
      subNav: "about",
      open: false,
      tagTitle: "私たちについて | 会社紹介 | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_INTRODUCE_ABOUT,
      bannerImage: Constants.SUB_NAV_INTRODUCE_ABOUT_IMAGE_URL,
    },
    {
      title: "なぜ選ばれるのか",
      iconSrc1: introIcon1,
      iconSrc2: introIcon2,
      link: Constants.URL_PATH_INTRODUCE_MERIT,
      describe: "なぜNAMU TECH JAPANが選ばれるのか？",
      navMenuId: Constants.NAV_MENU_INTRODUCE,
      navLink: Constants.URL_PATH_INTRODUCE,
      navTitle: "会社紹介",
      subNav: "merit",
      open: false,
      tagTitle: "なぜ選ばれるのか | 会社紹介 | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_INTRODUCE_MERIT,
      bannerImage: Constants.SUB_NAV_INTRODUCE_MERIT_IMAGE_URL,
    },
    {
      title: "沿革",
      iconSrc1: introIcon1,
      iconSrc2: introIcon2,
      link: Constants.URL_PATH_INTRODUCE_HISTORY,
      describe: "沿革",
      navMenuId: Constants.NAV_MENU_INTRODUCE,
      navLink: Constants.URL_PATH_INTRODUCE,
      navTitle: "会社紹介",
      subNav: "history",
      open: false,
      tagTitle: "沿革 | 会社紹介 | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_INTRODUCE_HISTORY,
      bannerImage: Constants.SUB_NAV_INTRODUCE_HISTORY_IMAGE_URL,
    },
    {
      title: "会社概要",
      iconSrc1: introIcon1,
      iconSrc2: introIcon2,
      link: Constants.URL_PATH_INTRODUCE_INFO,
      describe: "会社概要",
      navMenuId: Constants.NAV_MENU_INTRODUCE,
      navLink: Constants.URL_PATH_INTRODUCE,
      navTitle: "会社紹介",
      subNav: "info",
      open: false,
      tagTitle: "会社概要 | 会社紹介 | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_INTRODUCE_INFO,
      bannerImage: Constants.SUB_NAV_INTRODUCE_INFO_IMAGE_URL,
    },
  ],
  [Constants.NAV_MENU_SERVICES]: [
    {
      title: "ITインフラ構築・運用",
      iconSrc1: serviceIcon1,
      iconSrc2: serviceIcon2,
      link: Constants.URL_PATH_SERVICES_IT_SYSTEM_INFRASTRUCTURE,
      describe: "ITインフラ構築・運用",
      navMenuId: Constants.NAV_MENU_SERVICES,
      navLink: Constants.URL_PATH_SERVICES,
      navTitle: "サービス",
      subNav: "it-infrastructure",
      open: false,
      tagTitle: "ITインフラ構築・運用 | サービス | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_SERVICES_IT_INFRASTRUCTURE,
      bannerImage: Constants.SUB_NAV_SERVICES_IT_INFRASTRUCTURE_IMAGE_URL,
    },
    {
      title: "仮想化構築・運用",
      iconSrc1: serviceIcon1,
      iconSrc2: serviceIcon2,
      link: Constants.URL_PATH_SERVICES_VIRTUALIZATION,
      describe: "仮想化構築・運用",
      navMenuId: Constants.NAV_MENU_SERVICES,
      navLink: Constants.URL_PATH_SERVICES,
      navTitle: "サービス",
      subNav: "virtualization",
      open: false,
      tagTitle: "仮想化構築・運用 | サービス | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_SERVICES_VIRTUALIZATION,
      bannerImage: Constants.SUB_NAV_SERVICES_VIRTUALIZATION_IMAGE_URL,
    },
    {
      title: "MSPサービス",
      iconSrc1: serviceIcon1,
      iconSrc2: serviceIcon2,
      link: Constants.URL_PATH_SERVICES_MSP_SERVICE,
      describe: "MSPサービス",
      navMenuId: Constants.NAV_MENU_SERVICES,
      navLink: Constants.URL_PATH_SERVICES,
      navTitle: "サービス",
      subNav: "msp-service",
      open: false,
      tagTitle: "MSPサービス | サービス | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_SERVICES_MSP_SERVICE,
      bannerImage: Constants.SUB_NAV_SERVICES_MSP_SERVICE_IMAGE_URL,
    },
    {
      title: "ソフトウェア開発",
      iconSrc1: serviceIcon1,
      iconSrc2: serviceIcon2,
      link: Constants.URL_PATH_SERVICES_SOFTWARE_DEVELOPMENT,
      describe: "ソフトウェア開発",
      navMenuId: Constants.NAV_MENU_SERVICES,
      navLink: Constants.URL_PATH_SERVICES,
      navTitle: "サービス",
      subNav: "software-development",
      open: false,
      tagTitle: "ソフトウェア開発 | サービス | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_SERVICES_SOFTWARE_DEVELOPMENT,
      bannerImage: Constants.SUB_NAV_SERVICES_SOFTWARE_DEVELOPMENT_IMAGE_URL,
    },
    {
      title: "IT専門人材紹介",
      iconSrc1: serviceIcon1,
      iconSrc2: serviceIcon2,
      link: Constants.URL_PATH_SERVICES_ENGINEER_OUTSOURCING,
      describe: "エンジニア案件紹介",
      navMenuId: Constants.NAV_MENU_SERVICES,
      navLink: Constants.URL_PATH_SERVICES,
      navTitle: "サービス",
      subNav: "engineer-outsourcing",
      open: false,
      tagTitle: "IT専門人材紹介 | サービス | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_SERVICES_ENGINEER_OUTSOURCING,
      bannerImage: Constants.SUB_NAV_SERVICES_ENGINEER_OUTSOURCING_IMAGE_URL,
    },
    {
      title: "IT機器の保守",
      iconSrc1: serviceIcon1,
      iconSrc2: serviceIcon2,
      link: Constants.URL_PATH_SERVICES_IT_SYSTEM_MAINTENANCE,
      describe: "IT機器の保守",
      navMenuId: Constants.NAV_MENU_SERVICES,
      navLink: Constants.URL_PATH_SERVICES,
      navTitle: "サービス",
      subNav: "it-system-maintenance",
      open: false,
      tagTitle: "IT機器の保守 | サービス | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_SERVICES_IT_SYSTEM_MAINTENANCE,
      bannerImage: Constants.SUB_NAV_SERVICES_IT_SYSTEM_MAINTENANCE_IMAGE_URL,
    },
    {
      title: "IT機器の販売",
      iconSrc1: serviceIcon1,
      iconSrc2: serviceIcon2,
      link: Constants.URL_PATH_SERVICES_IT_SYSTEM_SALES,
      describe: "IT機器の販売",
      navMenuId: Constants.NAV_MENU_SERVICES,
      navLink: Constants.URL_PATH_SERVICES,
      navTitle: "サービス",
      subNav: "it-system-sales",
      open: false,
      tagTitle: "IT機器の販売 | サービス | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_SERVICES_IT_SYSTEM_SALES,
      bannerImage: Constants.SUB_NAV_SERVICES_IT_SYSTEM_SALES_IMAGE_URL,
    },
  ],
  [Constants.NAV_MENU_INFORMATION]: [
    {
      title: "アクセス",
      iconSrc1: infoIcon1,
      iconSrc2: infoIcon2,
      link: Constants.URL_PATH_INFORMATION_MAPS,
      describe: "位置案内",
      navMenuId: Constants.NAV_MENU_INFORMATION,
      navLink: Constants.URL_PATH_INFORMATION,
      navTitle: "会社情報",
      subNav: "maps",
      open: false,
      tagTitle: "アクセス | 会社情報 | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_INFORMATION_MAPS,
      bannerImage: Constants.SUB_NAV_INFORMATION_MAPS_IMAGE_URL,
    },
    {
      title: "メッセージ",
      iconSrc1: infoIcon1,
      iconSrc2: infoIcon2,
      link: Constants.URL_PATH_INFORMATION_MESSAGES,
      describe: "技術革新でよりよい明日のために前進します",
      navMenuId: Constants.NAV_MENU_INFORMATION,
      navLink: Constants.URL_PATH_INFORMATION,
      navTitle: "会社情報",
      subNav: "messages",
      open: false,
      tagTitle: "メッセージ | 会社情報 | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_INFORMATION_MESSAGES,
      bannerImage: Constants.SUB_NAV_INFORMATION_MESSAGES_IMAGE_URL,
    },
    {
      title: "採用情報",
      iconSrc1: infoIcon1,
      iconSrc2: infoIcon2,
      link: Constants.URL_PATH_INFORMATION_EMPLOYMENT,
      describe: "採用情報についてのご案内",
      navMenuId: Constants.NAV_MENU_INFORMATION,
      navLink: Constants.URL_PATH_INFORMATION,
      navTitle: "会社情報",
      subNav: "employment",
      open: false,
      tagTitle: "採用情報 | 会社情報 | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_INFORMATION_EMPLOYMENT,
      bannerImage: Constants.SUB_NAV_INFORMATION_EMPLOYMENT_IMAGE_URL,
    },
  ],
  [Constants.NAV_MENU_COMMUNICATION]: [
    {
      title: "お問い合わせ",
      iconSrc1: '',
      iconSrc2: '',
      link: Constants.URL_PATH_COMMUNICATION_CONTACT,
      describe: "お問い合わせ",
      navMenuId: Constants.NAV_MENU_COMMUNICATION,
      navLink: Constants.URL_PATH_COMMUNICATION,
      navTitle: "会社連絡",
      subNav: "contact",
      open: false,
      tagTitle: "お問い合わせ | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_COMMUNICATION_CONTACT
    }
  ],
  [Constants.NAV_MENU_PRODUCTION]: [
    {
      title: "SKPACK",
      iconSrc1: productionIcon1,
      iconSrc2: productionIcon2,
      link: Constants.URL_PATH_PRODUCTION_SKPACK,
      describe: "製品",
      navMenuId: Constants.NAV_MENU_PRODUCTION,
      navLink: Constants.URL_PATH_PRODUCTION_SKPACK,
      navTitle: "製品",
      subNav: "skpack",
      open: false,
      tagTitle: "SKPACK | 製品 | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_PRODUCTION_SKPACK,
      bannerImage: '/assets/bannerImage/production/skpack-logo.svg',
      notTopic: true
    },
    {
      title: "ミネルバサーチ",
      iconSrc1: productionIcon1,
      iconSrc2: productionIcon2,
      link: Constants.URL_PATH_PRODUCTION_MINERVA,
      describe: "",
      navMenuId: Constants.NAV_MENU_PRODUCTION,
      navLink: Constants.URL_PATH_PRODUCTION_MINERVA,
      navTitle: "製品",
      subNav: "minerva",
      open: false,
      tagTitle: "ミネルバサーチ | 製品 | NAMU TECH JAPAN",
      subNavMenuId: Constants.SUB_NAV_PRODUCTION_MINERVA,
      bannerImage: '/assets/bannerImage/production/minerva-logo.svg',
    },
  ]
}
