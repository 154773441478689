import React from 'react';
import {Constants} from "../../../../features/constants/appConstants";
import {DecoImage} from "../../../../features/decoration/decoration";

type subNavItemsTitleProps = {
  subNavItemIndex: number
  navMenuIdKey: number
  hoverItemIndex: (i: number) => void
  subNavItem: any
  goToLink: (link: string) => void
  showHeaderSubNavBar: boolean
  subNavItemsAmount: number
}
const SubNavItemsTitle = React.memo((
  {subNavItemIndex, navMenuIdKey, hoverItemIndex, subNavItem, goToLink, subNavItemsAmount}: subNavItemsTitleProps) => {
  return (
    <li
      className={`${subNavItemsAmount > 6 ? 'py-4 px-8' : 'py-6 px-8'}
                  ${(subNavItemsAmount === 2) ? 'h-[8.43rem]' : 'h-auto'}
                  w-[19rem] m-[0.3rem] bg-white rounded-[0.6rem] relative cursor-pointer transition-all duration-200 delay-0 ease-linear hover:shadow-header-nav-hover hover:translate-x-[-0.2rem] hover:translate-y-[-0.2rem]
                   active:translate-x-0 active:translate-y-0`}
      key={`subNavItemKey-${subNavItemIndex}`}
      onMouseEnter={() => hoverItemIndex(subNavItem.subNavMenuId)}
      onClick={() => goToLink(subNavItem.link)}>
        <span className={`flex text-sm`}>
            <img src={subNavItem.iconSrc1} alt={''}/>
            <h5 className={`ml-4 font-medium text-sm`}>{subNavItem.title}</h5>
        </span>
      <DecoImage style={{
        backgroundImage: `url(${subNavItem.iconSrc2})`,
        ...navIcon2(Number(navMenuIdKey))
      }}/>
    </li>
  )
}, (prevProps, nextProps) => {
  if (prevProps.showHeaderSubNavBar !== nextProps.showHeaderSubNavBar) {
    return false
  }
  return true
});
export default SubNavItemsTitle;

function navIcon2(key: number) {
  const iconType = {
    [Constants.NAV_MENU_INTRODUCE]: {width: '4rem', height: '4rem', bottom: '-5%', right: '10%'},
    [Constants.NAV_MENU_SERVICES]: {width: '2rem', height: '2rem', bottom: '18%', right: '3%'},
    [Constants.NAV_MENU_INFORMATION]: {width: '3.5rem', height: '3.5rem', bottom: '5%', right: '10%'},
    [Constants.NAV_MENU_PRODUCTION]: {width: '4.3rem', height: '4.3rem', bottom: '-5%', right: '7%'},
  }
  return iconType[key]
}
