import React from 'react';
import {useData} from '../../../features/reducer/navReducer'
import {Constants} from "../../../features/constants/appConstants";
import {useWindowResizeToMobile} from "../../../features/util/responsive/mobile";
import {useWindowResizeToTablet} from "../../../features/util/responsive/tablet";

const BannerProductionStyle = (props: any) => {
  let resultPage = null;
  const {state} = useData();
  const subPageDesc = state.sharedData;
  const isMobile = useWindowResizeToMobile();
  const isTablet = useWindowResizeToTablet();

  if (Object.keys(subPageDesc).length > 1) {
    if (subPageDesc && ('navMenuId' in subPageDesc) && ('open' in subPageDesc) && ('subNavMenuId' in subPageDesc)) {
        if (!(subPageDesc.navMenuId === Constants.NAV_MENU_HOME ||
            (subPageDesc.navMenuId === Constants.NAV_MENU_INFORMATION && subPageDesc.open))) {
            let imgStyle = isMobile? props.bannerStyleData?.styleMobile:isTablet? props.bannerStyleData?.styleTablet:props.bannerStyleData?.style
            let titleStyle= isMobile? props.bannerStyleData?.mobileTitleStyle:props.bannerStyleData?.titleStyle
            let backgroundSrc = isMobile? props.bannerStyleData?.mobileBackgroundUrl:props.bannerStyleData?.backgroundUrl
            resultPage = (
                <div className={`flex justify-center items-center overflow-hidden flex-col-reverse bg-[#B8EDCE] h-[12.5rem] mt-14 lg:max-w-full
                                                md:w-full md:h-[19.7rem] md:pr-60 lg:h-[30rem] lg:pr-96 bg-right`}
                     style={{
                       backgroundImage: `url(${backgroundSrc})`,
                       backgroundSize: '52% auto',
                       backgroundRepeat: 'no-repeat',
                       backgroundPosition: 'right',
                     }}>
                    <div className="flex flex-col justify-start pl-16 pt-1 md:pr-16 lg:pr-24">
                        <div className="flex justify-start text-end -mt-14 md:-mt-12 lg:-mt-12">
                            <img style={imgStyle} src={props.bannerStyleData?.imageUrl}/>
                        </div>
                        <div className="lg:-mt-4 md:-mt-10 text-center text-2xl font-bold md:text-left md:text-2xl lg:text-3xl"
                             style={titleStyle}>{props.content?.title}</div>
                        {!isMobile &&
                            <div className={"text-left font-semibold mt-4 md:text-sm"}>
                                会社内で使用しているクラウドサービス<br/>
                                かつファイルサーバ内で全文検索できない。<br/>
                                電子ファイルを「ミネルバサーチ」を<br/>
                                通して全データをインデックス処理し、<br/>
                                テキスト全文検索が可能とします。<br/>
                            </div>
                        }
                    </div>

                </div>
            )
        }
    }
  }

    return resultPage;
};

export default BannerProductionStyle;
