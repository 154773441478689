import React, {useState} from 'react';
import Tabs from "./tabs";
import Btn from "./btn";
import {TopicBtnProps} from "../../../features/types/globals.d";
import {TopicBtnMenu} from "../../../features/styledComponents/common/topicTab";
import {useWindowResizeToTablet} from "../../../features/util/responsive/tablet";
import {useWindowResizeToMobile} from "../../../features/util/responsive/mobile";

const TopicBtn = ({children, btnData, index, handleSelect, goToLink, type, tabsAmount}: TopicBtnProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const isTablet = useWindowResizeToTablet();
  const isMobile = useWindowResizeToMobile();
  const btn: React.JSX.Element =
    <Btn btnData={btnData} index={index}
         handleSelect={handleSelect} goToLink={goToLink} onLoaded={() => setIsLoaded(true)}
         btnClassName={`topic-button`} active={"active"} type={type}/>
  return (
    <div>
      {(!isTablet && !isMobile) && (((tabsAmount ?? 0) >= 2) &&
        <Tabs
          btnContainer={TopicBtnMenu}
          className={'banner-nav'}
          isLoaded={isLoaded}
          buttons={btn}
          type={type}
        />)}
      {children}
    </div>
  );
};

export default TopicBtn;
