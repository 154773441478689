import React, {useEffect, useState} from 'react';
import {callData} from "../../../features/util/data/callData";
import {Constants} from "../../../features/constants/appConstants";
import {CompanyInfoData} from "../../../features/types/globals.d";
import {DecoImage} from "../../../features/decoration/decoration";
import {DecoImages} from "../../../features/decoration/decoImages";
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";
import {useWindowResizeToTablet} from "../../../features/util/responsive/tablet";
import titleCube from "../../../../assets/img/info/information_cube2.svg";
import {useWindowResizeToMobile} from "../../../features/util/responsive/mobile";
import {IsVisibleBoxDivStyled} from "../../../features/styledComponents/common/isVisibleBox";

const Overview = () => {
  const [data, setData] = useState<CompanyInfoData | null>(null);
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0});
  const isTablet = useWindowResizeToTablet();
  const isMobile = useWindowResizeToMobile();

  // 데이터 불러오기 작업
  useEffect(() => {
    const fetchData = async () => {
      const url = Constants.CORPORATE_INFO_DATA_URL;
      const topicData = await callData(url);
      setData(topicData);
    };
    fetchData().then();
  }, []);
  return (
    <IsVisibleBoxDivStyled className="bg-[#DED0FF] w-full relative overflow-hidden
    lg:bg-[#B9A0FF] lg:mt-24
    " ref={innerRef} isVisible={isVisible}>
      <div className="px-6 py-16
      lg:py-[13rem]
      ">
        {(!isTablet && !isMobile) &&
          <>
            <DecoImage style={{...DecoImages.infoBattery,}}/>
            <DecoImage style={{...DecoImages.infoCube1,}}/>
            <DecoImage style={{...DecoImages.infoCube2,}}/>
            <DecoImage style={{...DecoImages.infoCube3,}}/>
            <DecoImage style={{...DecoImages.infoCube4,}}/>
            <DecoImage style={{...DecoImages.infoCube5,}}/>
            <DecoImage style={{...DecoImages.infoCube6,}}/>
            <DecoImage style={{...DecoImages.infoGrid1,}}/>
            <DecoImage style={{...DecoImages.infoGrid2,}}/>
          </>}
        <div className="flex flex-col items-center gap-10">
          <div className="flex flex-row gap-4
          lg:hidden
          ">
            <img className="w-8 h-8
            sm:w-12 sm:h-12
            " src={titleCube} alt=""/>
            <img className="w-24
            sm:w-28
            " src={Constants.MAIN_PURPLE_LOGO} alt=""/>
          </div>
          <div className="flex flex-col gap-3">
            {data && (isMobile || isTablet) && Object.entries(data).map((data, i) => (
              <div key={`corporateInfoKeys-${i}`}>
                <div className="text-[#9931FF] font-bold text-sm
                sm:text-base
                ">{data[0]}</div>
                <ol className="">
                  {
                    Array.isArray(data[1])
                      ? data[1].map((item, index) => (
                        <li key={index} className="ml-6 list-decimal">
                          {item}
                        </li>
                      ))
                      : <li className="list-none">{data[1]}</li>
                  }
                </ol>
              </div>
            ))}
            {data && (!isTablet && !isMobile) &&
                <div className="relative bg-white rounded-2xl pt-6 pb-12 px-8">
                  <div className="flex justify-end mb-4">
                    <img src={Constants.MAIN_LOGO} className="h-7"/>
                  </div>
                  <table className="border-separate border-spacing-4">
                    <tbody>
                    {Object.entries(data).map((info) => (
                      <tr key={info[0]}>
                        <th className="align-text-top text-right text-[#243142] pr-2">{info[0]}</th>
                        {
                          Array.isArray(info[1]) ?
                            (<td>
                              <ul>
                                {info[1].map((item, index) => (
                                  <li key={index} className="mb-1">{item}</li>
                                ))}
                              </ul>
                            </td>) :
                            (<td>{info[1]}</td>)
                        }
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>}
          </div>
        </div>
      </div>
      <DecoImage className="lg:hidden" style={{...DecoImages.infoBattery, width: '1.5rem', bottom: '-2%'}}/>
    </IsVisibleBoxDivStyled>
  );
};

// @ts-ignore
export default Overview;
