import React from 'react';
import {RecordItem} from "../../../../../features/types/history.d";
import Box from "@mui/material/Box";
import HistoryContentsDivine from "./historyContentsDivine";

const HistoryBlockContentsMobile = ({historyData, color, activeYearId}: {
  historyData: RecordItem[] | undefined | null,
  color: string[],
  activeYearId: number,
}) => {

  return (
    <Box id={'NamuTech-Korea-history'}>
      {
        historyData?.map((RecordItem: RecordItem, index) => {
            return (
              <React.Fragment key={`koreaHistoryKey-${index}`}>
                <HistoryContentsDivine RecordItem={RecordItem} color={color[index % 4]} activeYearId={activeYearId}/>
              </React.Fragment>
            )
          }
        )
      }
    </Box>
  );
};

export default HistoryBlockContentsMobile;
