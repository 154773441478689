import styled from "@emotion/styled";

interface ExtraStyleForImage {
  extraStyle?: string
}

export const DecoImage = styled.div<ExtraStyleForImage>`
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  //  기본 10%
  width: 10%;
  height: 10%;
  ${(props) => props.extraStyle};
`;
