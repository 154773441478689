import React from 'react';
import {DecoImage} from "../../../../../features/decoration/decoration";
import imgSys from '../../../../../../assets/img/minerva/solutionService/minerva_system_image.png'
import styles from './solutionService.module.scss'
import webBackGround from '../../../../../../assets/img/minerva/solutionService/minerva_bg1.png';
import {useWindowResizeToMobile} from "../../../../../features/util/responsive/mobile";
import useIntersectionObserver from "../../../../../features/util/commonFacility/sectionsObserver";
import {IsVisibleBoxSectionStyled} from "../../../../../features/styledComponents/common/isVisibleBox";

const SolutionService = () => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.2});
  const isMobile = useWindowResizeToMobile();

  return (
      <IsVisibleBoxSectionStyled
          className={`flex flex-col items-center text-sm my-12 w-full text-[#243242] relative md:text-base lg:max-w-screen-xl lg:mx-auto`}
          ref={innerRef} isVisible={isVisible}
      >

          {/* Start Mobile Area ----------------------------------------------------------------------------------------- */}
          {isMobile &&
              <>
                  {/*　First Area */}
                  <div className={`w-full mt-4 px-6`}>
                      <div className={`relative ${styles.solution_service_bluebg}`}>
                          <div className={`relative flex flex-col items-center border border-[#A7BFFF] p-4 rounded-lg mb-4 bg-white`}>
                              <h3 className={`text-base font-semibold mb-3`}>システム概要</h3>
                              <ul className={`list-none w-11/12 mb-16 space-y-1`}>
                                  <li>会社内で使用しているクラウドサービスかつファイルサーバ内で全文検索できない。<br/>
                                      子ファイルを「ミネルバサーチ」を通して全データをインデックス処理し、テキスト全文検索が可能とします。
                                  </li>
                              </ul>

                              <h3 className={`text-base font-semibold mb-3`}>現在各会社の共有データについて課題</h3>
                              <ul className={`list-disc w-10/12 space-y-1`}>
                                  <li>探す場所とデータ増えて、欲しい情報を手に入れるのに時間がかかる。</li>
                                  <li>部門やチームのナレッジ共有ができていない。</li>
                                  <li>同じ資料がいくつかある。</li>
                                  <li>検索機能の使い勝手が悪く、欲しい情報が見つかりにくい。</li>
                                  <li>テレワークの影響で人に気軽に聞けなくなった。</li>
                                  <li>各システムが統合されていないため纏めて検索ができない。</li>
                              </ul>
                          </div>
                      </div>
                  </div>

                  {/*　Second Area */}
                  <div className={`w-full mt-4 px-6`}>
                      <div className={`relative ${styles.solution_service_greenbg}`}>
                          <div className={`relative flex flex-col items-center border border-[#A7E1FF] p-4 rounded-lg mb-4 bg-white`}>
                              <h3 className={`text-base font-semibold mb-3`}>システム構成</h3>
                              <img src={imgSys} alt="システム構築イメージ"/>
                              <ul className={`list-disc w-10/12 space-y-1`}>
                                  <li>ファイルサーバとBOXクラウドのドキュメント内容を全文検索</li>
                                  <li>お客様社内のオンプレミス環境構築又はクラウド環境構築</li>
                                  <li>画像内のテキスト内容も検索可能</li>
                                  <li>ドキュメントビューア機能</li>
                                  <li>検索キーワードの刑能素解析機能</li>
                                  <li>属性絞り込み機能</li>
                                  <li>AIを活用した検索菅れキーワード</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </>}
          {/* End Mobile Area   ----------------------------------------------------------------------------------------- */}

          {/* Start PC Area     ----------------------------------------------------------------------------------------- */}
            {!isMobile &&
              <div className="w-full h-full relative">
                  <div className="flex flex-col items-center md:px-10">
                      <article className={`w-full h-10 flex justify-center`}>
                      </article>

                      {/*　First Area */}
                      <article className={`w-full h-full flex justify-center`}>
                          <div className={`relative w-6/12 p-6 min-w-[43.6rem]`}>
                              <div className={`rounded-3xl px-6 py-12 bg-white flex flex-col justify-center shadow-custom-shadow`}>
                                  <h3 className={`text-center text-xl font-bold mb-9`}>現在各会社の共有データについて課題</h3>
                                  <ul className={`list-decimal gab-5 pl-10 space-y-4`}>
                                      <li>探す場所とデータ増えて、欲しい情報を手に入れるのに時間がかかる。</li>
                                      <li>部門やチームのナレッジ共有ができていない。</li>
                                      <li>同じ資料がいくつかある。</li>
                                      <li>検索機能の使い勝手が悪く、欲しい情報が見つかりにくい。</li>
                                      <li>テレワークの影響で人に気軽に聞けなくなった。</li>
                                      <li>各システムが統合されていないため纏めて検索ができない。</li>
                                  </ul>
                              </div>
                          </div>
                      </article>

                    <div style={{zIndex: '-1', position: 'absolute'}}>
                      <img src={webBackGround} alt=""/>
                    </div>
                      {/*　Second Area */}
                      <article className={`w-full h-full flex justify-center`}>
                          <div className={`relative w-6/12 p-6 min-w-[43.6rem]`}>
                              <div className={`rounded-3xl px-6 py-12 bg-white flex flex-col justify-center shadow-custom-shadow`}>
                                  <h3 className={`text-center text-xl font-bold mb-4`}>システム構成</h3>
                                  <img src={imgSys} alt="システム構築イメージ"/>
                                  <ul className={`list-disc pt-10 pl-10 space-y-4`}>
                                      <li>ファイルサーバとBOXクラウドのドキュメント内容を全文検索</li>
                                      <li>お客様社内のオンプレミス環境構築又はクラウド環境構築</li>
                                      <li>画像内のテキスト内容も検索可能</li>
                                      <li>ドキュメントビューア機能</li>
                                      <li>検索キーワードの刑能素解析機能</li>
                                      <li>属性絞り込み機能</li>
                                      <li>AIを活用した検索菅れキーワード</li>
                                  </ul>
                              </div>
                          </div>
                      </article>
                  </div>
              </div>}
          {/* End PC Area       ----------------------------------------------------------------------------------------- */}
      </IsVisibleBoxSectionStyled>
  );
};

export default SolutionService;
