import React, {Suspense, useState} from 'react';
import {
  ContentsBoxStyled,
  OverviewDivineContentsStyled, OverviewSectionStyled
} from "../../../../../features/styledComponents/introduce/chosenReason";
import Loading from "../../../../common/loading";
import namuBuilding from "../../../../../../assets/img/peopleLoveUs/namu_building.png";
import mobileNamuBuilding from "../../../../../../assets/img/peopleLoveUs/mobile_namu_building.png";
import squareCircle from "../../../../../../assets/img/peopleLoveUs/squareCircle.svg";
import oval from "../../../../../../assets/img/peopleLoveUs/oval.svg";
import rope from "../../../../../../assets/img/peopleLoveUs/rope.svg";
import threeReasonCss from './threeReason.module.scss';

const LazyImage = React.lazy(() => import('../../../../common/lazyImg'));
const ThreeReason = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="w-full bg-[#F7F7F7] flex flex-col items-center
    lg:mt-24
    ">
      <div className="flex flex-col items-center gap-8 py-8 px-6
      sm:max-w-2xl
      md:mb-10
      lg:max-w-full lg:flex-row lg:py-12
      xl:gap-40
      ">
        <div className="text-sm text-yellow-500 font-semibold leading-loose
        md:text-lg md:mt-10 md:-mb-10
        lg:hidden
        ">
          “なぜNAMU TECH JAPANが選ばれるのか？”
            {/*<br/>お客様の満足を第一の目標として働き続けます。*/}
        </div>
        <Suspense fallback={<Loading styleClass={`h-1/2`}/>}>
          <picture>
            <source media="(max-width: 768px)" srcSet={mobileNamuBuilding}/>
            <img src={namuBuilding} alt="namu-building" className="lg:pb-12 max-w-md w-full h-full" onLoad={() => setIsLoading(false)}/>
          </picture>
            <div className={`flex flex-col gap-8 ${isLoading ? 'opacity-0' : 'opacity-100'}
        max-w-[30.5rem]
        lg:max-w-lg
        `}>
                <h3 className="text-1.2rem text-yellow-500 font-bold leading-loos hidden
          lg:block
          ">
                    “なぜNAMU TECH JAPANが選ばれるのか？”
                    {/*<br/>お客様の満足を第一とし、お客様に選ばれる企業を目指します。”*/}
                </h3>
                <div className={threeReasonCss.detail_box}>
                    <div>
                        <img src={squareCircle} alt={''}/><h4>コンサルティング</h4>
                    </div>
                    <p className="leading-relaxed">お客様の立場に寄り添ったコンサルティングサービスのご提供</p>
                </div>
                <div className={threeReasonCss.detail_box}>
                    <div>
                        <img src={oval} alt={''}/><h4>設計、構築</h4>
                    </div>
                    <p className="leading-relaxed">運用の安定性・効率化を踏まえたITインフラ設計と構築</p>
                </div>
                <div className={threeReasonCss.detail_box}>
                    <div>
                        <img src={rope} alt={''}/><h4>保守、運用</h4>
                    </div>
                    <p className="leading-relaxed">これまでに手がけてきた数多くのシステム運用実績に基づく、信頼性の高い運用管理のご提案し、
                        高品質なサービスのご提供</p>
                </div>
                <div className={threeReasonCss.detail_box}>
                    <h4 className="font-bold text-blue-950 leading-relaxed">お客様の満足を最優先とし、お客様に選ばれる企業を目指しています。</h4>
                </div>
            </div>
        </Suspense>
      </div>
    </div>
  );
};

export default ThreeReason;
