import React, {Suspense} from 'react';
import {Constants} from "../../../../../features/constants/appConstants";
import {RecordItemKey, YearlyRecord} from "../../../../../features/types/history.d";
import {
  HistoryDataView,
} from "../../../../../features/styledComponents/introduce/history/historyContents";
import {SelectedHistoryActive} from "../../../../../features/styledComponents/introduce/history/historyFrames";
import useIntersectionObserver from "../../../../../features/util/commonFacility/sectionsObserver";
import Loading from "../../../../common/loading";
import {DecoImage} from "../../../../../features/decoration/decoration";
import {DecoImages} from "../../../../../features/decoration/decoImages";
import HistoryBlockContent from "../desktop/historyBlockContentsDesktop";

type HistoryProps = {
  history: RecordItemKey
  color: string;
  selectedYear: boolean;
  yearName: string;
}
export type historyContentProps = {
  historyData: any,
  color: string,
  year: string,
  yearName: string
}
const HistoryBlock = ({history, color, selectedYear, yearName}: HistoryProps) => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.3});
  const yearRecord = history[Constants.CHECK_HISTORY_ID_FOR_SECTION];
  let id = '';
  let year = '';

  function isYearlyRecordId(object: any): object is YearlyRecord {
    return 'id' in object;
  }

  function isYearlyRecordYear(object: any): object is YearlyRecord {
    return 'year' in object;
  }

  if (isYearlyRecordId(yearRecord)) {
    id = yearRecord.id?.toString() ?? '';
  }
  if (isYearlyRecordYear(yearRecord)) {
    year = yearRecord.year.toString();
  }
  return (
    <HistoryDataView className={`history_view_data ${id ? 'goToLink' : ''}`}
                     color={color}
                     ref={innerRef} isVisible={isVisible}
                     id={year ?? ''}
                     targetHistoryYear={selectedYear}>
      <Suspense fallback={<Loading/>}>
        {/* deco 빈 공간 채우는 용도 */}
        {(year === '2004') && <DecoImage style={DecoImages.namutechJapan}/>}
        {/* 해당 년도 구간에 반짝 거리는 효과 */}
        {selectedYear && <SelectedHistoryActive color={color} targetHistoryYear={selectedYear}/>}
        {history.slice(1).map((historyData, htdIndex: number) => {
          if ('type' in historyData) {
            return (
              <React.Fragment key={`historyContentsKey-${htdIndex}`}>
                <HistoryBlockContent historyData={historyData}
                                     color={color}
                                     year={year}
                                     yearName={yearName}/>
              </React.Fragment>
            )
          } else {
            return null
          }
        })}
      </Suspense>
    </HistoryDataView>
  )
};

export default React.memo(HistoryBlock, (prevProps, nextProps) => {
  if (prevProps.selectedYear !== nextProps.selectedYear) {
    return false
  }
  return true
});
