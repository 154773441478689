import React from 'react';
import {NavLink} from "react-router-dom";
import {Constants} from "../../features/constants/appConstants";

const Footer = () => {
  return (
    <footer className="bg-[#243142] text-white pt-10 md:pt-20 px-6 flex flex-col items-center overflow-hidden
    md:px-12
    ">
      <div className="grid grid-cols-12 gap-y-8 pb-10 max-w-lg border-b
      md:grid-cols-2 md:max-w-2xl md:gap-x-16
      lg:max-w-4xl
      xl:max-w-screen-xl
      ">
        <div className="col-span-12
        xl:col-span-4 xl:col-start-1
        ">
          <img src="/assets/logo/white_namu_logo.svg" alt="" className="w-28 mb-6"/>
          <p
            className="leading-6 text-home-gray">サーバーの構築、運用、仮想化からクラウド、DevOps、ソフトウェア開発まで幅広く対応可能なワンストップソリューション企業です。</p>
        </div>
        <div className="grid grid-cols-12 col-span-12 gap-x-8
        md:col-span-6
        xl:col-span-4 xl:col-start-5
        ">
          <div className="col-span-6">
            <h2 className="text-1.2rem font-bold mb-3">Top Menu</h2>
            <ul className="leading-8 text-home-gray">
              <li><NavLink to={Constants.URL_PATH_HOME}>ホーム</NavLink></li>
              <li><NavLink to={Constants.URL_PATH_INTRODUCE_ABOUT}>会社紹介</NavLink></li>
              <li><NavLink to={Constants.URL_PATH_SERVICES}>サービス</NavLink></li>
              <li><a href={Constants.URL_PATH_PRODUCTION_SKPACK} target="_blank" rel="noopener noreferrer">製品 ‐ SK PACK</a></li>
              <li><NavLink to={Constants.URL_PATH_PRODUCTION_MINERVA}>製品 ‐ MINERVA</NavLink></li>
              <li><NavLink to={Constants.URL_PATH_INTRODUCE_INFO}>会社情報</NavLink></li>
              <li><NavLink to={Constants.URL_PATH_COMMUNICATION_CONTACT}>お問い合わせ</NavLink></li>
            </ul>
          </div>
          <div className="col-span-6">
            <h2 className="text-1.2rem font-bold mb-3">Company</h2>
            <ul className="leading-8 text-home-gray">
              <li><NavLink to={Constants.URL_PATH_INFORMATION_MAPS}>アクセス</NavLink></li>
              <li><NavLink to={Constants.URL_PATH_INFORMATION_MESSAGES}>メッセージ</NavLink></li>
              <li><NavLink to={Constants.URL_PATH_INFORMATION_EMPLOYMENT}>採用情報</NavLink></li>
              <li><NavLink to={Constants.URL_PATH_PRIVACY_POLICY}>個人情報保護方針</NavLink></li>
              {/*// 근로자 파견 사업의 마진율 공시 추가 -김한-*/}
              <li><NavLink to={Constants.URL_PATH_WORKER_DISPATCHING_ACT}>マージン率の公開</NavLink></li>
            </ul>
          </div>
        </div>
        <div className="col-span-12
        md:col-span-6
        xl:col-span-4 xl:col-start-9
        ">
          <h2 className="text-1.2rem font-bold mb-3">Contact Us</h2>
          <div className="text-home-gray leading-8">
            <p>〒113-0033</p>
            <p>東京都文京区本郷1-20-3 中山ビル5階</p>
            <p><a href="tel:+81362400471">Tel: 03-6240-0471</a></p>
            <p>Fax: 03-6240-0472</p>
            <p><a href="mailto:info@namutech.co.jp">info@namutech.co.jp</a></p>
          </div>
        </div>
      </div>
      {/* Copyright */}
      <div className="py-10 w-full text-center">
        <p className="text-sm">&copy; NAMU TECH JAPAN 株式会社 All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
