import React, {useEffect, useState} from 'react';
import {DataButton} from "../../../features/styledComponents/common/Button";
import {useGoToLink} from "../../../features/util/commonFacility/goToLink";
import Loading from "../../common/loading";
import useIntersectionObserver from "../../../features/util/commonFacility/sectionsObserver";
import {ServiceData} from "../../../features/types/globals.d";
import {Constants} from "../../../features/constants/appConstants";
import {callData} from "../../../features/util/data/callData";
import {servicesDataProps} from "../../../features/types/service.d";

const ServiceBlock = () => {
  const goToLink = useGoToLink();
  const [service, setService] = useState<ServiceData[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);

  // 데이터 불러오기 작업
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = Constants.SERVICES_DATA_URL;
      const topicData = await callData(url);
      setService(topicData);
      setIsLoading(false);
    };
    fetchData().then();
  }, []);

  if (isLoading) return <Loading/>;
  return (
    <div className="main-service-bg pt-16 pb-16 px-5 lg:px-10">
      <div className="flex flex-col items-center">
        <h1 className="font-semibold text-xl text-[#243142] mb-6 lg:text-3xl">サービス</h1>
        <p
          className="font-normal text-1rem px-4 text-[#243142]
          sm:px-16
">弊社は、お客様の満足度を100%に向けて努め、最適なITソリューションを提供いたします。</p>
      </div>
      <div className="flex flex-col gap-16 mt-10 lg:gap-32 lg:items-center">
        {
          service.map((ServiceData, serviceNumber) =>
            (
              <div className="flex justify-center px-4 text-[#243142]" key={ServiceData.title}>
                <ServiceHomeContents
                  contents={service}
                  isOdd={serviceNumber % 2 !== 1}
                  serviceNumber={serviceNumber}
                  goToLink={goToLink}/>
              </div>
            )
          )
        }
      </div>
    </div>
  );
};
export default ServiceBlock;

export type serviceHomeContentsProps = servicesDataProps & {
  isOdd?: boolean;
  goToLink: (link: string) => void
}
const ServiceHomeContents = ({isOdd, contents, serviceNumber, goToLink}: serviceHomeContentsProps) => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.1});

  return (
    <div className={`flex flex-col gap-6 max-w-sm w-full transition duration-500 ease-in
     lg:max-w-5xl lg:gap-16 
      ${isVisible ? 'opacity-100 translate-x-0 translate-y-0' : 'opacity-0 translate-y-[-3%]'} ${isOdd ? 'lg:flex-row-reverse' : 'lg:flex-row'}`}
         ref={innerRef}>
      <div className="flex justify-center lg:w-1/2">
        {isVisible &&
          <img className="w-full" src={contents[serviceNumber].srcHome} alt={contents[serviceNumber].title}/>}
      </div>
      <div className="flex flex-col gap-3
             lg:w-1/2 lg:justify-center">
        {
          isVisible &&
          <>
            <h2
              className="text-center text-1.2rem font-medium lg:text-left lg:text-3xl">{contents[serviceNumber].title}</h2>
            <p dangerouslySetInnerHTML={{__html: contents[serviceNumber].descHome}}></p>
            <div className="flex justify-center lg:justify-start">
              <DataButton onClick={() => goToLink(contents[serviceNumber].link)}>詳細はこちら</DataButton>
            </div>
          </>
        }
      </div>
    </div>
  );
};
