import React, {useContext, useEffect, useRef, useState} from 'react';
import Loading from "../../../common/loading";
import {callData} from "../../../../features/util/data/callData";
import {Constants} from "../../../../features/constants/appConstants";
import {FromYearToYear, RecordArray, RecordItem} from "../../../../features/types/history.d";
import {throttle} from "lodash";
import HistoryBlock from "./desktop/historyBlock";
import {useWindowResizeToMobile} from "../../../../features/util/responsive/mobile";
import HistoryBlockContentsMobile from "./mobile/historyBlockContentsMobile";
import {DataContext} from "../../../../features/reducer/navReducer";
import {
  historyKoreaOrJapanDivine
} from "../../../../features/decoration/historyDecoForDivineCircle";
import useIntersectionObserver from "../../../../features/util/commonFacility/sectionsObserver";

const History = () => {
  const [data, setData] = useState<RecordArray | []>([]);
  const [historyNavMenuBar, setHistoryNavMenuBarPositionUpdate] = useState<boolean>(false)
  const [lastScrollY, setLastScrollY] = useState<number>(0);
  const [headNavComeDown, setHeadNavComeDown] = useState<boolean>(false)
  const [activeId, setActiveId] = useState<number>(0);
  const [activeYearId, setActiveYearId] = useState<number>(0);
  const color = ['#3199FF', '#3E4FBD', '#6736B8', '#A220B5']
  const isMobile = useWindowResizeToMobile();
  const olRef = useRef<HTMLOListElement | null>(null) // 버튼들의 틀
  const {dispatch} = useContext(DataContext);
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0});

  const handleScroll = throttle(() => {
    const currentPosition = window.scrollY;
    // 섹션 별 아이디 모으기 작업
    const sections = document.querySelectorAll('.goToLink');
    const yearSections = document.querySelectorAll('.history_view_data')
    let currentSectionId = Constants.WHITE_SPACE;
    let currentYearSectionsId = Constants.WHITE_SPACE;
    sections.forEach((section: Element) => {
      const sectionTop = (section as HTMLElement).offsetTop;
      if (window.scrollY >= sectionTop) {
        currentSectionId = (section.getAttribute(Constants.ID) ?? Constants.WHITE_SPACE);
      }
    });
    yearSections.forEach((yearSection) => {
      const sectionTop = (yearSection as HTMLElement).offsetTop;
      if (window.scrollY >= sectionTop - Constants.SECTION_SCROLL_THRESHOLD_150) {
        currentYearSectionsId = (yearSection.getAttribute(Constants.ID) ?? Constants.WHITE_SPACE);
      }
    })
    if (olRef.current) {
      const targetElement = olRef.current.querySelector(`.\\3${currentSectionId[0]} ${currentSectionId.substring(1)}`) as HTMLElement;
      if (targetElement) {
        if (Number(currentSectionId) !== activeId) {

          // 스크롤 컨테이너의 중앙에 요소를 위치시키기 위한 계산
          const scrollPosition = targetElement.offsetLeft + (targetElement.offsetWidth / 2) - (olRef.current.clientWidth / 2);
          olRef.current.scrollTo({
            left: scrollPosition,
            behavior: 'smooth'
          });
        }
      }
    }
    setActiveId(Number(currentSectionId)); // 섹션 별 아이디를 모은다.
    setActiveYearId(Number(currentYearSectionsId)); // 섹션 년도 별 아이디를 모은다.
    setLastScrollY(currentPosition) // 이걸로 헤더바가 내려오면 위로 올리는 역할을 수행한다.
    // 스크롤바 450 OR 350 보다 크면 바의 포지션을 변경 모바일일때는 350
    if (currentPosition > (!isMobile ? Constants.SECTION_SCROLL_THRESHOLD_450 : Constants.SECTION_SCROLL_THRESHOLD_350)) {
      setHistoryNavMenuBarPositionUpdate(true)
    } else {
      setHistoryNavMenuBarPositionUpdate(false)
    }

    if (!isMobile) {
      if (currentPosition > lastScrollY) {
        setHeadNavComeDown(true)
      } else {
        setHeadNavComeDown(false)
      }
    } else {
      if (currentPosition > Constants.SECTION_SCROLL_THRESHOLD_350) {
        setHeadNavComeDown(true)
        dispatch && dispatch({type: Constants.SET_MOBILE_HISTORY, historyHeaderForMobile: true})
      } else {
        setHeadNavComeDown(false)
        dispatch && dispatch({type: Constants.SET_MOBILE_HISTORY, historyHeaderForMobile: false})
      }
    }

  }, 400)

  const handleClick = (elementId: string) => {
    scrollToElementById(elementId);
  };

  function scrollToElementById(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
      setTimeout(() => {
        setActiveId(Number(elementId)); // elementId를 기반으로 상태 업데이트
      }, 1000);
    }
  }

  // 데이터 불러오기 작업
  useEffect(() => {
    const fetchData = async () => {
      const url = Constants.CORPORATE_HISTORY_DATA_URL;
      const topicData = await callData(url);
      setData(topicData);
    };
    fetchData().then();
  }, []);

  useEffect(() => {
    // 스크롤 이벤트 리스너 추가
    window.addEventListener('scroll', handleScroll);
    // 컴포넌트가 언마운트될 때 스크롤 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (!isMobile) {
        dispatch && dispatch({type: Constants.SET_MOBILE_HISTORY, historyHeaderForMobile: false})
      }
    };
  }, [lastScrollY]);

  return (
    <div ref={innerRef}
         className={`pb-8 h-full
                   md:pb-40`}>
      {!isMobile &&
        <>
          <div className={`mt-24 mb-20 px-0 sticky ${historyNavMenuBar ? (headNavComeDown ? 'top-3' : 'top-16') : 'top-3'} z-50 transition-all ease duration-500`} style={{height: 'auto',position: '-webkit-sticky'}}>
            <menu className={'HeaderNotComeDown flex justify-center'}>
              <ol
                  className={`my-0 m-auto flex text-center w-full rounded-[2rem] shadow-sm z-20 bg-[#F7F9FC] border border-[#CDD8E7]
                        lg:w-[70%] `}>
                {
                  data[1]?.map((navbar: FromYearToYear) => {
                    return (
                      <li key={`historyNavMenuBarKey-${navbar.link}`}
                          className={`w-11/12 py-4 px-0 font-bold`}>
                        <a
                            className={`rounded-2xl py-1.5 px-2.5 text-sm h-full transition ease-in duration-300 delay-75 ${activeId === navbar.link ? 'text-white bg-[#7C4DFF]' : 'text-black bg-transparent'}`}
                            href={`#${navbar.link}`}>
                          {navbar.date}
                        </a>
                      </li>
                    )
                  })
                }
              </ol>
            </menu>
          </div>
          {historyKoreaOrJapanDivine()}
          <div className={`overflow-hidden`}>
            {
              data.length > 0 ?
                data[0]?.map((history: RecordItem, index: number) => {
                  const yearName = history[0].yearName
                  const selected = history[0].year === activeYearId
                  return (
                      <React.Fragment key={`historyKey-${yearName}`}>
                        <HistoryBlock
                            history={history}
                            selectedYear={selected}
                            color={color[index % 4]}
                            yearName={yearName}/>
                      </React.Fragment>
                  )
                }) : <Loading/>
            }
          </div>
        </>
      }
      {(isMobile &&
        <>
          {/*<menu className={`${historyNavMenuBar ? 'fixed' : 'static'}*/}
          {/*   ${historyNavMenuBar && headNavComeDown && 'top-[3.5rem]'}*/}
          {/*   ${headNavComeDown ? 'shadow-md' : 'shadow-none'}*/}
          {/*    pb-4 flex justify-center z-50 bg-white m-0 w-full h-[3.5rem] transition ease-in duration-300 delay-75`}>*/}
          {/*  {FramingHistoryMobile()}*/}
          {/*  <ol ref={olRef}*/}
          {/*      className={`HistoryNavBarGroupForMobile flex-nowrap overflow-x-auto overflow-y-hidden whitespace-nowrap w-full hide-scrollbar flex transition ease-in duration-500 delay-75 shadow-none h-[2.7rem] my-0 mx-auto text-center rounded-[2rem] snap-x-mandatory`}>*/}
          {/*    <li className={`z-10 min-w-[33.333333333%] p-1 bg-[#F1F3F7] snap-start`}/>*/}
          {/*    {*/}
          {/*      data.length > 0 ?*/}
          {/*        data[1]?.map((navbar: FromYearToYear) => {*/}
          {/*          return (*/}
          {/*            <li key={`historyNavMenuBarKey-${navbar.link}`}*/}
          {/*                className={`${navbar.link} z-10 min-w-[33.333333333%] p-1 bg-[#F1F3F7] snap-center`}>*/}
          {/*              <a*/}
          {/*                className={`z-20 p-0 flex justify-center items-center bg-[#F1F3F7] rounded-[1rem] h-full transition ease-in duration-300 delay-75 min-w-[33.333333333%]*/}
          {/*                                       ${activeId === navbar.link ? 'text-base font-semibold text-[#4D5BFF]' : 'text-sm font-normal text-black'}`}*/}
          {/*                onClick={(e) => {*/}
          {/*                  e.preventDefault(); // 기본 href 동작 방지*/}
          {/*                  handleClick(String(navbar.link)); // 스크롤 함수 호출*/}
          {/*                }}*/}
          {/*                href={`#`}>*/}
          {/*                {navbar.date}*/}
          {/*              </a>*/}
          {/*            </li>*/}
          {/*          )*/}
          {/*        }) : <Loading/>*/}
          {/*    }*/}
          {/*    <li className={`z-10 min-w-[33.333333333%] p-1 bg-[#F1F3F7] snap-end`}/>*/}
          {/*  </ol>*/}
          {/*</menu>*/}
          <HistoryBlockContentsMobile historyData={data[0]} color={color} activeYearId={activeYearId}/>
        </>)}
      </div>
  );
};

export default History;
