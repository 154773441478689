import React from 'react';
import pdfImg from '../../../../../../assets/img/minerva/fileType/minerva_pdf_icon.svg';
import docImg from '../../../../../../assets/img/minerva/fileType/minerva_doc_icon.svg';
import pptImg from '../../../../../../assets/img/minerva/fileType/minerva_ppt_icon.svg';
import xlsImg from '../../../../../../assets/img/minerva/fileType/minerva_xls_icon.svg';
import docxImg from '../../../../../../assets/img/minerva/fileType/minerva_docx_icon.svg';
import pptxImg from '../../../../../../assets/img/minerva/fileType/minerva_pptx_icon.svg';
import xlsxImg from '../../../../../../assets/img/minerva/fileType/minerva_xlsx_icon.svg';
import jpegImg from '../../../../../../assets/img/minerva/fileType/minerva_jpeg_icon.svg';
import pngImg from '../../../../../../assets/img/minerva/fileType/minerva_png_icon.svg';
import mp3Img from '../../../../../../assets/img/minerva/fileType/minerva_mp3_icon.svg';
import wavImg from '../../../../../../assets/img/minerva/fileType/minerva_wav_icon.svg';
import dwgImg from '../../../../../../assets/img/minerva/fileType/minerva_dwg_icon.svg';
import dxfImg from '../../../../../../assets/img/minerva/fileType/minerva_dxf_icon.svg';
import jtdImg from '../../../../../../assets/img/minerva/fileType/minerva_jtd_icon.svg';
import jttImg from '../../../../../../assets/img/minerva/fileType/minerva_jtt_icon.svg';
import txtImg from '../../../../../../assets/img/minerva/fileType/minerva_txt_icon.svg';
import csvImg from '../../../../../../assets/img/minerva/fileType/minerva_csv_icon.svg';
import webBackGround from '../../../../../../assets/img/minerva/fileType/minerva_rainbowbg.png';
import {useWindowResizeToMobile} from "../../../../../features/util/responsive/mobile";
import useIntersectionObserver from "../../../../../features/util/commonFacility/sectionsObserver";
import {IsVisibleBoxSectionStyled} from "../../../../../features/styledComponents/common/isVisibleBox";
import styles from "../fileType/fileType.module.scss";

const data = [
  {
    title: 'PDF',
    subTitle: '拡張子：pdf <br> 備考：文書内の画像の検索はOCRオプションが必要となります。',
    img: [pdfImg]
  },
  {
    title: 'Microsoft Office 2003以下',
    subTitle: '拡張子：doc, ppt, xls <br> 備考：文書内の画像の検索はOCRオプションが必要となります。',
    img: [
      docImg, pptImg, xlsImg
    ]
  },
  {
    title: 'Microsoft Office 2007以上',
    subTitle: '拡張子：docx, pptx, xlsx <br> 備考：文書内の画像の検索はOCRオプションが必要となります。',
    img: [
      docxImg, pptxImg, xlsxImg
    ]
  },
  {
    title: 'イメージ',
    subTitle: '拡張子：jpeg, png <br> 備考：オプション機能',
    img: [
      jpegImg, pngImg
    ]
  },
  {
    title: '音声ファイル',
    subTitle: '拡張子：mp3, wav <br> 備考：オプション機能 / API利用料金が発生（単位：分）',
    img: [
      mp3Img, wavImg
    ]
  },
  {
    title: '図面ファイル（AutoCAD）',
    subTitle: '拡張子：dwg, dxf <br> 備考：オプション機能',
    img: [
      dwgImg, dxfImg
    ]
  },
  {
    title: '一太郎ファイル',
    subTitle: '拡張子：jtd, jtt <br> 備考：オプション機能',
    img: [
      jtdImg, jttImg
    ]
  },
  {
    title: 'テキストファイル',
    subTitle: '拡張子：txt, csv',
    img: [
      txtImg, csvImg
    ]
  }
]

const FileType = () => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.2});
  const isMobile = useWindowResizeToMobile();

  function mobileBg(index: number) {
    if (index === -1 || (index !== 0 && index !== 2 && index !== 4 && index !== 6)) return
    return styles.file_type
  }

  return (
    <IsVisibleBoxSectionStyled
        className="w-full z-10"
        ref={innerRef} isVisible={isVisible}
    >
      <div className="flex flex-col items-center pt-8 pb-16 px-6 gap-4
      md:pb-32 md:pt-28 md:px-10
      " style={{
        background: isMobile ? '' : `url(${webBackGround})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}>
        {/* title */}
        {isMobile  && <div className="mb-4 md:mb-24"><h2 className={`text-xl font-bold mb-8`}>検索対象ファイル種類</h2></div>}
        {!isMobile && <div className="mb-4 md:mb-24 mt-56"><h2 className={`text-3xl  font-bold absolute inset-x-0 z-10 text-center`}>検索対象ファイル種類</h2></div>}

        {/* card */}
        {
          data.map((item, index) => (
              <div key={index} className={`relative ${mobileBg(isMobile ? index : -1)} w-full max-w-md flex flex-col gap-4 border-[1px] border-[#B8A7FF] rounded-lg text-[#243142] px-6 py-4 bg-white
              md:max-w-3xl md:flex-row md:py-6 md:border-2 md:rounded-2xl
              lg:max-w-4xl lg:px-10
              `}>
                  <div className="flex flex-col gap-4 md:basis-3/4">
                    {/* card title */}
                    <h3 className="text-center font-semibold md:text-left lg:text-xl">{item.title}</h3>
                    {/* card subTitle*/}
                    <p className="min-h-14 text-sm lg:text-base" dangerouslySetInnerHTML={{__html: item.subTitle}}/>
                  </div>
                  {/* card image */}
                  <div className="flex justify-center gap-4 md:basis-1/4">
                    {
                      item.img.map((imgSrc, index) => (
                          <img className="w-9 h-auto lg:w-12" src={imgSrc} alt="" key={index}/>
                      ))
                    }
                </div>
              </div>
          ))
        }
      </div>
    </IsVisibleBoxSectionStyled>
  );
};

export default FileType;
