import styled from "@emotion/styled";

interface HeaderStyledProps {
  showHeaderNavBarToScroll: boolean;
  isZeroLastScrollY: boolean;
  showHeaderSubNavBar: boolean;
  isOpenAllNavPage: boolean;
  isMobileHistoryHeaderShow: boolean;
  touchScreenToShowHeaderNavBar: boolean;
}

export const HeaderMenuStyled = styled.menu`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  justify-content: space-around;
  height: 3.5rem;

  // tablet
  @media screen and (max-width: 1024px) {
    justify-content: space-between;
    margin: 0 2rem;
  }
`

export const HeaderStyled = styled.div<HeaderStyledProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.showHeaderSubNavBar ? '27rem' : '3.5rem'};
  background-color: ${props => {
    let value;
    if (props.showHeaderSubNavBar) {
      value = '#F1F3F7'
    } else if (props.isOpenAllNavPage) {
      value = 'rgba(255,255,255,1)'
    } else if (props.isZeroLastScrollY || props.isOpenAllNavPage) {
      value = 'rgba(255,255,255,0.0)'
    } else if (props.isOpenAllNavPage) {
      value = 'white'
    } else {
      value = 'white'
    }
    return value;
  }};
  transition: height 0.5s ease, transform 0.5s ease, background-color 0.1s ease, box-shadow 0.5s ease;
  z-index: 100;
  box-sizing: border-box;
  transform: ${props => {
    let value;
    if (!props.showHeaderNavBarToScroll) {
      value = 'translateY(0)';
      if (props.isMobileHistoryHeaderShow) {
        value = 'translateY(0%)'
      } else if (props.isZeroLastScrollY) {
        value = 'translateY(0)'
      } else if (!props.isZeroLastScrollY) {
        if (!props.touchScreenToShowHeaderNavBar) {
          value = 'translateY(-100%)'
        } else if (props.touchScreenToShowHeaderNavBar) {
          value = 'translateY(0%)'
        }
      }
    } else {
      value = 'translateY(0)';
    }
    return value
  }};
  box-shadow: ${props => {
    let boxShadowValue;
    // if 문을 사용한 조건부 로직
    if (props.isZeroLastScrollY) {
      if (props.showHeaderSubNavBar) {
        boxShadowValue = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
      } else {
        boxShadowValue = '';
      }
    } else if (props.isMobileHistoryHeaderShow) {
      boxShadowValue = '';
    } else {
      boxShadowValue = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
    }
    return boxShadowValue
  }};
`
