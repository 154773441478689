import {Button,} from "@mui/material";
import styled from "@emotion/styled";

export const DownloadButton = styled(Button)`
  background-color: white;
  border: 1px solid #1876D2;
  margin-left: 0.6rem;
  padding: 0.7rem;
  color: #051B4F;
  border-radius: 0.6rem;
  height: 2.3625rem;
  font-size: 0.75rem;
  font-weight: bold;
  position: relative;

  &:hover {
  }

  @media screen and (max-width: 768px) {
    width: 30%;
    margin: 0;
  }
`;
export const ClickEventButton = styled(Button)`
    padding: 0.7rem 0rem;
    width: 12rem;
    border-radius: 0.6rem;
    height: 3rem;
    border: 1.5px solid black;
    box-shadow: none;
    @media screen and (max-width: 768px) {
        width: 50%;
    }
`
export const DataButton = styled(Button)`
  padding: 0.5rem 2rem;
  color: white;
  border: none;
  border-radius: 0.3rem;
  font-size: small;
  font-weight: 500;
  cursor: pointer;
  margin-top: 1rem;
  background-image: linear-gradient(to right, #5D25B9 0%, #8D4AFE 51%, #FA6FD3FF 100%);
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;

  &:hover {
    background-position: right center;
  }


  @media (max-width: 768px) {
    
  }
`
