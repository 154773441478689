import styled from "@emotion/styled";
import {IsVisibleBoxSectionStyled} from "../../common/isVisibleBox";

export interface navBarGroup {
  showHistoryNavBar?: boolean,
  downHeaderBar?: boolean,
}

interface selectedNavBar {
  selectedNavBarId: boolean,
}


export const HistoryNavMenu = styled.menu<navBarGroup>`
  display: flex;
  margin: 6rem 0rem 5rem;
  justify-content: center;
  position: relative;
`
export const HistoryMobileNavMenu = styled(HistoryNavMenu)<navBarGroup>`
  position: ${props => props.showHistoryNavBar ? 'fixed' : 'static'};
  z-index: 90;
  background-color: white;
  margin: 0;
  width: 100%;
  top: ${props => {
    let topValue = '-8%';
    if (props.showHistoryNavBar) {
      topValue = '1%'
    }
    if (props.showHistoryNavBar && props.downHeaderBar) {
      topValue = '3.5rem'
    }
    return topValue
  }};
  height: 3.5rem;
  box-shadow: ${props => props.downHeaderBar ? '0rem 0.3rem 0.6rem -0.3rem rgba(0,0,0,0.1)' : 'none'};
  padding-bottom: 1rem;
`
export const HistoryNavBarGroup = styled.ol`
  margin: 0 auto;
  //position: sticky; 변경하기 높이를 지정 하고 쓰면 됨 부드럽게 쓰는 방법을 못찾아서 못씀
  display: flex;
  text-align: center;
  width: 70%;
  border-radius: 2rem;
  box-shadow: 0rem 0.3rem 0.6rem -0.3rem rgba(0, 0, 0, 0.1);

  li {
    width: 90%;
    padding: 1rem 0;
    font-weight: 700;
  }

  @media screen and (max-width: 920px) {
    width: 100%;
  }
`
export const HistoryNavBarGroupHidden = styled(HistoryNavBarGroup)<navBarGroup>`
  top: ${props => {
    let topValue = '-8%';
    if (props.showHistoryNavBar) {
      topValue = '4rem'
    }
    if (props.showHistoryNavBar && props.downHeaderBar) {
      topValue = '1%'
    }
    return topValue
  }};
  background-color: #ededed;
  opacity: ${props => props.showHistoryNavBar ? '0.95' : '0'};
  position: fixed;
  z-index: ${props => props.showHistoryNavBar ? '10' : '0'};
  border-radius: 2rem;
  transition: top 0.6s, opacity 0.6s;

`
export const SelectNavMenuBar = styled.a<selectedNavBar>`
  border-radius: 1rem;
  padding: 0.3rem 0.8rem;
  font-size: 0.875rem;
  height: 100%;
  transition: background-color 0.4s ease, color 0.4s ease;
  color: ${(props) => props.selectedNavBarId ? 'white' : 'initial'};
  background-color: ${(props) => props.selectedNavBarId ? 'black' : 'initial'};
`
export const SelectNavMenuBarMobile = styled(SelectNavMenuBar)`
  font-size: ${(props) => props.selectedNavBarId ? '1.0rem' : '0.875rem'};
  font-weight: ${props => props.selectedNavBarId ? '600' : 'normal'};
  color: ${(props) => props.selectedNavBarId ? '#4D5BFF' : 'initial'};
  background-color: #F1F3F7;
`
