import {NavLink, useLocation} from "react-router-dom";
import React from "react";
import {navMenuData} from "../../../../assets/data/menuData";

type NavProps = {
  onMouseHover: (navMenuId: number) => void;
  onMouseLeave: () => void;
  isOpenAllNavPage: boolean;
  clickItemIndex: (navMenuId: number) => void;
}
const Nav = ({onMouseHover, onMouseLeave, isOpenAllNavPage, clickItemIndex}: NavProps) => {
  const location = useLocation();
  const notMoveLink = (event: any, link: string) => {
    if (link === "/production") {
      event.preventDefault();
    }
  };

  return (
    <nav>
      <ol className={`list-none p-0 m-0 flex`}>
        {navMenuData.map((navMenuData) =>
          (
            <li className={`${isOpenAllNavPage ? 'opacity-0 cursor-default pointer-events-none':'opacity-100 cursor-pointer pointer-events-auto'}
                decoration-0 font-bold text-[#243142] flex text-sm my-0 mx-8`}
                key={`navKey-${navMenuData.describe}`}
                onMouseEnter={() => onMouseHover(navMenuData.navMenuId)}>
              <NavLink
                title={`move-${navMenuData.title}`}
                to={(location.pathname).slice(1).split('/')[0] ===
                (navMenuData.link).slice(1).split('/')[0] ? '#' : navMenuData.link}
                // className={({isActive}) => isActive ? '' : ''}
                onClick={(e) => {
                  notMoveLink(e, navMenuData.link);
                  onMouseLeave();
                  clickItemIndex(navMenuData.navMenuId);
                }}>
                <p>{navMenuData.title}</p>
              </NavLink>
            </li>
          )
        )}
      </ol>
    </nav>
  );
};
export default Nav;
