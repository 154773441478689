import React from 'react';
import {
  SelectionProcessContainerStyled,
} from "../../../../features/styledComponents/information/employment/selectionProcessStyled";
import selectionProcess from "../../../../../assets/img/selectionProcess/entry_processes.png";
import {useWindowResizeToMobile} from "../../../../features/util/responsive/mobile";
import atMark from "../../../../../assets/img/recruitment/mobile_recruit_mail_icon.svg";

const SelectionProcess = () => {
  const isMobile = useWindowResizeToMobile();
  return (
    <section
      className={`py-8 employment-selection-process-bg `}
      style={{padding: '2rem 0' , background: 'transparent linear-gradient(180deg, #DFEAF4 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box'}}>
      <div style={{display: 'flex', paddingBottom: isMobile ? '2rem' : '', justifyContent: 'center', gap: '1rem'}}>
        <img style={{display: isMobile ? 'block' : 'none', width: '1.2rem'}} src={atMark} alt=""/>
          <div className="text-center py-3 text-[#243142] text-xl font-medium
           md:text-2xl md:font-semibold
           lg:text-2xl lg:font-semibold
           xl:text-2xl xl:font-semibold">選考プロセス</div>
      </div>
      <SelectionProcessContainerStyled>
        {
          !isMobile ? (
            <div style={{height: '30rem', alignItems: 'center', padding: '2rem'}}>
              <img style={{width: 'auto', height: '100%'}} src={selectionProcess} alt="selectionProcess"/>
            </div>
          ) : (
            <div style={{padding: '0 1.5rem'}}>
              <div className={"mobileSelectionProcess"}>
                <span style={{top: '24.5%', left: '14%'}}>書類選考</span>
                <span style={{top: '44%', left: '65.5%'}}>1次面接<br/>(実務)</span>
                <span style={{top: '65%', left: '14.5%'}}>2次面接<br/>(役員)</span>
                <span style={{top: '88%', left: '70%'}}>内定</span>
              </div>
            </div>
          )
        }
      </SelectionProcessContainerStyled>
    </section>
  );
};

export default SelectionProcess;
