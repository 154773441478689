import React, {useCallback, useEffect, useState} from 'react';
import {subNavMenuData} from "../../../../../assets/data/menuData";
import {useNavigate} from "react-router-dom";
import {Constants} from "../../../../features/constants/appConstants";
import SubNavItemsTitle from "./subNavItemsTitle";
import SubNavItemsImage from "./subNavItemsImage";

type NavItemProps = {
  navMenuId: number;
  onMouseLeave: () => void;
  showHeaderSubNavBar: boolean
}

const SubNavItems = ({navMenuId = 0, onMouseLeave, showHeaderSubNavBar}: NavItemProps) => {
  let navigate = useNavigate();
  const [subNavId, setSubNavId] = useState(0);
  const hoverItemIndex = useCallback((suvNavMenuId?: number) => {
    if (suvNavMenuId !== undefined) {
      setSubNavId(suvNavMenuId)
    }
  }, []);

  function goToLink(link: string) {
    if (link.includes('skpack')) {
      window.open(link, '_blank');
    } else {
      navigate(link);
    }
    onMouseLeave();
  }

  // 이미지 갯수 조정
  useEffect(() => {
    if (subNavMenuData[navMenuId].length - 1 < subNavId) {
      setSubNavId(0)
    }
  }, [navMenuId]);

  return (
    <div className={`mt-4 flex justify-center relative`}>
      {
        Object.entries(subNavMenuData).map(([navMenuIdKey, subNavItems], navIndex) => {
          if (Number(navMenuIdKey) === Constants.NAV_MENU_HOME || Number(navMenuIdKey) === Constants.NAV_MENU_COMMUNICATION) {
            return null;
          } else {
            return (
              <nav
                className={`w-[60rem] flex justify-center h-72 transition-all duration-100 ease-in absolute flex-wrap bg-transparent
                            ${showHeaderSubNavBar ? 'pointer-events-auto' : 'pointer-events-none'}
                            ${(showHeaderSubNavBar && (Number(navMenuIdKey) === navMenuId)) ? 'opacity-100 z-10' : 'opacity-0 z-[-100]'}`}
                key={`subNavMenuBarContainerKey-${navIndex}`}>
                <ul className={`${(subNavItems.length === 2) ? 'w-80 flex-col' : 'w-[40rem] flex-row'}
                                            flex flex-wrap justify-center`}>
                  {subNavItems.map((subNavItem, subNavItemIndex) => {
                    return (
                      <React.Fragment key={`subNavItemsTitleKey-${subNavItemIndex}`}>
                        <SubNavItemsTitle subNavItemIndex={subNavItemIndex}
                                          subNavItem={subNavItem}
                                          hoverItemIndex={hoverItemIndex}
                                          showHeaderSubNavBar={showHeaderSubNavBar}
                                          navMenuIdKey={Number(navMenuIdKey)}
                                          goToLink={goToLink}
                                          subNavItemsAmount={subNavItems.length}/>

                      </React.Fragment>
                    )
                  })}
                  {/*그냥 공간 채우기 용으로 만든거*/}
                  {
                    subNavItems.length % 2 === 1 ?
                      <li style={{width: '19rem', margin: '0.3rem 0.3rem'}}></li>
                      : null
                  }
                </ul>
                <div
                  className={`w-72 flex justify-center items-center relative bg-white mt-[0.3rem] rounded-[0.6rem] overflow-hidden h-calc-0.6rem`}>
                  {subNavItems.map((subNavBannerImage, subNavItemIndex) => {
                    return (
                      <React.Fragment key={`subNavBannerImageKey-${subNavItemIndex}`}>
                        <SubNavItemsImage
                          src={subNavBannerImage.bannerImage}
                          navMenuIdKey={Number(navMenuIdKey)}
                          navMenuId={navMenuId}
                          subNavItemIndex={subNavItemIndex}
                          subNavId={subNavId}
                          showHeaderSubNavBar={showHeaderSubNavBar}
                        />
                      </React.Fragment>
                    )
                  })}
                </div>
              </nav>
            )
          }
        })
      }
    </div>
  )
  // }
};
export default React.memo(SubNavItems, (prevProps, nextProps) => {
  if (prevProps.showHeaderSubNavBar !== nextProps.showHeaderSubNavBar) {
    return false
  } else if (prevProps.navMenuId !== nextProps.navMenuId) {
    return false
  } else if (prevProps.onMouseLeave !== nextProps.onMouseLeave) {
    return false
  }
  return true
})
