import React from 'react';
import mobileWhiteQuotes from "../../../../../assets/img/messages/mobileWhiteQuotes.svg";
import smallTriangle from "../../../../../assets/img/messages/smallTriangle.svg";

const RepresentativeMessage = () => {
  return (
    <div className="bg-white w-full
    lg:mt-24 lg:bg-[url('./assets/img/messages/grid.svg')] bg-no-repeat bg-cover bg-center
    ">
      <div className="px-6 pt-12 pb-8 flex justify-center relative
      lg:flex-col lg:items-center lg:py-24 lg:gap-16">
        <div className="hidden text-2xl font-bold lg:block">
          <h2>代表取締役メッセージ</h2>
        </div>
        <div className="bg-[#2F67A7] shadow-xl rounded-2xl flex flex-col items-center px-6 py-7 w-[40rem] gap-5
        sm:py-12 sm:px-8
        lg:overflow-hidden lg:w-[55rem] lg:pb-28">
          <div className="text-1.2rem text-white font-semibold
          sm:text-2xl
          lg:hidden">
            代表取締役メッセージ
          </div>
          <div className="lg:hidden">
            <img className="lg:mt-10" src={mobileWhiteQuotes} alt=""/>
          </div>
          <div className="text-white text-sm
          sm:text-base
          lg:font-medium lg:text-base">
            <div className="hidden relative lg:flex lg:justify-center lg:items-center lg:w-full lg:relative">
              <img className="w-14 h-14 mt-4 mb-10" src={mobileWhiteQuotes} alt=""/>
            </div>
            <p>
              NAMU TECH JAPANはICT、<br className="hidden sm:block lg:hidden"/>
              クラウド技術の専門企業です。<br className="hidden sm:block"/>
              2010年に日本でICT事業を展開してから、<br className="hidden sm:block lg:hidden"/>
              仮想化、クラウド、インフラ、<br className="hidden sm:block"/>
              ビックデータ、AIに至るまで、顧客のデジタル<br className="hidden sm:block lg:hidden"/>
              革新と効果的な運営をリードする<br className="hidden sm:block"/>
              ソリューション企業へと躍進しています。
            </p><br/>
            <p>
              絶えず変化するIT技術環境に合わせて、<br className="hidden sm:block lg:hidden"/>
              デジタル変化と流れを把握し、<br className="hidden sm:block"/>
              持続的に優れたソリューションを開発して<br className="hidden sm:block lg:hidden"/>
              デジタルトランスフォーメーション時代を<br className="hidden sm:block"/>
              リードしていきます。<br className="hidden sm:block"/>
              革新的な技術を基に、顧客のニーズに応える<br className="hidden sm:block lg:hidden"/>
              カスタマイズサービスを提供し、<br className="hidden sm:block"/>
              顧客と共に成長していくことを約束します。
            </p>
            <div className="hidden lg:flex lg:justify-items-start lg:w-full lg:h-full relative">
              <img className="absolute w-40 -ml-28 mr-6 mt-9 opacity-20" src={smallTriangle} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepresentativeMessage;
