import React from 'react';
import styled from "@emotion/styled";
import DeviceComponent from "../../features/util/commonFacility/deviceType";

const Loading = ({style, styleClass}: { style?: React.CSSProperties, styleClass?: string }) => {
  const deviceType = DeviceComponent()
  return (
    <LoadingStyled style={style} className={`${styleClass}`}>
      {deviceType === 'iOS' ? <IosLoader/> : <Loader/>}
      {/*<MainLoader>*/}
      {/*  <div/>*/}
      {/*</MainLoader>*/}
    </LoadingStyled>
  );
};

export default Loading;

// 로딩컴포넌트 CSs
const LoadingStyled = styled.section`
  min-height: 28.125rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: none;
`
const Loader = styled.div`
  width: 3.75rem;
  height: 2.5rem;
  position: relative;
  display: inline-block;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: #263238;
    background-repeat: no-repeat;
    box-sizing: border-box;
  }

  &::before {
    left: 0;
    top: 0;
    width: 2.25rem;
    height: 2.25rem;
    background-image: radial-gradient(circle 8px at 18px 18px, #FFF 100%, transparent 0),
    radial-gradient(circle 4px at 18px 0px, #FFF 100%, transparent 0),
    radial-gradient(circle 4px at 0px 18px, #FFF 100%, transparent 0),
    radial-gradient(circle 4px at 36px 18px, #FFF 100%, transparent 0),
    radial-gradient(circle 4px at 18px 36px, #FFF 100%, transparent 0),
    radial-gradient(circle 4px at 30px 5px, #FFF 100%, transparent 0),
    radial-gradient(circle 4px at 30px 30px, #FFF 100%, transparent 0),
    radial-gradient(circle 4px at 5px 30px, #FFF 100%, transparent 0),
    radial-gradient(circle 4px at 5px 5px, #FFF 100%, transparent 0);
    animation: rotationBack 3s linear infinite;
  }

  &::after {
    left: 2.1875rem;
    top: 0.9375rem;
    width: 1.5rem;
    height: 1.5rem;
    background-image: radial-gradient(circle 5px at 12px 12px, #FFF 100%, transparent 0),
    radial-gradient(circle 2.5px at 12px 0px, #FFF 100%, transparent 0),
    radial-gradient(circle 2.5px at 0px 12px, #FFF 100%, transparent 0),
    radial-gradient(circle 2.5px at 24px 12px, #FFF 100%, transparent 0),
    radial-gradient(circle 2.5px at 12px 24px, #FFF 100%, transparent 0),
    radial-gradient(circle 2.5px at 20px 3px, #FFF 100%, transparent 0),
    radial-gradient(circle 2.5px at 20px 20px, #FFF 100%, transparent 0),
    radial-gradient(circle 2.5px at 3px 20px, #FFF 100%, transparent 0),
    radial-gradient(circle 2.5px at 3px 3px, #FFF 100%, transparent 0);
    animation: rotationBack 4s linear infinite reverse;
  }

  @keyframes rotationBack {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
`;

const MainLoader = styled.div`
  position: relative;
  width:50px;
  height:50px;
  background-color: black;
  border-radius:100%;
  margin:40px;
  animation: x 1s ease-in-out alternate infinite, y 1s linear infinite;
  
  @keyframes x {
    0% {
      transform:translatex(-100px)
    }
    100% {
      transform:translatex(100px)
    }
  }
  @keyframes y {
    25%{
      transform:translatey(-50px);
    }
    0%,50%,100%{
      transform:translatey(0);
    }
    75%{
      transform:translatey(50px);
    }
  }
`
const IosLoader = styled.div`
  width: 40px;
  height: 26px;
  background:
      no-repeat linear-gradient(#000 0 0) 0    100%,
      no-repeat linear-gradient(#000 0 0) 50%  100%,
      no-repeat linear-gradient(#000 0 0) 100% 100%;
  background-size: 8px calc(100% - 4px);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #000;
    left: 0;
    top: 0;
    animation:
        moveHorizontally 1.5s linear infinite alternate,
        moveVertically 0.75s cubic-bezier(0, 200, 0.8, 200) infinite;
  }
  @keyframes moveHorizontally {
    100% { left: calc(100% - 8px); }
  }
  @keyframes moveVertically {
    100% { top: -0.1px; }
  }
`
