import React, {useState} from 'react';
import DOMPurify from "dompurify";
import {mapInfoData} from "../../../../features/types/globals.d";
import Loading from "../../../common/loading";

const Map = ({data}: { data: mapInfoData }) => {
  const cleanUrl = DOMPurify.sanitize(data.mapUrl);
  const [isLoading, setIsLoading] = useState(true); // iframe 로딩 상태
  const handleLoad = () => {
    setIsLoading(false);
  };
  return (
    // Important! Always set the container height explicitly
    <div>
      {isLoading && <Loading style={{width:'100%', height:'18rem'}}/>} {/* 로딩 인디케이터 */}
      <iframe className={`w-full border-0 rounded-xl ${isLoading ? 'h-0' : 'h-[18rem] lg:h-[21rem]'}`}
              src={cleanUrl}
              loading={"lazy"}
              title="Company Namutech location on Google Maps"
              onLoad={handleLoad}
      />
    </div>
  );
};

export default Map;
