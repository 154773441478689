import {MapsInfoData, MobileMapsInfoData} from "../../app/features/types/globals.d";

export const mapsInfo: MobileMapsInfoData = [
  {
    area: '日本法人(東京)',
    mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.886086251484!2d139.752907015571!3d35.704420736306666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c67aecfffff%3A0xacb8a5026d589dea!2sNAMU%20TECH%20JAPAN!5e0!3m2!1sen!2sjp!4v1618639881300!5m2!1sen!2sjp',
    address: "〒113-0033<br>東京都文京区本郷1-20-3<br>中山ビル5階",
    mobileAddress:"〒113-0033<br>東京都文京区本郷1-20-3<br>中山ビル5階",
    phone: "03-6240-0471",
    email: "info@namutech.co.jp",
    businessHours: "Monday - Friday<br>09:00 - 18:00",
    nearByStation: "水道橋駅・三田線 A6出口 徒歩1分"
  },
  {
    area: '日本法人(大阪)',
    mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.4888163194632!2d135.52260421523204!3d34.692848880436564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e72b29fe34e7%3A0xc931a07bedcd832c!2s1-ch%C5%8Dme-8-18%20Katamachi%2C%20Miyakojima%20Ward%2C%20Osaka%2C%20534-0025!5e0!3m2!1sen!2sjp!4v1618930928939!5m2!1sen!2sjp',
    address: "〒534-0025<br>大阪市都島区片町1-8-18日辰ビル301号",
    mobileAddress:"〒534-0025<br>大阪市都島区片町1-8-18<br>日辰ビル301号",
    phone: "06-4397-4397",
    email: "info@namutech.co.jp",
    businessHours: "Monday - Friday<br>09:00 - 18:00",
    nearByStation: "大阪城北詰駅・学研都市線 2出口 徒歩3分"
  },
  {
    area: '韓国本社',
    mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d718.3564710048971!2d126.83673377868725!3d37.56480280593839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9d000798b8fb%3A0x1965210d479a7406!2z64KY66y06riw7IigIOuniOqzoeyXoOuwuOumrCBSJkTshLzthLA!5e0!3m2!1sen!2sjp!4v1715838727353!5m2!1sen!2sjp',
    address: "〒07793 <br>NAMUTECH, 41, 3-gil, Magokjungang 8-ro, Gangseo-gu, Seoul, Korea",
    mobileAddress:"〒07793 <br>NAMUTECH, 41, 3-gil,<br>Magokjungang 8-ro,<br> Gangseo-gu<br>Seoul, Korea",
    phone: "+82-2-3288-7900",
    email: "info@namutech.co.kr",
    businessHours: "Monday - Friday<br>09:00 - 18:00",
  }
];
